<template>
  <el-collapse v-bind="$attrs">
    <template
      v-for="(_, slot) of $slots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </el-collapse>
</template>

<script lang="ts" setup></script>
<style>
.el-collapse {
  border: none;
}
.el-collapse-item__header {
  --el-collapse-header-height: auto;
}
</style>
