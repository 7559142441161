import { GATEWAY_ENDPOINT } from './SetupBase'
import { PAGINATION_LIMIT } from '~/src/constants'

export const billsApi = {
  $_getAirtimeHistory: (page = 1) => {
    const url = `/bills/airtime?page=${page}&page_size=${PAGINATION_LIMIT}`
    return GATEWAY_ENDPOINT.get(url)
  },
  $_buyAirtime: (credential) => {
    const url = '/bills/airtime'
    return GATEWAY_ENDPOINT.post(url, credential)
  },
  $_fetchAirtimeBillers: (data = 'GH') => {
    const url = `/bills/airtime/billers/${data}`
    return GATEWAY_ENDPOINT.get(url)
  },
}
