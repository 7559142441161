<template>
  <el-radio
    class="radio"
    v-bind="$attrs"
  >
    <template
      v-for="(_, slot) of $slots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </el-radio>
</template>
<style>
/* Todo: make color dynamic */
.radio.el-radio.is-bordered.is-checked {
  --el-color-primary: var(--primary300);
  transition: box-shadow 0.25s ease-in-out;
  box-shadow: 0px 0px 0px 2px var(--primary100);
}
.radio.el-radio.is-bordered.el-radio--large {
  @apply py-3 pr-6 pl-5;
  --el-border-radius-base: 8px;
}
.radio.el-radio.el-radio--large .el-radio__inner {
  @apply h-5 w-5;
}
.radio .el-radio__input .el-radio__inner {
  @apply border-gray10 hover:border-primary600;
}
.radio .el-radio__input .el-radio__inner::after {
  @apply h-1.5 w-1.5;
}

.radio .el-radio__input.is-checked .el-radio__inner {
  @apply border-primary600 bg-primary600;
}

.radio .el-radio__input.is-checked .el-radio__inner::after {
  @apply bg-white;
}

.radio .el-radio__input.is-checked + .el-radio__label {
  --el-color-primary: var(--gray900);
}
</style>
