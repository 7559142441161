import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/opt/build/repo/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/build/repo/.nuxt/sentry-client-config.mjs";
import i18n_yfWm7jX06p from "/opt/build/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_8SbxDRbG6Y from "/opt/build/repo/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_client_LcKgStRyi6 from "/opt/build/repo/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import vue_gtag_client_EKuLeLTp9S from "/opt/build/repo/src/plugins/vue-gtag.client.ts";
import vue_gtm_client_saUZpjMrRK from "/opt/build/repo/src/plugins/vue-gtm.client.ts";
import vue_google_maps_client_UJ5WnYd8TM from "/opt/build/repo/src/plugins/vue-google-maps.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  i18n_yfWm7jX06p,
  plugin_8SbxDRbG6Y,
  plugin_client_LcKgStRyi6,
  chunk_reload_client_UciE0i6zes,
  vue_gtag_client_EKuLeLTp9S,
  vue_gtm_client_saUZpjMrRK,
  vue_google_maps_client_UJ5WnYd8TM
]