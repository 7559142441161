<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/yup'
import { useFuse } from '@vueuse/integrations/useFuse'
import { Path, useForm } from 'vee-validate'
import { useKYC } from '~/src/composables/apis/kyc/useKyc'
import { Director } from '~/src/services/requests/kyc/types'
import Calendar from '~/src/components/icons/Calendar.vue'
import { businessUSDDetailsSchema, associateSchema } from '~/src/services/requests/wallet/schema'
import allCountries from '~/src/constants/countries'
import { RESIDENCY_STATUS, ADDRESS_TYPE, EMPLOYEE_STATUS } from '~/src/constants/index'
import { toBase64 } from '~/src/composables/utils/useFile'
import { CreateAccount } from '~/src/services/requests/wallet/types'
import { CurrencyCode, DateFormat, SourceOfFunds } from '~/src/types/internals'
import { useFundWallet } from '~~/src/composables/apis/wallet/index.js'
import { useWalletsModal } from '~/src/composables/core/modals'

const { getDirectors, state } = useKYC()
const { createBusinessVirtualAccount, creatingAcount } = useFundWallet()
const { closeCreateVirtualAccount } = useWalletsModal()

const step = ref(0)

const schemas = [associateSchema, businessUSDDetailsSchema]
const currentSchema = computed(() => toTypedSchema(schemas[step.value]))

const { defineField, handleSubmit, setFieldValue } = useForm({
  validationSchema: currentSchema,
  keepValuesOnUnmount: true,
  initialValues: {
    currency: CurrencyCode.USD,
    associate: {
      documents: {
        sourceOfFunds: {
          fileName: SourceOfFunds.BANK_STATEMENT,
        },
      },
    },
    documents: {
      sourceOfFunds: {
        fileName: SourceOfFunds.BANK_STATEMENT,
      },
    },
  },
})

const elPlusConfig = (state: any) => ({
  props: {
    validateEvent: false,
    error: state.errors[0],
    required: state.required,
  },
})

const directors = computed(() => state.directors)
const search = ref('')
const countries = allCountries.map(({ id, text }) => ({ value: id, label: text }))

// associate
const [_, idProps] = defineField('associate.id', elPlusConfig)
const [email, emailProps] = defineField('associate.email', elPlusConfig)
const [poa, poaProps] = defineField('associate.documents.proofOfAddress.file', elPlusConfig)
const [addressImageName, addressImageNameProps] = defineField(
  'associate.documents.proofOfAddress.fileName',
  elPlusConfig,
)
const [nationality, nationalityProps] = defineField('associate.nationality', elPlusConfig)
const [dob, dobProps] = defineField('associate.dob', elPlusConfig)
const [passportImage, passportImageProps] = defineField('associate.documents.passportImage', elPlusConfig)
const [identification, identificationProps] = defineField('associate.identificationNumber', elPlusConfig)
const [description] = defineField('associate.employmentDescription', elPlusConfig)
const [usResidencyStatus, usResidencyStatusProps] = defineField('associate.usResidencyStatus', elPlusConfig)
const [employmentStatus, employmentStatusProps] = defineField('associate.employmentStatus', elPlusConfig)
const [employer, employerProps] = defineField('associate.employerName', elPlusConfig)
const [occupation, occupationProps] = defineField('associate.occupation', elPlusConfig)
const [passportNumber, passportNumberProps] = defineField('associate.passportNumber', elPlusConfig)
const [sof, sofProps] = defineField('associate.documents.sourceOfFunds.file', elPlusConfig)

// business details
const [stateOfIncorp, stateOfIncorpProps] = defineField('stateOfIncorporation', elPlusConfig)
const [stateName, stateNameProps] = defineField('address.state', elPlusConfig)
const [country, countryProps] = defineField('address.country', elPlusConfig)
const [city, cityProps] = defineField('address.city', elPlusConfig)
const [postalCode, postalCodeProps] = defineField('address.postalCode', elPlusConfig)
const [street, streetProps] = defineField('address.street', elPlusConfig)
const [busPoa, busPoaProps] = defineField('documents.proofOfAddress.file', elPlusConfig)
const [busPoaName, busPoaNameProps] = defineField('documents.proofOfAddress.fileName', elPlusConfig)
const [busSof, busSofProps] = defineField('documents.sourceOfFunds.file', elPlusConfig)

const { results } = useFuse(search, directors, {
  fuseOptions: {
    keys: ['fullName'],
  },
  matchAllWhenSearchEmpty: true,
})

const carousel = ref<InstanceType<any> | null>(null)

const prev = () => carousel.value?.prev()
const next = () => carousel.value?.next()

const getSingleFile = (event: Event) => {
  const [file] = (event.target as HTMLInputElement).files || []

  return file
}

const handleChange = async (field: Path<CreateAccount>, event: Event) => {
  const value = (await toBase64(getSingleFile(event))) as string

  setFieldValue(field, value)
}

const selectDirector = (director: Director) => {
  setFieldValue('associate.id', director.id)
  setFieldValue('associate.nationality', director.nationality)
  setFieldValue('associate.documents.proofOfAddress.file', director.proofOfAddress)
  setFieldValue('associate.documents.sourceOfFunds.file', director.meta?.proofOfFunds || undefined)
  setFieldValue('associate.occupation', director.meta?.occupation || undefined)
  setFieldValue('associate.passportNumber', director.meta?.passportNumber || undefined)
  setFieldValue('associate.usResidencyStatus', director.meta?.usResidencyStatus || undefined)
  setFieldValue('associate.employmentStatus', director.meta?.employmentStatus || undefined)
  setFieldValue('associate.employmentDescription', director.meta?.employmentDescription || undefined)
  setFieldValue('associate.employerName', director.meta?.employerName || undefined)
  setFieldValue('associate.documents.passportImage', director.meta?.passportImage || undefined)
}

const submit = handleSubmit((val) => {
  if (step.value === 0) {
    next()
    return
  }

  createBusinessVirtualAccount(val as CreateAccount, {
    onSuccess: () => closeCreateVirtualAccount(),
  })
})

getDirectors()
</script>
<template>
  <Modal
    title=""
    modal="$atts.modal"
  >
    <form
      class="w-full"
      @submit.prevent="submit"
    >
      <header class="flex justify-between items-center w-full mb-4">
        <div class="flex-1 min-h-[32px]">
          <Button
            v-if="step"
            variant="text"
            type="button"
            @click="prev"
          >
            <Icon
              name="backArrow"
              class="w-5 text-secondary cursor-pointer"
            />
            {{ $t('back') }}
          </Button>
        </div>
        <Typography
          class="flex-2 text-center"
          variant="lgBold"
        >
          {{ $t(step === 0 ? 'wallets.chooseHolder' : 'kyc.businessDetails') }}
        </Typography>
        <div class="flex-1" />
      </header>

      <el-carousel
        ref="carousel"
        height="auto"
        arrow="never"
        :autoplay="false"
        :loop="false"
        indicator-position="none"
        @change="(newIndex: number) => (step = newIndex)"
      >
        <el-carousel-item style="height: auto">
          <div class="flex flex-col gap-4 h-[550px] overflow-scroll">
            <Search
              v-model="search"
              size="large"
              :placeholder="$t('settings.searchRole')"
            />
            <div>
              <Typography
                as="span"
                color="text-secondary"
                class="mb-2"
                variant="smBold"
              >
                {{ $t('kyc.directors') }}
              </Typography>
              <ul
                v-if="results.length"
                class="flex gap-2"
              >
                <li
                  v-for="{ item } in results"
                  :key="item.email"
                >
                  <TagUser
                    :name="`${item.firstName} ${item.lastName}`"
                    @click="() => selectDirector(item)"
                  />
                </li>
              </ul>
              <Typography
                variant="xs"
                color="text-red"
                class="mt-1"
              >
                {{ idProps.error }}
              </Typography>
            </div>

            <FormControl
              v-bind="emailProps"
              class="flex-1"
            >
              <template #label>
                <Typography
                  as="span"
                  color="text-secondary"
                  variant="smBold"
                  class="inline-flex"
                >
                  {{ $t('email') }}
                  <Icon
                    name="tip"
                    class="ml-1 w-4"
                  />
                </Typography>
              </template>
              <InputField
                v-model="email"
                :placeholder="$t('transactions.enterEmailAddress')"
              />
            </FormControl>

            <FormControl
              :label="$t('nationality')"
              v-bind="nationalityProps"
            >
              <Select
                v-model="nationality"
                filterable
                class="w-full"
                size="large"
                :placeholder="`--${$t('choose')}--`"
              >
                <SelectOption
                  v-for="item in countries"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  <span class="inline-flex justify-between items-center w-full">
                    {{ item.label }}

                    <IconsCheckOutlined
                      v-if="nationality === item.value"
                      class="text-success900 h-4 w-4"
                    />
                  </span>
                </SelectOption>
              </Select>
            </FormControl>

            <FormControl
              v-bind="identificationProps"
              class="flex-1"
            >
              <template #label>
                <Typography
                  as="span"
                  color="text-secondary"
                  variant="smBold"
                  class="inline-flex"
                >
                  {{ $t('wallets.idNumber') }}
                  <Icon
                    name="tip"
                    class="ml-1 w-4"
                  />
                </Typography>
              </template>
              <InputField
                v-model="identification"
                :placeholder="$t('wallets.enterIdNumber')"
              />
            </FormControl>

            <FormControl
              v-bind="passportNumberProps"
              class="flex-1"
            >
              <template #label>
                <Typography
                  as="span"
                  color="text-secondary"
                  variant="smBold"
                  class="inline-flex"
                >
                  {{ $t('wallets.passportNumber') }}
                  <Icon
                    name="tip"
                    class="ml-1 w-4"
                  />
                </Typography>
              </template>
              <InputField
                v-model="passportNumber"
                :placeholder="$t('wallets.enterPassportNumber')"
              />
            </FormControl>

            <div class="flex gap-6">
              <FormControl
                :label="$t('customer.dateOfBirth')"
                v-bind="dobProps"
              >
                <DatePicker
                  :model-value="dob"
                  size="large"
                  type="date"
                  placeholder="MM / DD / YYYY"
                  class="!w-full"
                  :value-format="DateFormat.DefaultAlt"
                  :prefix-icon="Calendar"
                  :clearable="false"
                  @update:model-value="($event: string) => setFieldValue('associate.dob', $event)"
                />
              </FormControl>

              <FormControl
                :label="$t('wallets.usResidencyStatus')"
                v-bind="usResidencyStatusProps"
              >
                <Select
                  v-model="usResidencyStatus"
                  filterable
                  class="w-full"
                  size="large"
                  :placeholder="`--${$t('choose')}--`"
                >
                  <SelectOption
                    v-for="item in RESIDENCY_STATUS"
                    :key="item.value"
                    :label="$t(item.label)"
                    :value="item.value"
                  >
                    <span class="inline-flex justify-between items-center w-full">
                      {{ $t(item.label) }}

                      <IconsCheckOutlined
                        v-if="usResidencyStatus === item.value"
                        class="text-success900 h-4 w-4"
                      />
                    </span>
                  </SelectOption>
                </Select>
              </FormControl>
            </div>

            <FormControl
              :label="$t('wallets.employerName')"
              v-bind="employerProps"
              class="flex-1"
            >
              <InputField
                v-model="employer"
                :placeholder="$t('wallets.enterEmployerName')"
              />
            </FormControl>

            <div class="flex gap-6">
              <FormControl
                :label="$t('wallets.occupation')"
                v-bind="occupationProps"
                class="flex-1"
              >
                <InputField
                  v-model="occupation"
                  :placeholder="$t('wallets.enterOccupation')"
                  @input="($event: string) => (description = $event)"
                />
              </FormControl>

              <FormControl
                :label="$t('wallets.employeeStatus')"
                v-bind="employmentStatusProps"
              >
                <Select
                  v-model="employmentStatus"
                  filterable
                  class="w-full"
                  size="large"
                  :placeholder="`--${$t('choose')}--`"
                >
                  <SelectOption
                    v-for="item in EMPLOYEE_STATUS"
                    :key="item.value"
                    :label="$t(item.label)"
                    :value="item.value"
                  >
                    <span class="inline-flex justify-between items-center w-full">
                      {{ $t(item.label) }}

                      <IconsCheckOutlined
                        v-if="employmentStatus === item.value"
                        class="text-success900 h-4 w-4"
                      />
                    </span>
                  </SelectOption>
                </Select>
              </FormControl>
            </div>

            <FormControl
              :label="$t('wallets.addressType')"
              v-bind="addressImageNameProps"
            >
              <Select
                v-model="addressImageName"
                filterable
                class="w-full"
                size="large"
                :placeholder="`--${$t('choose')}--`"
              >
                <SelectOption
                  v-for="item in ADDRESS_TYPE"
                  :key="item.value"
                  :label="$t(item.label)"
                  :value="item.value"
                >
                  <span class="inline-flex justify-between items-center w-full">
                    {{ $t(item.label) }}

                    <IconsCheckOutlined
                      v-if="addressImageName === item.value"
                      class="text-success900 h-4 w-4"
                    />
                  </span>
                </SelectOption>
              </Select>
            </FormControl>

            <EntityDetails
              icon="document"
              :title="$t('wallets.bankStatement')"
              :subtitle="$t('wallets.shareBankStatement', { type: 'pdf' })"
              :error="sofProps.error"
              @remove="() => setFieldValue('associate.documents.sourceOfFunds.file', undefined)"
            >
              <Tag
                v-if="sof"
                closable
                size="large"
                type="info"
                @close="() => setFieldValue('associate.documents.sourceOfFunds.file', undefined)"
              >
                <Typography>{{ $t('wallets.bankStatement') }}</Typography>
              </Tag>
              <Upload
                v-else
                id="sourceOfFunds"
                file-type=".pdf"
                :label="$t('kyc.upload')"
                v-bind="sofProps"
                @change="handleChange('associate.documents.sourceOfFunds.file', $event)"
              />
            </EntityDetails>
            <EntityDetails
              icon="document"
              :title="$t('kyc.proofOfAddress')"
              :subtitle="$t('wallets.shareProofOfAddress', { type: 'pdf' })"
              :error="poaProps.error"
              @remove="() => setFieldValue('associate.documents.proofOfAddress.file', undefined)"
            >
              <Tag
                v-if="poa"
                closable
                size="large"
                type="info"
                @close="() => setFieldValue('associate.documents.proofOfAddress.file', undefined)"
              >
                <Typography>{{ $t('kyc.proofOfAddress') }}</Typography>
              </Tag>
              <Upload
                v-else
                id="idCard"
                label="Upload"
                file-type=".pdf"
                v-bind="poaProps"
                @change="handleChange('associate.documents.proofOfAddress.file', $event)"
              />
            </EntityDetails>

            <EntityDetails
              icon="document"
              :title="$t('wallets.passportImage')"
              :subtitle="$t('wallets.sharePassportImage', { type: 'jpeg' })"
              :error="passportImageProps.error"
              @remove="() => setFieldValue('associate.documents.passportImage', undefined)"
            >
              <Tag
                v-if="passportImage"
                closable
                size="large"
                type="info"
                @close="() => setFieldValue('associate.documents.passportImage', undefined)"
              >
                <Typography>{{ $t('wallets.passportImage') }}</Typography>
              </Tag>
              <Upload
                v-else
                id="passportImage"
                file-type=".jpeg"
                :label="$t('kyc.upload')"
                v-bind="passportImageProps"
                @change="handleChange('associate.documents.passportImage', $event)"
              />
            </EntityDetails>
          </div>
        </el-carousel-item>

        <el-carousel-item style="height: auto">
          <div
            ref="secondPage"
            class="flex flex-col gap-4 h-[550px] overflow-scroll"
          >
            <FormControl
              :label="$t('country')"
              v-bind="countryProps"
            >
              <Select
                v-model="country"
                filterable
                class="w-full"
                size="large"
                :placeholder="`--${$t('choose')}--`"
              >
                <SelectOption
                  v-for="item in countries"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  <span class="inline-flex justify-between items-center w-full">
                    {{ item.label }}

                    <IconsCheckOutlined
                      v-if="country === item.value"
                      class="text-success900 h-4 w-4"
                    />
                  </span>
                </SelectOption>
              </Select>
            </FormControl>

            <FormControl
              :label="$t('street')"
              v-bind="streetProps"
            >
              <InputField
                v-model="street"
                type="textarea"
                :rows="3"
                :placeholder="$t('wallets.enterStreet')"
              />
            </FormControl>

            <div class="flex gap-4">
              <FormControl
                :label="$t('state')"
                v-bind="stateNameProps"
                class="flex-1"
              >
                <InputField
                  v-model="stateName"
                  :rows="5"
                  placeholder="Lagos"
                />
              </FormControl>

              <FormControl
                :label="$t('city')"
                v-bind="cityProps"
                class="flex-1"
              >
                <InputField
                  v-model="city"
                  :rows="5"
                  placeholder="Ikega"
                />
              </FormControl>
            </div>

            <FormControl
              :label="$t('wallets.stateOfBusinessIncorporation')"
              v-bind="stateOfIncorpProps"
              class="flex-1"
            >
              <InputField
                v-model="stateOfIncorp"
                :rows="5"
                placeholder="Lagos"
              />
            </FormControl>

            <FormControl
              :label="$t('customer.postalCode')"
              v-bind="postalCodeProps"
            >
              <InputField
                v-model="postalCode"
                :placeholder="$t('wallets.enterPostalCode')"
              />
            </FormControl>

            <FormControl
              :label="$t('wallets.addressType')"
              v-bind="busPoaNameProps"
            >
              <Select
                v-model="busPoaName"
                filterable
                class="w-full"
                size="large"
                :placeholder="`--${$t('choose')}--`"
              >
                <SelectOption
                  v-for="item in ADDRESS_TYPE"
                  :key="item.value"
                  :label="$t(item.label)"
                  :value="item.value"
                >
                  <span class="inline-flex justify-between items-center w-full">
                    {{ $t(item.label) }}

                    <IconsCheckOutlined
                      v-if="busPoaName === item.value"
                      class="text-success900 h-4 w-4"
                    />
                  </span>
                </SelectOption>
              </Select>
            </FormControl>

            <EntityDetails
              icon="document"
              :title="`${$t('kyc.proofOfAddress')} - ${$t('business')}`"
              :subtitle="$t('wallets.shareBusinessAddress', { type: 'pdf' })"
              :error="busPoaProps.error"
              @remove="() => setFieldValue('documents.proofOfAddress.file', undefined)"
            >
              <Tag
                v-if="busPoa"
                closable
                size="large"
                type="info"
                @close="() => setFieldValue('documents.proofOfAddress.file', undefined)"
              >
                <Typography>{{ `${$t('kyc.proofOfAddress')} - ${$t('business')}` }} </Typography>
              </Tag>
              <Upload
                v-else
                id="poa"
                file-type=".pdf"
                label="Upload"
                v-bind="busPoaProps"
                @change="handleChange('documents.proofOfAddress.file', $event)"
              />
            </EntityDetails>

            <EntityDetails
              icon="document"
              :title="$t('wallets.bankStatement')"
              :subtitle="$t('wallets.shareBankStatement', { type: 'pdf' })"
              :error="busSofProps.error"
              @remove="() => setFieldValue('documents.sourceOfFunds.file', undefined)"
            >
              <Tag
                v-if="busSof"
                closable
                size="large"
                type="info"
                @close="() => setFieldValue('documents.sourceOfFunds.file', undefined)"
              >
                <Typography>{{ $t('wallets.bankStatement') }}</Typography>
              </Tag>
              <Upload
                v-else
                id="sourceOfFunds"
                file-type=".pdf"
                :label="$t('kyc.upload')"
                v-bind="busSofProps"
                @change="handleChange('documents.sourceOfFunds.file', $event)"
              />
            </EntityDetails>
          </div>
        </el-carousel-item>
      </el-carousel>

      <Button
        :loading="creatingAcount"
        size="large"
        class="w-full mt-4"
      >
        {{ $t(step === 0 ? 'next' : 'save') }}
      </Button>
    </form>
  </Modal>
</template>
