<script setup lang="ts">
import { computed, ref } from 'vue'
import allCountries from '~/src/constants/countries'
import { CountryIso, MAX_PHONE_LENGTH } from '~/src/types/internals'

const props = withDefaults(
  defineProps<{
    disableCountrySelector?: boolean
    id: string
    modelValue: string
    iso?: string
    prefixPhonenumber?: string
  }>(),
  {
    iso: CountryIso.NGN,
    disableCountrySelector: false,
    prefixPhonenumber: '+',
  },
)
const emit = defineEmits(['update:modelValue'])
const countries = allCountries.map(({ text, id: value, CallingCode: callingCode }) => ({
  value,
  label: `${value} - ${text}`,
  text,
  callingCode,
}))

const phoneCountry = ref(props.iso)
const selectCountry = computed(() => countries.find(({ value }) => value === phoneCountry.value))

const selectedCountryCode = computed(() => {
  if (!selectCountry.value) return ''

  return `${selectCountry.value.callingCode}`
})

const dropCallingCode = computed(() => {
  const [, acct] =
    selectCountry.value && props.modelValue
      ? props.modelValue.split(`${props.prefixPhonenumber}${selectedCountryCode.value}`)
      : [undefined, '']
  return acct
})

const phoneDisplay = ref(dropCallingCode.value)

const handleUpdatePhoneNumber = (value: string) => {
  const code = countries.find(({ value }) => value === phoneCountry.value)?.callingCode || ''

  emit('update:modelValue', `${props.prefixPhonenumber}${code}${value}`)
}

const handleUpdateCountry = (value: string) => {
  const newCountry = value
  const code = countries.find(({ value }) => value === newCountry)?.callingCode || ''

  phoneCountry.value = value
  emit('update:modelValue', `${props.prefixPhonenumber}${code}${phoneDisplay.value}`)
}

watch(
  () => props.iso,
  (country) => {
    handleUpdateCountry(country)
  },
)

watch(
  () => props.modelValue,
  () => {
    phoneDisplay.value = dropCallingCode.value
  },
)
</script>
<template>
  <SelectInput
    :id="id"
    v-model="phoneDisplay"
    label=""
    :selected="selectedCountryCode ? `${prefixPhonenumber}${selectedCountryCode}` : ''"
    :select-props="{ placeholder: `${prefixPhonenumber}00`, disabled: disableCountrySelector }"
    :maxlength="MAX_PHONE_LENGTH.toString()"
    placeholder="(000) 000-0000"
    :options="countries"
    @update:model-value="handleUpdatePhoneNumber"
    @update:selected="handleUpdateCountry"
  />
</template>
