import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js'
import { object, string, number, boolean, array } from 'yup'
import { CurrencyCode, Institution } from '~/src/types/internals'

export const createRecipientSchema = object({
  reference: string().optional(),
  bankCode: string().required().label('bank code'),
  accountNumber: string()
    .required()
    .test('is-valid-phone-number', 'Phone number does not match country selected', function (value) {
      try {
        const country = parsePhoneNumber(`+${value}`).country

        return isValidPhoneNumber(`+${value}`, country)
      } catch (error) {
        return false
      }
    }),
  meta: object({
    counterparty: object({
      firstName: string().required().label('first name'),
      lastName: string().required().label('last name'),
      email: string().required().label('email'),
      phoneNumber: string().test(
        'is-valid-phone-number',
        'Phone number does not match country selected',
        function (value) {
          if (!value) return true

          try {
            const country = parsePhoneNumber(`+${value}`).country

            return isValidPhoneNumber(`+${value}`, country)
          } catch (error) {
            return false
          }
        },
      ),
    }),
  }),
})

export const collectionApiSchema = object({
  currency: string<CurrencyCode>().oneOf(Object.values(CurrencyCode)).required().label('currency'),
  amount: number().positive().required().label('amount'),
  accountNumber: string().required().label('account number'),
  description: string().required().label('description'),
}).concat(createRecipientSchema)

export const collectionRecipientResponseSchema = object({
  status: boolean().required(),
  message: string().required(),
  data: array()
    .of(
      object({
        id: string().required(),
        currency: string<CurrencyCode>().oneOf(Object.values(CurrencyCode)).required(),
        institution: string().required(),
        accountNumber: string()
          .required()
          .test('is-valid-phone-number', 'Phone number does not match country selected', function (value) {
            try {
              const country = parsePhoneNumber(`+${value}`).country

              return isValidPhoneNumber(`+${value}`, country)
            } catch (error) {
              return false
            }
          }),
        accountName: string().required(),
        active: boolean().required(),
        type: string<Institution>().oneOf(Object.values(Institution)).required(),
        bankCode: string().required(),
        firstName: string().required(),
        lastName: string().required(),
        email: string().email().nullable(),
        phoneNumber: string().test(
          'is-valid-phone-number',
          'Phone number does not match country selected',
          function (value) {
            if (!value) return true

            try {
              const country = parsePhoneNumber(`+${value}`).country

              return isValidPhoneNumber(`+${value}`, country)
            } catch (error) {
              return false
            }
          },
        ),
        nickname: string().nullable(),
      }),
    )
    .required(),
})
