<template>
  <Modal
    modal="$atts.modal"
    :title="$t('customer.upgradeTier', { number: 2 })"
  >
    <div class="flex flex-col justify-between w-full h-full">
      <form
        class="w-full mt-6 overflow-y-auto"
        @submit.prevent="create"
      >
        <div class="flex flex-col gap-6">
          <FormControl :label="$t('customer.identityType')">
            <Select
              v-model="credential.identity.type.value"
              class="w-full"
              size="large"
              required
            >
              <SelectOption
                v-for="item in identityTypes"
                :key="item.id"
                :label="item.text"
                :value="item.id"
              >
                {{ item.text }}

                <IconsCheckOutlined
                  v-if="credential.identity.type.value === item.id"
                  class="text-success900 h-4 w-4"
                />
              </SelectOption>
            </Select>
          </FormControl>

          <FormControl :label="$t('customer.identityNumber')">
            <InputField
              v-model="credential.identity.number.value"
              :formatter="(value: string) => `${value}`.replace(/\D/g, '')"
              required
            />
          </FormControl>

          <div>
            <div class="mb-2">
              <Typography
                as="span"
                color="text-secondary"
                class="mb-2"
                variant="smMedium"
              >
                {{ $t('customer.uploadIdDoc') }}
              </Typography>
            </div>
            <DraggableUploadPreview
              v-if="idCardFile.url"
              :name="idCardFile.name || $t('kyc.proofOfIdentification')"
              :url="idCardFile.url"
              @remove="deleteIdCard"
            />
            <DraggableUpload
              v-show="!credential.identity.image.value"
              ref="idCardRef"
              accept=".pdf, .png, .jpeg, .jpg"
              subtitle="You may upload PDF, PNG, or JPEG files"
              :show-file-list="false"
              :on-change="handleUploadIdCard"
              :limit="1"
            />
          </div>

          <div>
            <div class="mb-2">
              <Typography
                as="span"
                color="text-secondary"
                class="mb-2"
                variant="smMedium"
              >
                {{ $t('customer.uploadCustPhoto') }}
              </Typography>
            </div>
            <DraggableUploadPreview
              v-if="photoFile.url"
              :name="photoFile.name || $t('kyc.photo')"
              :url="photoFile.url"
              @remove="deletePOA"
            />
            <DraggableUpload
              v-show="!credential.photo.value"
              ref="photoRef"
              accept=".pdf, .png, .jpeg, .jpg"
              subtitle="You may upload PDF, PNG, or JPEG files"
              :show-file-list="false"
              :on-change="handleUploadPhoto"
            />
          </div>

          <button
            class="mt-3 btn-primary"
            type="submit"
          >
            <span v-if="!loading"> {{ $t('customer.upgrade') }}</span> <Spinner v-else />
          </button>
        </div>
      </form>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import type { UploadInstance, UploadProps } from 'element-plus'
import { createTier2 } from '~/src/composables/apis/customer/tiersCreation'
import { toBase64 } from '~/src/composables/utils/useFile/index'

const { t } = useI18n()

const identityTypes = [
  { text: 'NIN', id: 'NIN' },
  { text: t('customer.passport'), id: 'PASSPORT' },
  { text: t('customer.votersCard'), id: 'VOTERS_CARD' },
  { text: t('customer.driversLicense'), id: 'DRIVERS_LICENSE' },
]

const { credential, create, loading } = createTier2()

const idCardFile = ref({ name: '', url: '' })
const idCardRef = ref<{ uploadRef: null | UploadInstance; beforeUpload: UploadProps['beforeUpload'] }>()

const photoFile = ref({ name: '', url: '' })
const photoRef = ref<{ uploadRef: null | UploadInstance; beforeUpload: UploadProps['beforeUpload'] }>()

const handleUploadPhoto: UploadProps['onChange'] = async (uploadFile) => {
  if (!photoRef.value!.beforeUpload(uploadFile.raw!)) {
    return
  }

  const value = (await toBase64(uploadFile.raw!)) as string

  photoFile.value.url = URL.createObjectURL(uploadFile.raw!)
  photoFile.value.name = uploadFile.name
  credential.photo.value = value
}

const handleUploadIdCard: UploadProps['onChange'] = async (uploadFile) => {
  if (!idCardRef.value!.beforeUpload(uploadFile.raw!)) {
    return
  }

  const value = (await toBase64(uploadFile.raw!)) as string

  idCardFile.value.url = URL.createObjectURL(uploadFile.raw!)
  idCardFile.value.name = uploadFile.name
  credential.identity.image.value = value
}

const deleteIdCard = () => {
  idCardRef.value!.uploadRef!.clearFiles()
  idCardFile.value = { name: '', url: '' }

  credential.identity.image.value = undefined
}

const deletePOA = () => {
  photoRef.value!.uploadRef!.clearFiles()
  photoFile.value = { name: '', url: '' }

  credential.photo.value = undefined
}
</script>

<style scoped>
.slideUp-enter,
.slideUp-leave-to {
  opacity: 0;
  transform: translateY(-200px);
}

.slideUp-enter-active,
.slideUp-leave-active {
  transition: all 0.35s;
}
</style>
