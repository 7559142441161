<template>
  <Modal
    modal="$atts.modal"
    :title="$t('wallets.fundWallet')"
    type="settings"
  >
    <p
      v-if="state.currency === CurrencyCode.NGN && virtualAccounts.length !== 0"
      class="text-greyDark text-sm text-start mt-3 max-w-[400px]"
    >
      {{ $t('wallets.customersCanPay', { currency: CurrencyCode.NGN }) }}
    </p>
    <div
      v-if="state.currency !== CurrencyCode.NGN"
      class="flex flex-col w-full justify-between mt-6"
    >
      <article class="flex flex-col items-start gap-4 justify-start px-5 py-6 w-full bg-[#476EF90D] rounded-[10px]">
        <h3 class="text-base font-semibold -mt-3">{{ $t('transactions.mapleradTransfer') }}</h3>

        <p class="flex justify-between w-full items-center">
          <span class="text-xs text-gray600">{{ $t('wallets.accountNumber') }}</span>
          <span class="text-sm text-secondary font-medium flex gap-3">
            {{ business?.account_number }}
            <icon
              name="copy"
              class="w-4 text-tertiary cursor-pointer"
              @click="copyData(business?.account_number, $t('copiedToClipboard'))"
            />
          </span>
        </p>
      </article>
    </div>

    <div
      v-if="state.currency === CurrencyCode.NGN"
      class="flex flex-col w-full h-full justify-between mt-3"
    >
      <div
        v-if="virtualAccounts.length === 0 && !loading"
        class="flex flex-col items-start gap-4 justify-start px-5 py-12 w-full bg-[#476EF90D] rounded-[10px]"
      >
        <p class="text-base text-center">
          {{ $t('wallets.couldNotLoad') }}
        </p>
      </div>

      <div
        v-else
        class="flex flex-col gap-4"
      >
        <SkeletonLoader
          v-if="loading"
          height="140px"
          radius="10px"
        />
        <article
          v-for="(item, index) in virtualAccounts"
          :key="item.id"
          class="flex flex-col items-start gap-4 justify-start px-5 py-6 w-full bg-[#476EF90D] rounded-[10px]"
        >
          <h3 class="text-base font-semibold -mt-3">{{ $t('wallets.accountCount', { count: index + 1 }) }}</h3>
          <p class="flex justify-between w-full items-center">
            <span class="text-xs text-gray600">{{ $t('bank') }}</span>
            <span class="text-sm text-secondary font-medium flex gap-3">
              {{ loading ? `${$t('loading')}...` : item.bankName }}
              <icon
                name="copy"
                class="w-4 text-tertiary"
                @click="copyData(item.bankName, $t('copiedToClipboard'))"
              />
            </span>
          </p>
          <p class="flex justify-between w-full items-center">
            <span class="text-xs text-gray600">{{ $t('wallets.accountName') }}</span>
            <span class="text-sm text-secondary font-medium flex gap-3">
              {{ loading ? 'loading...' : item.accountName }}
              <icon
                name="copy"
                class="w-4 text-tertiary"
                @click="copyData(item.accountName, $t('copiedToClipboard'))"
              />
            </span>
          </p>
          <p class="flex justify-between w-full items-center">
            <span class="text-xs text-gray600">{{ $t('wallets.accountNumber') }}</span>
            <span class="text-sm text-secondary font-medium flex gap-3">
              {{ loading ? 'loading...' : item.accountNumber }}
              <icon
                name="copy"
                class="w-4 text-tertiary"
                @click="copyData(item.accountNumber, $t('copiedToClipboard'))"
              />
            </span>
          </p>
          <button
            class="btn-primary font-medium mt-5"
            @click="copyData(generateAccount(item), $t('copiedToClipboard'))"
          >
            {{ $t('wallets.copyAccount', { count: index + 1 }) }}
          </button>
        </article>
      </div>
    </div>

    <!-- <div v-else class="flex flex-col items-start gap-4 justify-start px-5 py-12 w-full bg-[#476EF90D] rounded-[10px] mt-6">
			<p class="text-base text-center">
				We currently do not have support for direct wallet transfer for other accounts apart from naira, kindly contact support
			</p>
		</div> -->
  </Modal>
</template>

<script lang="ts" setup>
import { useFundWallet } from '~/src/composables/apis/wallet/index.js'
import { VirtualAccount } from '~/src/services/requests/wallet/types'
import { copyToClipboard } from '~/src/composables/utils/useFile/index'
import { useBusiness } from '~/src/composables/apis/business'
import { CurrencyCode } from '~/src/types/internals'

const { business } = useBusiness()
const { copyData } = copyToClipboard()
const { getVirtualAccounts, virtualAccounts, loading, state } = useFundWallet()

onMounted(async () => {
  if (state.currency === CurrencyCode.NGN) await getVirtualAccounts(CurrencyCode.NGN)
})

const generateAccount = (data: VirtualAccount) => {
  return `Bank: ${data.bankName} \nAccount Name: ${data.accountName} \nAccount Number: ${data.accountNumber}`
}
</script>

<style scoped>
svg {
  @apply cursor-pointer;
}
.slideUp-enter {
  opacity: 0;
  /* transform: translateY(200px); */
}
.slideUp-leave-to {
  opacity: 0;
  /* transform: translateY(-200px); */
}

.slideUp-enter-active,
.slideUp-leave-active {
  transition: all 0.35s ease;
}
</style>
