<script setup lang="ts">
import { maCurrencyFormatter, MaIcon } from '@wirepay/maplerad-component-lib'
import { toTypedSchema } from '@vee-validate/yup'
import { useForm } from 'vee-validate'
import { useStashModal } from '~/src/composables/core/modals'
import { useStash } from '~/src/composables/apis/stash'
import { toUnit } from '~/src/helpers/fromMinorUnit'
import { CountryIso, CurrencyCode } from '~/src/types/internals'
import { fundStash } from '~/src/services/requests/stash/schema'
import { elPlusConfig } from '~/src/constants'
import { countriesCode, currencies } from '~/src/constants/countriesCode'
import { getWallet } from '~/src/composables/apis/wallet'

const { state, resetFunding, fundStash: fundStashRequest } = useStash()
const { fetchWallet, walletData } = getWallet()
const { t } = useI18n()

const step = ref(0)
const carousel = ref<InstanceType<any> | null>(null)

const prev = () => carousel.value?.prev()
const next = () => carousel.value?.next()

const walletBalance = computed(() => {
  const countryCode = countriesCode.find((item) => item.iso === CountryIso.USD)

  if (!countryCode) return ''

  const wallet = walletData.value.find((wallet) => wallet.currency === countryCode.id)

  if (!wallet) return ''

  return maCurrencyFormatter(countryCode.id).format(toUnit(wallet.availableBalance || 0))
})

const { defineField, handleSubmit } = useForm({
  initialValues: state.funding,
  validationSchema: toTypedSchema(fundStash),
})

const [amount, amountProps] = defineField('amount', elPlusConfig)

const submit = handleSubmit((values) => {
  if (step.value === 0) {
    next()
    return
  }

  fundStashRequest(values, {
    onSuccess: () => {
      state.fundingSuccess = {
        title: t('stash.paymentApplied', { planName: state.stash?.name }),
        description: t('stash.paymentAddedMessage', {
          amount: maCurrencyFormatter(CurrencyCode.USD).format(toUnit(amount.value || 0)),
          planName: investment.value?.name,
        }),
        actionName: t('stash.allDone'),
        id: investment.value?.id || '',
      }
      useStashModal().closeFundStash()
      useStashModal().openPlanCreated()
    },
  })
})

const cancel = () => {
  useStashModal().closeFundStash()
  resetFunding()
}

!walletData.value.length && fetchWallet()
const investment = computed(
  () =>
    state.investment ||
    state.businessInvestments.data.find((investmentItem) => investmentItem.id === state.funding?.investmentId),
)
</script>

<template>
  <Modal
    modal="$atts.modal"
    width="448px"
    @close="useStashModal().closePlanCreated"
  >
    <form
      class="w-full"
      @submit.prevent="submit"
    >
      <el-carousel
        ref="carousel"
        height="auto"
        arrow="never"
        :autoplay="false"
        :loop="false"
        indicator-position="none"
        @change="(newIndex: number) => (step = newIndex)"
      >
        <el-carousel-item style="height: auto">
          <div class="flex flex-col gap-8">
            <div>
              <Typography
                variant="lgBold"
                class="mb-1"
              >
                {{ $t('stash.addMoney') }}
              </Typography>
              <Typography variant="base">
                {{ $t('stash.fundStashPlan', { currency: CurrencyCode.USD }) }}
              </Typography>
            </div>

            <FormControl v-bind="amountProps">
              <CurrencyInput
                id="amount"
                :model-value="amount?.toString()"
                :selected="`${CurrencyCode.USD} Wallet`"
                :select-props="{ disabled: true, width: '130px' }"
                :label="$t('amount')"
                placeholder="0.00"
                :options="currencies.map((curr) => ({ label: `${curr} Wallet`, value: curr }))"
                @update:model-value="($event: string) => (amount = Number($event))"
              />
              <Typography
                class="flex gap-1 mt-0.5"
                color="text-gray800"
                variant="sm"
                :class="{ invisible: !walletBalance }"
              >
                {{ $t('stash.currentBalance') }}
                <Typography
                  variant="smMedium"
                  color="text-gray900"
                >
                  {{ walletBalance }}
                </Typography>
              </Typography>
            </FormControl>

            <div>
              <Typography as="label">{{ $t('to') }} </Typography>
              <div
                v-if="investment"
                class="mt-2 w-full p-3 rounded-xl border border-divider flex items-center shadow-gray900/10"
              >
                <div class="p-2.5 rounded-full bg-gray300">
                  <MaIcon
                    name="stash"
                    class="w-5 h-5"
                  />
                </div>

                <div class="ml-4">
                  <Typography
                    variant="baseMedium"
                    color="text-gray900"
                    class="text-left block"
                    as="span"
                  >
                    {{ investment.name }}
                  </Typography>

                  <Typography
                    variant="xs"
                    color="text-gray500"
                    class="text-left block capitalize"
                    as="span"
                  >
                    {{ maCurrencyFormatter(CurrencyCode.USD).format(toUnit(investment.balance)) }}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>

        <el-carousel-item style="height: auto">
          <div class="flex flex-col gap-8">
            <div>
              <Typography
                variant="lgBold"
                class="mb-1"
              >
                {{ $t('stash.addMoney') }}
              </Typography>
              <Typography variant="base">
                {{ $t('stash.paymentConfirmationMessage') }}
              </Typography>
            </div>

            <div>
              <div>
                <Typography
                  variant="sm"
                  color="text-gray500"
                >
                  {{ $t('stash.paymentAmount') }}
                </Typography>
                <Typography
                  variant="xxxlBold"
                  color="text-gray900"
                  class="!font-medium"
                >
                  {{ maCurrencyFormatter(CurrencyCode.USD).format(toUnit(amount || 0)) }}
                </Typography>
              </div>

              <Divider />

              <div>
                <Typography
                  variant="sm"
                  color="text-gray500"
                >
                  {{ $t('stash.makingPaymentFrom') }}
                </Typography>

                <div
                  v-if="investment"
                  class="mt-2 w-full flex items-center shadow-gray900/10"
                >
                  <div class="p-2.5 rounded-full bg-gray300">
                    <MaIcon
                      name="stash"
                      class="w-5 h-5"
                    />
                  </div>

                  <div class="ml-4">
                    <Typography
                      variant="baseMedium"
                      color="text-gray900"
                      class="text-left block"
                      as="span"
                    >
                      {{ investment.name }}
                    </Typography>

                    <Typography
                      variant="xs"
                      color="text-gray500"
                      class="text-left block capitalize"
                      as="span"
                    >
                      {{ maCurrencyFormatter(CurrencyCode.USD).format(toUnit(investment.balance)) }}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>

            <Typography
              variant="sm"
              color="text-gray500"
            >
              {{ $t('stash.authorizationText') }}
            </Typography>
          </div>
        </el-carousel-item>
      </el-carousel>

      <div class="justify-end flex gap-4 mt-8">
        <template v-if="step === 0">
          <Button
            class="w-fit"
            type="button"
            size="large"
            variant="secondary"
            @click="cancel"
          >
            {{ $t('cancel') }}
          </Button>
          <Button
            class="w-fit"
            size="large"
            :disabled="!investment"
          >
            {{ $t('review') }}
          </Button>
        </template>
        <template v-else>
          <Button
            class="w-fit"
            type="button"
            size="large"
            variant="secondary"
            @click="prev"
          >
            {{ $t('edit') }}
          </Button>
          <Button
            class="w-fit"
            size="large"
            :disabled="!investment"
            :loading="state.loading.funding"
          >
            {{ $t('stash.confirmPayment') }}
          </Button>
        </template>
      </div>
    </form>
  </Modal>
</template>
