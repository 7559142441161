import { GATEWAY_ENDPOINT } from './SetupBase'
import { addQueryParam } from '~/src/composables/utils/useActivityChecker'
import { PAGINATION_LIMIT } from '~/src/constants'

export const customerApi = {
  $_getAllCustomers: (page = 1, credential: any, limit = PAGINATION_LIMIT) => {
    const url = ref('/customers?')

    addQueryParam(url, 'page', page)
    addQueryParam(url, 'page_size', limit)
    addQueryParam(url, 'start_date', credential.start_date.value)
    addQueryParam(url, 'end_date', credential.end_date.value)
    addQueryParam(url, 'search', credential.search.value)
    addQueryParam(url, 'status', credential.status.value)

    url.value = url.value.slice(0, -1)
    return GATEWAY_ENDPOINT.get(url.value)
  },
  $_createCustomer: (credential: any) => {
    const url = '/customers/enroll'
    return GATEWAY_ENDPOINT.post(url, credential)
  },
  $_blacklistCustomer: (id: string, credential: any) => {
    const url = `/customers/${id}/active`
    return GATEWAY_ENDPOINT.post(url, credential)
  },
  $_getCustomerCards: (id: string) => {
    const url = `/issuing/?customer_id=${id}`
    return GATEWAY_ENDPOINT.get(url)
  },
  $_getCustomerTransactions: (id: string) => {
    const url = `/customers/${id}/transactions`
    return GATEWAY_ENDPOINT.get(url)
  },
  $_getCustomerVirtualAccounts: (id: string) => {
    const url = `/customers/${id}/virtual-account`
    return GATEWAY_ENDPOINT.get(url)
  },
  $_getCustomer: (id: string) => {
    const url = `/customers/${id}`
    return GATEWAY_ENDPOINT.get(url)
  },
  $_createTier_2: (credential: any) => {
    const url = '/customers/upgrade/tier2'
    return GATEWAY_ENDPOINT.patch(url, credential)
  },
  $_createTier_1: (credential: any) => {
    const url = '/customers/upgrade/tier1'
    return GATEWAY_ENDPOINT.patch(url, credential)
  },
  $_createTier_0: (credential: any) => {
    const url = '/customers'
    return GATEWAY_ENDPOINT.post(url, credential)
  },
}
