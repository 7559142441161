<template>
  <el-date-picker
    class="date-picker"
    v-bind="$attrs"
  >
    <template
      v-for="(_, slot) of $slots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </el-date-picker>
</template>
<style>
.date-picker .el-input__wrapper {
  --el-input-border-radius: 8px;
  @apply bg-white;
}
.el-date-editor {
  --el-input-border-color: var(--gray10);
  --el-input-focus-border-color: var(--tertiary);
  --el-input-bg-color: var(--gray100);
}
</style>
