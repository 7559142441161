import { ref } from 'vue'
import { useStorage } from '@vueuse/core'
import { Business, globalInterface } from './types'
import { useAlert } from '~/src/composables/core/useNotification'
import { businessApi } from '~/src/services/requests/business'
import { NewBusiness } from '~/src/services/requests/business/type'
import { ApprovalStatus } from '~/src/types/api'

export const global: globalInterface = {
  auth: ref(null),
  business: useStorage('business', {}),
}

export const step = ref(1)

export function useBusiness() {
  // TODO: Use storage to manage local storage interaction
  const updating = ref(false)

  const businesses = useStorage('businesses', [] as any)

  const hasAnApprovedBusiness = computed(() => {
    const ownedBusinesses = businesses.value.filter((business: Business) => business.is_owner)

    return ownedBusinesses.some((business: Business) => business.approval_status === ApprovalStatus.Approved)
  })

  const businessesPaginator = useStorage('businessesPaginator', {
    page: 1,
    page_size: 10,
    total: 0,
  })

  const hasNextPage = computed(
    () => businessesPaginator.value.total > businessesPaginator.value.page * businessesPaginator.value.page_size,
  )

  const user = sessionStorage.getItem('user') ? ref(JSON.parse(sessionStorage.getItem('user') as string)) : {}

  const updateSelectedBusiness = async (data: any) => {
    global.business.value = data

    await sessionStorage.setItem('business', JSON.stringify(data))
  }

  const fetchAllBusinesses = async () => {
    const res = await businessApi.$_getMyBussiness()

    businesses.value = res?.data
    sessionStorage.setItem('businesses', JSON.stringify(res?.data))
    businessesPaginator.value = res.meta
  }

  const loadMoreBusinesses = async () => {
    const res = await businessApi.$_getMyBussiness({
      page: businessesPaginator.value.page + 1,
      page_size: businessesPaginator.value.page_size,
    })

    businesses.value.push(...res?.data)

    sessionStorage.setItem('businesses', JSON.stringify(businesses))
    businessesPaginator.value = res.meta
  }

  const setDefaultBusiness = async (id?: string) => {
    if (!businesses.value?.length) return

    const currentBus = businesses.value.find((item: any) => item.id === id)
    await updateSelectedBusiness(currentBus || businesses.value[0])
  }

  const updateBusiness = async (business: Partial<Business>, options?: { onSuccess?: () => void }) => {
    if (!business.id) return
    const { id: _, ...rest } = business

    updating.value = true
    const res = await businessApi.$_updateBusiness(rest)

    const currentBus = businesses.value.find((bus: any) => bus.id === business.id)

    if (res?.status) {
      updateSelectedBusiness({
        ...currentBus,
        ...business,
      })

      options?.onSuccess?.()
    }

    updating.value = false
  }

  return {
    businesses,
    user,
    updating,
    hasNextPage,
    updateBusiness,
    updateSelectedBusiness,
    loadMoreBusinesses,
    ...global,
    step,
    hasAnApprovedBusiness,
    setDefaultBusiness,
    fetchAllBusinesses,
  }
}

export const createBusiness = () => {
  const { businesses } = useBusiness()

  const loading = ref(false)
  const create = async (data: NewBusiness, options?: { onSuccess?: () => void }) => {
    loading.value = true
    const res = (await businessApi.$_createBusiness(data)) as any

    if (res !== 'ERROR') {
      await useBusiness().updateSelectedBusiness(res.data.data)

      const currBussinesses = JSON.parse(sessionStorage.getItem('businesses') as string) || []
      businesses.value.push(res.data.data)
      currBussinesses.push(res.data.data)
      sessionStorage.setItem('businesses', JSON.stringify(currBussinesses))

      options?.onSuccess?.()
    }

    loading.value = false
  }
  return { create, loading, step }
}

export const getBusinessesSettings = () => {
  const loading = ref(false)
  const generatingKeys = ref(false)
  const SettingsData = ref<Record<string, string>>({})
  const secretKey = ref('')

  const fetchBusinessSettings = async () => {
    loading.value = true
    const res = await businessApi.$_getBusinessSettings()
    loading.value = false
    if (res !== 'ERROR') {
      SettingsData.value = res.data.data
    }
  }

  const generateSecretKey = async () => {
    generatingKeys.value = true
    const res = await businessApi.$_generateKeys()
    generatingKeys.value = false
    if (res !== 'ERROR') {
      useAlert().openAlert({ type: 'SUCCESS', msg: res.data.message })
      secretKey.value = res.data.data.secretKey
    }
  }

  return { fetchBusinessSettings, generateSecretKey, secretKey, generatingKeys, loading, SettingsData }
}
