import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js'
import { boolean, date, number, object, string } from 'yup'
import { fromUnit } from '~/src/helpers/fromMinorUnit'
import {
  CurrencyCode,
  TransactionType,
  TransactionEntryType,
  TransactionStatus,
  TransactionChannel,
} from '~/src/types/internals'
export * from './recipient'

export const collectionOtpSchema = object({
  otp: string().required(),
  transactionId: string().required(),
})

export const collectionResponseSchema = object({
  status: boolean().required(),
  message: string().required(),
  data: object({
    id: string().required(),
    currency: string<CurrencyCode>().oneOf(Object.values(CurrencyCode)).required(),
    status: string<TransactionStatus>().oneOf(Object.values(TransactionStatus)).required(),
    entry: string<TransactionEntryType>().oneOf(Object.values(TransactionEntryType)).required(),
    type: string<TransactionType>().oneOf(Object.values(TransactionType)).required(),
    amount: number().required(),
    summary: string().required(),
    reason: string().nullable(),
    fee: number().required(),
    reference: string().nullable(),
    createdAt: string().required(),
    updatedAt: string().required(),
    requiresOtp: boolean().required(),
    otpInstruction: object({
      details: string().required(),
      length: number().required(),
    }),
  }),
}).required()

export const collectionStepTwoSchema = object().shape({
  amount: number()
    .positive()
    .required()
    .test('minimum', 'minimum value is 5', function (value) {
      return fromUnit('5') <= value
    }),
})
export const collectionStepThreeSchema = object().shape({
  memo: string().optional(),
  description: string().required().label('notes'),
})

export const collectionWithNewRecipientSchema = object({
  currency: string<CurrencyCode>().oneOf(Object.values(CurrencyCode)).required().label('currency'),
  bankCode: string().required().label('operator'),
  amount: number().positive().required().label('amount'),
  notifyCounterparty: boolean().optional().label('notify counterparty'),
  accountNumber: string()
    .required()
    .test('is-valid-phone-number', 'Phone number does not match country selected', function (value) {
      try {
        const country = parsePhoneNumber(`+${value}`).country

        return isValidPhoneNumber(`+${value}`, country)
      } catch (error) {
        return false
      }
    }),
  reference: string().optional(),
  description: string().required().label('notes'),
  meta: object({
    counterparty: object({
      firstName: string().required().label('first name'),
      lastName: string().required().label('last name'),
      email: string().email().label('email'),
      phoneNumber: string().test(
        'is-valid-phone-number',
        'Phone number does not match country selected',
        function (value) {
          if (!value) return true

          try {
            const country = parsePhoneNumber(`+${value}`).country

            return isValidPhoneNumber(`+${value}`, country)
          } catch (error) {
            return false
          }
        },
      ),
    }),
  }),
})

export const collectionWithRecipientSchema = object({
  currency: string<CurrencyCode>().oneOf(Object.values(CurrencyCode)).required().label('currency'),
  notifyCounterparty: boolean().optional().label('notify counterparty'),
  bankCode: string().required().label('bank code'),
  amount: number().positive().required().label('amount'),
  accountNumber: string().required().label('account number'),
  reference: string().optional(),
  description: string().required().label('description'),
  counterpartyId: string().uuid().required().label('counterparty id'),
})

export const createCollectionWithExistingRecipientSchema = object({
  currency: string<CurrencyCode>().oneOf(Object.values(CurrencyCode)).required().label('currency'),
  bankCode: string().required().label('bank code'),
  accountNumber: string().required().label('account number'),
  reference: string().optional(),
  counterpartyId: string().uuid().required().label('counterparty id'),
})

export const collectionTransactionResponseSchema = object({
  status: boolean().required(),
  message: string().required(),
  data: object({
    id: string().uuid().required(),
    status: string<TransactionStatus>().oneOf(Object.values(TransactionStatus)).required(),
    entry: string<TransactionEntryType>().oneOf(Object.values(TransactionEntryType)).required(),
    type: string<TransactionType>().oneOf(Object.values(TransactionType)).required(),
    amount: number().required(),
    fee: number().required(),
    currency: string<CurrencyCode>().oneOf(Object.values(CurrencyCode)).required(),
    channel: string<TransactionChannel>().oneOf(Object.values(TransactionChannel)).required(),
    summary: string().nullable(),
    reason: string().nullable(),
    reference: string().nullable(),
    accountId: string().nullable(),
    createdAt: date().required(),
    updatedAt: date().required(),
    customer: object().nullable(),
    source: object()
      .shape({
        bankName: string(),
        bankCode: string(),
        accountName: string(),
        accountNumber: string(),
      })
      .nullable(),
    counterParty: object({
      bankName: string().required(),
      bankCode: string().required(),
      accountName: string().required(),
      accountNumber: string().required(),
    }),
    requireOtp: boolean().required(),
    otpInstruction: object({
      details: string().required(),
      length: number().required(),
    }),
    meta: object().nullable(),
    business: object({
      name: string().required(),
      companyName: string().required(),
      email: string().email().required(),
      address: string().nullable(),
    }),
  }),
}).required()
