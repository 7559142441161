import { useModal } from './modal'
import CreateCustomer from '@/components/modals/customers/CreateCustomer/index.vue'
import CreateTier0 from '@/components/modals/customers/CreateTier0.vue'
import CreateTier1 from '@/components/modals/customers/CreateTier1.vue'
import CreateTier2 from '@/components/modals/customers/CreateTier2.vue'
import CreateAccounts from '@/components/modals/Accounts/createAccount.vue'
import Webhook from '@/components/modals/settings/Webhook.vue'
import Support from '@/components/modals/settings/Support.vue'
import IpWhitelist from '@/components/modals/settings/IpWhitelist.vue'
import NewTeam from '@/components/modals/settings/NewTeam/index.vue'
import NewRole from '@/components/modals/settings/NewRole/index.vue'
import DeleteTeam from '@/components/modals/settings/Team/DeleteTeam.vue'
import DeleteIpAddress from '@/components/modals/settings/DeleteIpAddress.vue'
import BankTransferCountry from '@/components/modals/transfers/BankTransferCountry/index.vue'
import CreateBankTransfer from '@/components/modals/transfers/CreateBankTransfer/index.vue'
import CreateMapleradTransfer from '@/components/modals/transfers/CreateMapleradTransfer.vue'
import ReceiveFund from '@/components/modals/transfers/ReceiveFund.vue'
import MobileMoneyTransfer from '@/components/modals/transfers/MobileMoneyTransfer/index.vue'
import CreateUSDTransfer from '@/components/modals/transfers/CreateUSDTransfer/index.vue'
import TransferDetails from '@/components/modals/transfers/TransferDetails.vue'
import ConfimationModal from '@/components/modals/transfers/ConfimationModal.vue'
import BuyAirtime from '@/components/modals/bills/BuyAirtime.vue'
import CreateCard from '@/components/modals/cards/createCard.vue'
import GenerateStatement from '@/components/modals/cards/generateStatement.vue'
import CreateBusinessCard from '@/components/modals/cards/createBusinessCard.vue'
import CreditCard from '@/components/modals/cards/CreditCard.vue'
import DebitCard from '@/components/modals/cards/DebitCard.vue'
import FundWallet from '@/components/modals/wallet/FundWallet.vue'
import EditLimit from '@/components/modals/wallet/EditLimit.vue'
import FundProdWallet from '@/components/modals/wallet/FundProdWallet.vue'
import CreateVirtualAccount from '@/components/modals/wallet/CreateVirtualAccount.vue'
import VirtualAccountTAndC from '@/components/modals/wallet/VirtualAccountTAndC.vue'
import ConvertCurrency from '@/components/modals/wallet/ConvertCurrency.vue'
import WalletConverter from '@/components/modals/wallet/WalletConverter.vue'
import MobileSideBar from '@/components/shared/sidebars/Mobile.vue'
import GenerateWalletStatement from '@/components/modals/wallet/generateWalletStatement.vue'
import TransactionDetails from '@/components/modals/transactions/TransactionDetails.vue'
import Payment from '@/components/modals/transactions/Payment.vue'
import AddShareholder from '~/src/components/modals/kyc/AddShareholder.vue'
import ConfirmDirectorComplete from '~/src/components/modals/kyc/ConfirmDirectorComplete.vue'
import FraudPopup from '~/src/components/modals/home/FraudPopup.vue'
import Export from '~/src/components/modals/home/Export.vue'
import UnRegisteredBusiness from '~/src/components/modals/auth/UnRegisteredBusiness.vue'
import PlanCreated from '@/components/modals/stash/PlanCreated/index.vue'
import FundStash from '@/components/modals/stash/Fund/index.vue'

type CustomerTypes = 'CreateCustomer' | 'CreateTier0' | 'CreateTier1' | 'CreateTier2'
type SettingTypes =
  | 'Support'
  | 'Webhook'
  | 'MobileSideBar'
  | 'IpWhitelist'
  | 'DeleteIpAddress'
  | 'NewTeam'
  | 'NewRole'
  | 'DeleteTeam'
type AccountTypes = 'CreateAccounts'
type TransferTypes =
  | 'BankTransferCountry'
  | 'CreateBankTransfer'
  | 'CreateUSDTransfer'
  | 'TransferDetails'
  | 'CreateMapleradTransfer'
  | 'ConfimationModal'
  | 'MobileMoneyTransfer'
  | 'ReceiveFund'
type BillTypes = 'BuyAirtime'
type CardTypes = 'CreateCard' | 'CreditCard' | 'DebitCard' | 'CreateBusinessCard' | 'GenerateStatement'
type WalletTypes =
  | 'FundWallet'
  | 'FundProdWallet'
  | 'VirtualAccountTAndC'
  | 'CreateVirtualAccount'
  | 'ConvertCurrency'
  | 'EditLimit'
  | 'WalletConverter'
  | 'GenerateWalletStatement'
type TransactionTypes = 'TransactionDetails' | 'Payment'
type KycTypes = 'AddShareholder' | 'ConfirmDirectorComplete'
type HomeTypes = 'FraudPopup' | 'Export'
type AuthTypes = 'UnRegisteredBusiness' | 'Export'
type StashTypes = 'PlanCreated' | 'FundStash'

const CustomersModal = { CreateCustomer, CreateTier0, CreateTier1, CreateTier2 } as Record<CustomerTypes, any>
const SettingsModal = {
  Webhook,
  Support,
  MobileSideBar,
  IpWhitelist,
  DeleteIpAddress,
  NewTeam,
  NewRole,
  DeleteTeam,
} as Record<SettingTypes, any>
const AccountsModal = { CreateAccounts } as Record<AccountTypes, any>
const TransfersModal = {
  BankTransferCountry,
  CreateBankTransfer,
  CreateUSDTransfer,
  TransferDetails,
  CreateMapleradTransfer,
  ConfimationModal,
  MobileMoneyTransfer,
  ReceiveFund,
} as Record<TransferTypes, any>
const BillsModal = { BuyAirtime } as Record<BillTypes, any>
const CardsModal = { CreateCard, CreditCard, DebitCard, CreateBusinessCard, GenerateStatement } as Record<
  CardTypes,
  any
>
const WalletsModal = {
  FundWallet,
  FundProdWallet,
  VirtualAccountTAndC,
  CreateVirtualAccount,
  ConvertCurrency,
  EditLimit,
  WalletConverter,
  GenerateWalletStatement,
} as Record<WalletTypes, any>
const TransactionsModal = { TransactionDetails, Payment } as Record<TransactionTypes, any>
const KycModal = { AddShareholder, ConfirmDirectorComplete } as Record<KycTypes, any>
const HomeModal = { FraudPopup, Export } as Record<HomeTypes, any>
const AuthModal = { Export, UnRegisteredBusiness } as Record<AuthTypes, any>
const StashModal = { PlanCreated, FundStash } as Record<StashTypes, any>

export const modal = useModal()
const customerModal = modal.register('Customers', CustomersModal)
const settingModal = modal.register('Settings', SettingsModal)
const accountModal = modal.register('Accounts', AccountsModal)
const transferModal = modal.register('Transfers', TransfersModal)
const billModal = modal.register('Bills', BillsModal)
const cardModal = modal.register('Cards', CardsModal)
const walletModal = modal.register('Wallets', WalletsModal)
const transactionModal = modal.register('Wallets', TransactionsModal)
const kycModal = modal.register('KYC', KycModal)
const homeModal = modal.register('Home', HomeModal)
const authModal = modal.register('Auth', AuthModal)
const stashModal = modal.register('Stash', StashModal)

export const useCustomerModal = () => customerModal
export const useSettingsModal = () => settingModal
export const useAccountsModal = () => accountModal
export const useTransfersModal = () => transferModal
export const useBillsModal = () => billModal
export const useCardsModal = () => cardModal
export const useWalletsModal = () => walletModal
export const useTransactionsModal = () => transactionModal
export const useKycModal = () => kycModal
export const useHomeModal = () => homeModal
export const useAuthModal = () => authModal
export const useStashModal = () => stashModal
