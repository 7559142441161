<template>
  <Modal
    modal="$atts.modal"
    :title="$t('wallets.editLowLimit')"
  >
    <p
      v-if="error"
      class="text-red mt-4 font-medium"
    >
      {{ error }}
    </p>
    <form
      class="flex flex-col gap-4 mt-4 w-full"
      @submit.prevent="handleEdit"
    >
      <div class="flex flex-col gap-4 mt-2">
        <FormControl>
          <CurrencyInput
            id="fund-card"
            v-model="amount"
            v-model:selected="editLimitModalType"
            :label="$t('amount')"
            placeholder="0.00"
            :options="CurrencyArray.map((curr) => ({ value: curr.id, label: curr.name }))"
          />
        </FormControl>
      </div>

      <Button
        class="ml-auto"
        :loading="loading"
        :disabled="loading || !amount"
        size="large"
      >
        {{ $t('edit') }}
      </Button>
    </form>
  </Modal>
</template>

<script lang="ts" setup>
import { editLimit } from '~~/src/composables/apis/wallet/index.js'
import { CurrencyArray } from '~/src/constants/index'
import { useWalletsModal } from '~/src/composables//core/modals'

const { edit, amount, editLimitModalType, loading, error } = editLimit()

const handleEdit = () => {
  edit(Number(amount.value), {
    onSuccess: () => {
      useWalletsModal().closeEditLimit()
    },
  })
}
</script>
