import { resolveAccount, getBanks } from '../institution'
import { useAlert } from '~/src/composables/core/useNotification'
import { useTransfersModal } from '~/src/composables/core/modals'
import { transferApi } from '~/src/services/requests/transactions/index'
import { CurrencyCode, IdentificationType, Institution, TransferScheme } from '~/src/types/internals'
import { ROUTES_NAMES } from '~/src/routes'

const NairaTransferLoading = ref(false)

const step = ref(1)

const state = reactive({
  credential: {
    bank_code: '',
    country: '' as CurrencyCode,
    account_number: '',
    amount: '',
    reason: '',
    to: '',
    meta: {
      scheme: TransferScheme.BANKACCOUNT,
      sender: {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        address: '',
        country: '',
        dob: '',
        nationality: '',
        state: '',
        postal_code: '',
        city: '',
        id: {
          type: IdentificationType.NationalPassport,
          number: '',
          issued_at: '',
          issued_date: '',
          expiration_date: '',
        },
      },
      counterparty: {
        name: '',
        phone_number: '',
      },
    },
  },
})

const { fetch: fetchBanks, loading: bankLoading } = getBanks()

export const createBankTransfer = () => {
  const error = ref('')
  const disabled = computed(() => {
    if (
      state.credential.bank_code === '' ||
      state.credential.amount === '' ||
      state.credential.account_number.toString() === '' ||
      /[a-zA-Z]/.test(state.credential.account_number)
    )
      return true
    if (state.credential.country === CurrencyCode.NGN && resolved.value === '') return true
    if (state.credential.country !== CurrencyCode.NGN && state.credential.reason === '') return true
    else return false
  })

  watch(
    () => state.credential.country,
    async (val, prevVal) => {
      if (!val || !prevVal || val === prevVal) return
      switch (val) {
        case CurrencyCode.NGN:
          await fetchBanks(Institution.NUBAN)
          break
        case CurrencyCode.GHS:
          await fetchBanks(Institution.BOG, 'GH')
          break
        case CurrencyCode.KES:
          await fetchBanks(Institution.CBK, 'KE')
          break
      }
      state.credential.bank_code = ''
    },
    { immediate: true },
  )

  watch(
    () => state.credential.bank_code,
    () => {
      state.credential.account_number = ''
      resolved.value = ''
      error.value = ''
    },
  )

  const { fetch, loading: AccLoading, resolved } = resolveAccount()

  watch(
    () => state.credential.account_number,
    async (val) => {
      if (state.credential.country !== CurrencyCode.NGN || NairaTransferLoading.value) return

      if (val.toString().length !== 0 && (val.toString().length < 10 || val.toString().length > 10)) {
        error.value = 'Invalid Account Number'
        resolved.value = ''
      }

      if (/[a-zA-Z]/.test(val.toString())) {
        error.value = "Account Number Can't contain strings"
        resolved.value = ''
      }

      if (val.toString().length === 10) {
        error.value = ''
        if (state.credential.country === CurrencyCode.NGN) {
          await fetch({
            accountNumber: state.credential.account_number.toString(),
            bankCode: state.credential.bank_code,
          })
          state.credential.to = resolved.value
        }
      }
    },
  )
  const create = async () => {
    // Todo: create type for payload
    const payload = {
      bank_code: state.credential.bank_code,
      account_number: state.credential.account_number,
      amount: Number(state.credential.amount),
      currency: state.credential.country,
      reason: state.credential.reason,
      meta: state.credential.country !== CurrencyCode.NGN ? state.credential.meta : undefined,
    }

    NairaTransferLoading.value = true

    const res = (await transferApi.$_createTransfer(payload)) as any
    NairaTransferLoading.value = false

    if (res !== 'ERROR') {
      useTransfersModal().closeCreateBankTransfer()
      useRouter().push({
        name: ROUTES_NAMES.transactions,
      })
      useAlert().openAlert({ type: 'SUCCESS', msg: res.data.message })
    }
  }
  return { step, state, create, NairaTransferLoading, AccLoading, error, disabled, resolved, bankLoading }
}
