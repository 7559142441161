export const moneyFormatter = (value: string) => {
  const num = parseFloat(
    value
      .replace(/(.*){1}/, '0$1') // Ensure there's at least one digit before the decimal point
      .replace(/[^\d]/g, '') // Remove non-digit characters
      .replace(/(\d\d?)$/, '.$1'), // Add a decimal point before the last two digits
  ).toFixed(2)

  // Split the number into integer and fractional parts
  const parts = num.split('.')

  // Format the integer part with commas for thousands separators
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return parts.join('.')
}
