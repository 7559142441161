<script setup lang="ts">
import T_AND_C from '~/src/constants/layer2-tanc'
import { useWalletsModal } from '~/src/composables/core/modals'

const { openCreateVirtualAccount, closeVirtualAccountTAndC } = useWalletsModal()

const acknowledgement = ref(false)
const html = ref(T_AND_C)

const openAccountModal = () => {
  closeVirtualAccountTAndC()
  openCreateVirtualAccount()
}
</script>
<template>
  <Modal
    modal="$atts.modal"
    :title="$t('wallets.tandc')"
    width="30rem"
    closable
  >
    <div class="max-h-[400px] overflow-x-scroll">
      <!-- eslint-disable-next-line prettier/prettier vue/no-v-html eslint-disable-next-line vue/no-v-text-v-html-on-component -->
      <Typography class="my-8 overflow-y-hidden" v-html="html" />
    </div>

    <Checkbox
      v-model="acknowledgement"
      class="my-4"
    >
      <Typography>
        {{ $t('wallets.acceptTandC') }}
      </Typography>
    </Checkbox>

    <Button
      class="w-full"
      :disabled="!acknowledgement"
      @click="openAccountModal"
    >
      {{ $t('continue') }}
    </Button>
  </Modal>
</template>
