import { AxiosResponse } from 'axios'
import Recase from 'better-recase'
import { GATEWAY_ENDPOINT } from '../../SetupBase'
import { SnakeMomoTransfer } from '../transfers/types'
import { TransactionDetailsResponse } from './type'
import { addQueryParam } from '~/src/composables/utils/useActivityChecker'
import { toMinorUnit } from '~/src/helpers/toMinorUnit'
import { PAGINATION_LIMIT } from '~/src/constants'

export const transferApi = {
  $_createTransfer: (credential: SnakeMomoTransfer) => {
    const url = '/transfers'
    return GATEWAY_ENDPOINT.post(url, credential)
  },
  $_getTransfer: (id: string) => {
    const url = `/transfers/${id}`
    return GATEWAY_ENDPOINT.get(url)
  },
  $_getAllTransfers: (page = 1) => {
    const url = `/transfers?page=${page}&page_size=${PAGINATION_LIMIT}`
    return GATEWAY_ENDPOINT.get(url)
  },
  $_getCompletedTransaction: (page = 1, credential: any, limit = PAGINATION_LIMIT) => {
    const url = ref('/transactions?')

    addQueryParam(url, 'page', page)
    addQueryParam(url, 'page_size', limit)
    addQueryParam(url, 'entry', credential?.entry?.value)
    addQueryParam(url, 'status', credential?.status?.value)
    addQueryParam(url, 'type', credential?.type?.value)
    addQueryParam(url, 'amount', toMinorUnit(credential?.amount?.value || '0'))
    addQueryParam(url, 'currency', credential?.currency?.value)
    addQueryParam(url, 'start_date', credential?.startDate?.value)
    addQueryParam(url, 'end_date', credential?.endDate?.value)
    addQueryParam(url, 'id', credential?.id?.value)

    url.value = url.value.slice(0, -1)
    return GATEWAY_ENDPOINT.get(url.value)
  },
  $_getTransactionDetails: (id: any): Promise<AxiosResponse<TransactionDetailsResponse>> | 'ERROR' => {
    const url = `/transactions/${id}`
    return GATEWAY_ENDPOINT.get(url, {
      transformResponse: (data: string) => Recase.camelCopy(JSON.parse(data)),
    })
  },
  $_generateReceipt: (credential: Record<string, any>) => {
    const url = '/transactions/receipt'
    return GATEWAY_ENDPOINT.post(url, credential)
  },
}
