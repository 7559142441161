import { mixed, object, string } from 'yup'
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js'
import { BusinessRegisterationType } from '~/src/types/api'

export const businessKycSchema = object({
  typeOfRegistration: mixed<BusinessRegisterationType>()
    .oneOf(Object.values(BusinessRegisterationType))
    .required()
    .label('registration type'),
  registrationNumber: string().required().label('registration number'),
  phoneNumber: string()
    .required()
    .test('is-valid-phone-number', 'Phone number does not match country selected', function (value) {
      try {
        const country = parsePhoneNumber(value).country

        return isValidPhoneNumber(value, country)
      } catch (error) {
        return false
      }
    })
    .label('phone number'),
  taxIdNumber: string().required().label('tax identification'),
  website: string(), // TODO: validate url
  description: string().required().label('business Description'),
  industry: string().required().label('industry'),
  dateOfIncorporation: string().required(),
  name: string().required(),
}).required()

export const businessAddressSchema = object({
  address: object().shape({
    country: string().required(),
    street: string().required(),
    apartment: string(),
    city: string().required(),
    postalCode: string().required(),
    state: string().required(),
  }),
}).required()
