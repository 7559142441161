<template>
  <el-divider v-bind="$attrs">
    <template
      v-for="(_, slot) of $slots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </el-divider>
</template>
<style>
.el-divider {
  --el-border-color: var(--gray300);
  @apply my-4;
}
</style>
