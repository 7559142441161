<template>
  <Modal
    modal="$atts.modal"
    :title="$t('transfer.transferInfo')"
  >
    <div class="flex flex-col w-full h-full justify-between items-center mt-12 text-sm">
      <div class="w-full flex justify-between items-center py-4 px-1 border-b border-gray50">
        <span class="text-secondary">ID</span>
        <span
          class="!text-secondary cursor-pointer truncate bg-greyBg py-2 px-2.5 rounded text-mono text-xs"
          @click="copyData(transferInfo.id, transactionCopied)"
          >{{ transferInfo.id }}</span
        >
      </div>
      <div class="w-full flex justify-between items-center py-4 px-1 border-b border-gray50">
        <span class="text-secondary">{{ $t('amount') }}</span>
        <span class="!text-secondary"> {{ transferInfo.currency }} {{ fromMinorUnit(transferInfo.amount) }}</span>
      </div>
      <div class="w-full flex justify-between items-center py-4 px-1 border-b border-gray50">
        <span class="text-secondary">{{ $t('fee') }}</span>
        <span class="!text-secondary"> {{ transferInfo.currency }} {{ fromMinorUnit(transferInfo.fee) }}</span>
      </div>

      <div class="w-full flex justify-between items-center py-4 px-1 border-b border-gray50">
        <span class="text-secondary">{{ $t('name') }}</span>
        <span class="!text-secondary">{{
          transferInfo.counterparty ? transferInfo.counterparty.account_name : '-'
        }}</span>
      </div>
      <div class="w-full flex justify-between items-center py-4 px-1 border-b border-gray50">
        <span class="text-secondary">{{ $t('bank') }}</span>
        <span class="!text-secondary">{{ transferInfo.counterparty.bank_name }}</span>
      </div>
      <div class="w-full flex justify-between items-center py-4 px-1 border-b border-gray50">
        <span class="text-secondary">{{ $t('transfers.accountNumber') }}</span>
        <span class="!text-secondary">{{ transferInfo.counterparty.account_number }}</span>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import { ShowTransferDetails } from '~/src/composables/apis/transfers/index'
import { fromMinorUnit } from '~/src/helpers/fromMinorUnit'
import { copyToClipboard } from '~/src/composables/utils/useFile/index'

const { transferInfo } = ShowTransferDetails()
const { copyData } = copyToClipboard()
const { t } = useI18n()

const transactionCopied = t('customer.transactionCopied')
</script>

<style scoped></style>
