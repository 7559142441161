import { boolean, number, object } from 'yup'

export const meta = {
  page: number().required(),
  size: number().required(),
  hasNextPage: boolean().required(),
  hasPreviousPage: boolean().required(),
  totalCount: number().required(),
}

export const paginationSchema = object(meta)
