import VueGoogleMaps from '@fawmi/vue-google-maps'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const { $i18n } = useNuxtApp()

  nuxtApp.vueApp.use(VueGoogleMaps, {
    load: {
      key: config.public.GOOGLE_API_KEY,
      language: $i18n.locale.value,
      libraries: 'places',
    },
  })
})
