import { isValidPhoneNumber } from 'libphonenumber-js'
import { mixed, number, object, string } from 'yup'
import { IdentificationType, MAX_PHONE_LENGTH, TransferScheme } from '~/src/types/internals'

export const transferBeneficiarySchema = object({
  amount: number().required().label('Amount'),
  currency: string().required().label('Country'),
  bankCode: string().required().label('Network'),
  accountNumber: string()
    .required()
    .min(MAX_PHONE_LENGTH, 'Complete mobile number')
    .test('is-valid-phone', 'Mobile number is not a valid number', function (value) {
      return isValidPhoneNumber(value, this.parent.currency)
    })
    .label('Mobile Number'),
  reason: string(),
  reference: string(),
  meta: object({
    scheme: mixed<TransferScheme>().oneOf(Object.values(TransferScheme)).required().label('Transfer type'),
    counterparty: object({
      name: string().required().label('Beneficiary name'),
      phoneNumber: string(),
    }),
  }),
})

export const senderSchema = object({
  firstName: string().required().label('First name'),
  lastName: string().required().label('Last name'),
  phoneNumber: string()
    .required()
    .test('is-valid-phone', 'Phone number is not a valid number', function (value) {
      return isValidPhoneNumber(value, this.parent.country)
    })
    .label('Phone number'),
  email: string().email().required().label('Email'),
  country: string().required().label('Country'),
  address: string().required().label('Address'),
  state: string().required().label('State'),
  city: string().required().label('City'),
  dob: string().required().label('Date of birth'),
  postalCode: string().required().label('Postal code'),
})

export const senderIdentificationSchema = object({
  nationality: string().required().label('Nationality'),
  type: mixed<IdentificationType>().oneOf(Object.values(IdentificationType)).required().label('Identification type'),
  number: string().required().label('Identification number'),
  issuedAt: string().required().label('Country issued'),
  issuedDate: string().required().label('Date issued'),
  expirationDate: string().required().label('Expiration date'),
})
