<template>
  <form
    class="w-full overflow-y-auto"
    @submit.prevent="step++"
  >
    <div class="flex flex-col gap-6">
      <div class="field">
        <label
          for="bvn"
          class="label"
        >
          {{ $t('enter') }} NIN
        </label>
        <input
          id="bvn"
          v-model="credential.identity.number.value"
          type="number"
          name="bvn"
          class="input"
          required
        />
      </div>
      <div class="field">
        <label
          for="bvn"
          class="label"
        >
          {{ $t('wallets.selectCountry') }}
        </label>
        <SelectMenu
          id="country"
          v-model="credential.identity.country.value"
          :value="credential.identity.country.value"
          class="mt-2"
          :required="true"
          :options="countries"
          variant=""
        />
      </div>
    </div>
    <div class="flex justify-between w-full">
      <button
        class="btn bg-primary text-white self-end px-7 min-w-[140px] mt-5 text-sm !py-3"
        type="button"
        @click="step--"
      >
        {{ $t('back') }}
      </button>
      <button class="btn bg-primary text-white self-end px-7 min-w-[140px] mt-5 text-sm !py-3">{{ $t('next') }}</button>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { createCustomer } from '~/src/composables/apis/customer'
import countries from '~/src/constants/countries'

const { credential, step } = createCustomer()
</script>

<style scoped></style>
