<script lang="ts" setup>
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/yup'
import { fundCardSchema } from '~/src/services/requests/card/schema'
import { useCard } from '~/src/composables/apis/card/index'
import { useCardsModal } from '~/src/composables/core/modals'
import { CURRENCY_CODE } from '~/src/constants/index'

const { cardDetails, fundCard, funding } = useCard()

const elPlusConfig = (state: any) => ({
  props: {
    validateEvent: false,
    error: state.errors[0],
    required: state.required,
  },
})

const { defineField, handleSubmit } = useForm({
  validationSchema: toTypedSchema(fundCardSchema),
  initialValues: {
    id: cardDetails.value.id,
  },
})

const [amount, amountProps] = defineField('amount', elPlusConfig)

const submit = handleSubmit((values) => {
  fundCard(values, {
    onFinally: () => useCardsModal().closeCreditCard(),
  })
})
</script>
<template>
  <Modal
    modal="$atts.modal"
    :title="$t('cards.fundCard')"
  >
    <form
      class="w-full"
      @submit.prevent="submit"
    >
      <CurrencyInput
        id="fund-card"
        v-model="amount"
        v-model:selected="cardDetails.currency"
        :select-props="{ disabled: true }"
        :label="`${$t('amount')} ${$t('optional')}`"
        placeholder="0.00"
        :options="CURRENCY_CODE"
      />
      <Typography
        variant="xs"
        color="text-red"
        class="mt-1"
      >
        {{ amountProps.error }}
      </Typography>

      <Button
        class="w-full mt-4"
        size="large"
        :disabled="funding"
        :loading="funding"
      >
        {{ $t('fund') }}
      </Button>
    </form>
  </Modal>
</template>
