<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/yup'
import { useForm } from 'vee-validate'
import { useFuse } from '@vueuse/integrations/useFuse'
import allCountries from '~/src/constants/countries'
import { toBase64 } from '~/src/composables/utils/useFile/index'
import { useKycModal } from '~/src/composables/core/modals'
import { useKYC } from '~/src/composables/apis/kyc/useKyc'
import { shareholderSchema } from '~/src/services/requests/kyc/schema'
import { Director, Shareholder } from '~/src/services/requests/kyc/types'

const { state, loading, addShareholder, editShareholder } = useKYC()
const schema = toTypedSchema(shareholderSchema)

const { defineField, handleSubmit, setFieldValue, setValues, resetForm } = useForm({
  validationSchema: schema,
  initialValues: state.currentShareholder,
})

const elPlusConfig = (state: any) => ({
  props: {
    validateEvent: false,
    error: state.errors[0],
    required: state.required,
  },
})
const directors = toRef(state.directors)
const [firstName, firstNameProps] = defineField('firstName', elPlusConfig)
const [lastName, lastNameProps] = defineField('lastName', elPlusConfig)
const [sharePercentage, sharePercentageProps] = defineField('sharePercentage', elPlusConfig)
const [nationality, nationalityProps] = defineField('nationality', elPlusConfig)
const [address, addressProps] = defineField('address', elPlusConfig)
const [proofOfAddress, proofOfAddressProps] = defineField('proofOfAddress', elPlusConfig)
const [idCard, idCardProps] = defineField('idCard', elPlusConfig)

const countries = allCountries.map(({ id, text }) => ({ value: id, label: text }))
const search = ref('')
const getSingleFile = (event: Event) => {
  const [file] = (event.target as HTMLInputElement).files || []

  return file
}
const handleChange = async (field: keyof Shareholder, event: Event) => {
  const value = (await toBase64(getSingleFile(event))) as string

  setFieldValue(field, value)
}

const selectDirector = (director: Director) => {
  setValues({
    firstName: director.firstName,
    lastName: director.lastName,
    nationality: director.nationality,
    idCard: director.idCard,
    proofOfAddress: director.proofOfAddress,
    address: `${director.address}, ${director.city}, ${director.state}`,
  })
}

const submit = handleSubmit((values) => {
  const func = values.id ? editShareholder : addShareholder
  func(values, {
    onSuccess: () => {
      resetForm()
      useKycModal().closeAddShareholder()
    },
  })
})

const { results } = useFuse(search, directors, {
  fuseOptions: {
    keys: ['fullName'],
  },
  matchAllWhenSearchEmpty: true,
})
</script>
<template>
  <Modal
    modal="$atts.modal"
    :title="$t('kyc.addShareholder')"
    @close="resetForm"
  >
    <form
      class="w-full"
      @submit.prevent="submit"
    >
      <div class="flex flex-col gap-6 mt-5">
        <template v-if="results.length">
          <Search
            v-model="search"
            size="large"
            :placeholder="$t('settings.searchRole')"
          />

          <div>
            <Typography
              as="span"
              color="text-secondary"
              class="mb-2"
              variant="smBold"
            >
              {{ $t('kyc.directors') }}
            </Typography>
            <ul class="flex gap-2">
              <li
                v-for="{ item } in results"
                :key="item.email"
              >
                <TagUser
                  :name="`${item.firstName} ${item.lastName}`"
                  @click="() => selectDirector(item)"
                />
              </li>
            </ul>
          </div>
        </template>

        <div class="flex gap-4">
          <FormControl
            v-bind="firstNameProps"
            class="flex-1"
          >
            <template #label>
              <Typography
                as="span"
                color="text-secondary"
                class="mb-2"
                variant="smBold"
              >
                {{ $t('firstName') }}
              </Typography>
            </template>
            <InputField
              v-model="firstName"
              placeholder="eg. John"
            />
            <template #error>
              <Typography
                variant="xs"
                color="text-red"
                class="mt-1"
              >
                {{ firstNameProps.error }}
              </Typography>
            </template>
          </FormControl>

          <FormControl
            v-bind="lastNameProps"
            class="flex-1"
          >
            <template #label>
              <Typography
                as="span"
                color="text-secondary"
                class="mb-2"
                variant="smBold"
              >
                {{ $t('lastName') }}
              </Typography>
            </template>
            <InputField
              v-model="lastName"
              placeholder="eg. Doe"
            />
            <template #error>
              <Typography
                variant="xs"
                color="text-red"
                class="mt-1"
              >
                {{ lastNameProps.error }}
              </Typography>
            </template>
          </FormControl>
        </div>

        <FormControl v-bind="nationalityProps">
          <template #label>
            <Typography
              as="span"
              color="text-secondary"
              class="mb-2"
              variant="smBold"
            >
              {{ $t('nationality') }}
            </Typography>
          </template>
          <Select
            v-model="nationality"
            filterable
            class="w-full"
            size="large"
            :placeholder="`--${$t('choose')}--`"
          >
            <SelectOption
              v-for="item in countries"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
              <span class="inline-flex justify-between items-center w-full">
                {{ item.label }}

                <IconsCheckOutlined
                  v-if="nationality === item.value"
                  class="text-success900 h-4 w-4"
                />
              </span>
            </SelectOption>
          </Select>
          <template #error>
            <Typography
              variant="xs"
              color="text-red"
              class="mt-1"
            >
              {{ nationalityProps.error }}
            </Typography>
          </template>
        </FormControl>

        <FormControl v-bind="addressProps">
          <template #label>
            <Typography
              as="span"
              color="text-secondary"
              class="mb-2"
              variant="smBold"
            >
              {{ $t('customer.address') }}
            </Typography>
          </template>
          <InputField
            v-model="address"
            type="textarea"
            :rows="3"
            :placeholder="$t('typeSomething')"
          />
          <template #error>
            <Typography
              variant="xs"
              color="text-red"
              class="mt-1"
            >
              {{ addressProps.error }}
            </Typography>
          </template>
        </FormControl>

        <FormControl v-bind="sharePercentageProps">
          <template #label>
            <Typography
              as="span"
              color="text-secondary"
              class="mb-2"
              variant="smBold"
            >
              {{ $t('shares') }}
            </Typography>
          </template>
          <InputField
            v-model="sharePercentage"
            type="number"
            :placeholder="$t('enterSharePercentage')"
          />
          <template #error>
            <Typography
              variant="xs"
              color="text-red"
              class="mt-1"
            >
              {{ sharePercentageProps.error }}
            </Typography>
          </template>
        </FormControl>

        <EntityDetails
          icon="document"
          :title="$t('kyc.proofOfIdentification')"
          :error="idCardProps.error"
          @remove="() => setFieldValue('idCard', undefined)"
        >
          <Tag
            v-if="idCard"
            closable
            size="large"
            type="info"
            @close="() => setFieldValue('idCard', undefined)"
          >
            <Typography>{{ $t('kyc.proofOfIdentification') }}</Typography>
          </Tag>
          <Upload
            v-else
            id="idCard"
            :label="$t('kyc.upload')"
            v-bind="idCardProps"
            @change="handleChange('idCard', $event)"
          />
        </EntityDetails>

        <EntityDetails
          icon="document"
          :title="$t('kyc.proofOfAddress')"
          :error="proofOfAddressProps.error"
          @remove="() => setFieldValue('proofOfAddress', undefined)"
        >
          <Tag
            v-if="proofOfAddress"
            closable
            size="large"
            type="info"
            @close="() => setFieldValue('proofOfAddress', undefined)"
          >
            <Typography>{{ $t('kyc.proofOfAddress') }}</Typography>
          </Tag>
          <Upload
            v-else
            id="proofOfAddress"
            :label="$t('kyc.upload')"
            v-bind="proofOfAddressProps"
            @change="handleChange('proofOfAddress', $event)"
          />
        </EntityDetails>

        <Button
          size="large"
          :loading="loading"
        >
          {{ $t('save') }}
        </Button>
      </div>
    </form>
  </Modal>
</template>
