import { boolean, mixed, number, object, string } from 'yup'
import { CardBrand, CardStatus, CardType, CurrencyCode } from '~/src/types/internals'

export const fundCardSchema = object({
  id: string().required(),
  amount: number()
    .required()
    .min(100, 'amount must be greater than or equal to 1')
    .max(500000, 'amount must be less than or equal to 5,000'),
})

export const businessCardSchema = object({
  name: string().required().label('Full name'),
  brand: string().required().label('Card provider'),
  currency: mixed<CurrencyCode>().oneOf(Object.values(CurrencyCode)).required(),
  amount: number(),
  autoApprove: boolean().required(),
  type: mixed<CardType>().oneOf(Object.values(CardType)).required().label('card type'),
})

export const customerCardSchema = object({
  customerId: string().required(),
  brand: string().required().label('Card provider'),
  currency: mixed<CurrencyCode>().oneOf(Object.values(CurrencyCode)).required(),
  amount: number(),
  autoApprove: boolean().required(),
  type: mixed<CardType>().oneOf(Object.values(CardType)).required().label('card type'),
})

export const cardSchema = object({
  id: string().required(),
  name: string().required(),
  cardNumber: string().required(),
  maskedPan: string().required(),
  expiry: string().required(),
  cvv: string().required(),
  balance: number().required(),
  balanceUpdatedAt: string().required(),
  createdAt: string().required(),
  updatedAt: string().required(),
  autoApprove: boolean().required(),
  issuer: mixed<CardBrand>().oneOf(Object.values(CardBrand)).required().label('card type'),
  currency: mixed<CurrencyCode>().oneOf(Object.values(CurrencyCode)).required(),
  type: mixed<CardType>().oneOf(Object.values(CardType)).required().label('card type'),
  status: mixed<CardStatus>().oneOf(Object.values(CardStatus)).required(),
  amount: number().required(),
  address: object({
    street: string(),
    city: string(),
    state: string(),
    postalCode: string(),
    country: string(),
  }),
})
