import * as yup from 'yup'
import { toTypedSchema } from '@vee-validate/yup'

export const schema = toTypedSchema(
  yup.object({
    name: yup.string().required().label('Role name'),
    description: yup.string().required().label('Description'),
    permissions: yup.array().of(yup.string().required()).min(1).required(),
  }),
)
