import { useSettingsModal } from '../core/modals'
import { ipAddressApi } from '~/src/services/ipAddresses'
import { IpAddress } from '~/src/types'

const state = reactive<{ address: IpAddress; mode: null | 'edit' | 'add'; addresses: IpAddress[] }>({
  address: {
    id: '',
    ip_address: '',
    name: '',
    created_at: undefined,
  },
  mode: null,
  addresses: [],
})

export const useWhitelist = () => {
  const loading = ref(false)
  const creating = ref(false)
  const deleting = ref(false)
  const editing = ref(false)

  const getAddresses = async () => {
    loading.value = true
    const res = await ipAddressApi.$_getAddresses()

    if (res.data) state.addresses = [...res.data]

    loading.value = false
  }

  const resetState = () => {
    state.mode = null
    state.address.id = ''
    state.address.name = ''
    state.address.ip_address = ''
    state.address.created_at = undefined
  }

  const addAddress = async ({ ipAddress, name }: { ipAddress: string; name: string }) => {
    creating.value = true

    const res = await ipAddressApi.$_addAdress({ name, ip_address: ipAddress })

    if (res?.status) {
      await getAddresses()
    }

    resetState()
    creating.value = false
    useSettingsModal().closeIpWhitelist()
  }

  const editAddress = async ({ ipAddress, name }: { ipAddress: string; name: string }) => {
    editing.value = true

    const res = await ipAddressApi.$_editAdress({ id: state.address.id, name, ip_address: ipAddress })

    if (res?.status) {
      const index = state.addresses.findIndex((address) => {
        return address.id === state.address.id
      })

      state.addresses[index].ip_address = ipAddress
      state.addresses[index].name = name
    }

    resetState()
    editing.value = false
    useSettingsModal().closeIpWhitelist()
  }

  const deleteAddress = async () => {
    deleting.value = true

    const res = await ipAddressApi.$_deleteAdress(state.address.id)

    if (res?.status) {
      state.addresses = state.addresses.filter((item) => item.id !== state.address.id)
    }

    resetState()
    deleting.value = false
    useSettingsModal().closeDeleteIpAddress()
  }

  return {
    getAddresses,
    addAddress,
    deleteAddress,
    editAddress,
    resetState,
    loading,
    creating,
    deleting,
    editing,
    state,
  }
}
