import { CountryIso, CurrencyCode } from '~/src/types/internals'

export const countriesCode = [
  { id: CurrencyCode.NGN, text: 'Nigeria', iso: CountryIso.NGN },
  { id: CurrencyCode.KES, text: 'Kenya', iso: CountryIso.KES },
  { id: CurrencyCode.GHS, text: 'Ghana', iso: CountryIso.GHS },
  // { id: CurrencyCode.USD, text: 'United States', iso: CountryIso.USD }, // TODO: Deprecating this option because it is invalid and causes errors as mentioned here https://wirepay.slack.com/archives/C03BAEHGG1E/p1725986716828449
]

export const currencies = [CurrencyCode.NGN, CurrencyCode.KES, CurrencyCode.GHS, CurrencyCode.USD, CurrencyCode.XAF]
