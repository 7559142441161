<template>
  <Modal
    modal="$atts.modal"
    :title="$t('transfer.confirmTransfer')"
  >
    <!-- TODO reduce copling in this component -->
    <div class="flex flex-col items-center justify-between w-full h-full mt-12 text-sm">
      <div class="flex flex-col items-start w-full">
        <h1 class="text-sm text-greyDark">{{ $t('amount') }}</h1>
        <p class="text-xl font-semibold">
          {{
            maCurrencyFormatter(nairaCredentials.credential.country || credentials?.currency?.value).format(
              (nairaCredentials?.credential.amount && toUnit(nairaCredentials?.credential.amount)) ||
                (credentials?.amount?.value && toUnit(credentials?.amount?.value)),
            )
          }}
        </p>

        <h1 class="mt-3 text-sm text-greyDark">{{ $t('to') }}</h1>
        <p class="text-xl font-semibold">
          {{ credentials.to.value || nairaCredentials.credential.to }}
        </p>
      </div>

      <div class="flex items-center justify-between w-full py-4 mt-5 border-b border-gray50">
        <span class="font-semibold text-secondary">{{ $t('transactions.accountNumber') }}</span>
        <span class="!text-secondary">
          {{ nairaCredentials.credential.account_number || credentials.account_number?.value }}
        </span>
      </div>
      <div class="flex items-center justify-between w-full py-4 border-b border-gray50">
        <span class="font-semibold text-secondary">{{ $t('bank') }}</span>
        <span class="!text-secondary">
          {{ bank?.name || credentials.bank?.value?.name }}
        </span>
      </div>
      <div class="flex items-center justify-between w-full py-4 border-b border-gray50">
        <span class="font-semibold text-secondary">{{ $t('transfer.payingFrom') }}</span>
        <span class="!text-secondary">
          {{
            $t('wallets.currencyWallet', {
              currency: nairaCredentials.credential.country || credentials.currency.value,
            })
          }}
        </span>
      </div>

      <div
        v-if="nairaCredentials.credential.reason || credentials.reason.value"
        class="flex items-center justify-between w-full py-4 border-b border-gray50"
      >
        <span class="font-semibold text-secondary">{{ $t('reason') }} </span>
        <span class="!text-secondary">
          {{ nairaCredentials.credential.reason || credentials.reason.value || 'No reason' }}
        </span>
      </div>

      <div class="flex justify-between w-full gap-4 mt-14">
        <button
          class="w-full btn-secondary-md"
          type="button"
          @click="cancel"
        >
          {{ $t('cancel') }}
        </button>
        <button
          class="w-full btn-primary-md"
          :disabled="NairaTransferLoading || mapleradTransferLoading || USDTransferloading"
          @click="createFunc()"
        >
          <span v-if="!NairaTransferLoading || !mapleradTransferLoading || !USDTransferloading">
            {{ $t('confirm') }}</span
          >
          <Spinner v-else />
        </button>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import { maCurrencyFormatter } from '~/src/composables/utils/useCurrency'
import { useTransfersModal } from '~/src/composables/core/modals'
import { confirmTransfer } from '~/src/composables/apis/transfers/index'
import { createBankTransfer } from '~/src/composables/apis/transfers/bankTransfer'
import { mapleradTransferLoading } from '~/src/composables/apis/transfers/mapleradTransfer'
import { USDTransferloading } from '~/src/composables/apis/transfers/usdTransfer'
import { getBanks } from '~/src/composables/apis/institution'
import { toUnit } from '~/src/helpers/fromMinorUnit'

const { banks } = getBanks()

const { state: nairaCredentials, NairaTransferLoading, step } = createBankTransfer()
const bank = computed(() => banks.value.find((bank: any) => bank.id === nairaCredentials.credential.bank_code))
const { createFunc, credentials } = confirmTransfer()

const cancel = () => {
  useTransfersModal().closeAll()
  step.value = 1
}
</script>

<style scoped></style>
