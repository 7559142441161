<script setup lang="ts">
import { Colors } from '~/src/types/styles'

const props = withDefaults(defineProps<{ color?: Colors }>(), {
  color: 'green400',
})
const color = computed(() => `var(--${props.color})`)
</script>
<template>
  <el-switch
    class="switch"
    v-bind="$attrs"
  >
    <template
      v-for="(_, slot) of $slots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </el-switch>
</template>
<style>
.el-switch__core {
  min-width: 24px;
  --el-switch-on-color: v-bind(color);
}
.switch.el-switch--large .el-switch__core {
  min-width: 42px;
}
</style>
