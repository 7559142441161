<template>
  <Modal
    :title="$t('settings.deleteMember')"
    @close="useSettingsModal().closeDeleteTeam()"
  >
    <p class="text-secondary py-6 text-center">
      {{ $t('settings.deleteMemberText', { user: state.currentUser?.email }) }}
    </p>
    <Button
      class="w-full"
      variant="error"
      :loading="removingUser"
      @click="handleSubmit"
    >
      {{ $t('delete') }}
    </Button>
  </Modal>
</template>

<script lang="ts" setup>
import { useRoles } from '~/src/composables/apis/settings/roles'
import { useSettingsModal } from '~/src/composables/core/modals'
const { state, removeUser, removingUser } = useRoles()

const handleSubmit = () => state.currentUser && removeUser(state.currentUser.id)
</script>

<style scoped></style>
