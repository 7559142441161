import { array, boolean, date, number, object, string } from 'yup'
import { paginationSchema } from '../Pagination/schema'
import {
  CurrencyCode,
  InvestmentStatus,
  TransactionType,
  TransactionEntryType,
  TransactionStatus,
} from '~/src/types/internals'

const stashSchemaStepOne = {
  name: string().required(),
}

const stashSchemaStepTwo = {
  duration: number().required(),
  portfolioId: string().required(),
  amount: number().required().min(1000, 'amount must be greater than or equal to 10'),
}

export const stashSchemaStepOneSchema = object().shape(stashSchemaStepOne)
export const stashSchemaStepTwoSchema = object().shape(stashSchemaStepTwo)

export const stashSchema = object().shape(stashSchemaStepOne).concat(object(stashSchemaStepTwo))

export const createStashSchemaResponse = object({
  status: boolean().required(),
  message: string().required(),
  data: object({
    id: string().required(),
    name: string().required(),
    portfolioId: string().required(),
    status: string().required(),
    customerId: string().required(),
    amount: number().required(),
    balance: number().required(),
    currency: string<CurrencyCode>().oneOf(Object.values(CurrencyCode)).required(),
    duration: number().required(),
    maturityDate: string().required(),
  }).required(),
})

export const portfoliosResponseSchema = object({
  status: boolean().required(),
  message: string().required(),
  data: array(
    object({
      id: string().required(),
      name: string().required(),
      return: array(
        object({
          duration: number().required(),
          percentageReturn: number().required(),
        }),
      ).required(),
      minimum: number().nullable(),
      maximum: number().nullable(),
      breakageFee: number().required(),
      currency: string<CurrencyCode>().oneOf(Object.values(CurrencyCode)).required(),
    }),
  ).required(),
})

const yieldObject = {
  balance: number().required(),
  currency: string().required(),
  totalInvestment: number().required(),
  totalReturn: number().required(),
  returnPercentage: number().required(),
  yields: array().of(
    object().shape({
      date: date().required(),
      balance: number().required(),
      yield: number().required(),
    }),
  ),
}
export const fundStash = object({
  investmentId: string().required(),
  amount: number().min(100, 'amount must be greater than or equal to 1').required(),
})

export const fundStashResponse = object({
  status: boolean().required(),
  message: string().required(),
  data: object({
    transactionId: string().required(),
    amount: number().positive().integer().required(),
    status: string<InvestmentStatus>().oneOf(Object.values(InvestmentStatus)).required(),
  }),
})

const investment = {
  name: string().required(),
  id: string().required(),
  portfolioId: string().required(),
  status: string<InvestmentStatus>().oneOf(Object.values(InvestmentStatus)).required(),
  customerId: string().required(),
  duration: number().positive().integer().required(),
  interestRate: number().required(),
  startDate: date().required(),
  maturityDate: date().required(),
  totalInvestment: number().required(),
  projectedReturn: number().required(),
  balance: number().required(),
  currency: string().oneOf(Object.values(CurrencyCode)).required(),
}

const businessInvestment = {
  id: string().required(),
  name: string().nullable(),
  portfolioId: string().required(),
  status: string<InvestmentStatus>().oneOf(Object.values(InvestmentStatus)).required(),
  customerId: string().nullable(),
  amount: number().required(),
  balance: number().required(),
  currency: string().required(),
  duration: number().integer().required(),
  maturityDate: date().required(),
  startDate: date().required(),
}

export const investmentTransactionSchema = object({
  id: string().required(),
  currency: string<CurrencyCode>().oneOf(Object.values(CurrencyCode)).required(),
  status: string<TransactionStatus>().oneOf(Object.values(TransactionStatus)).required(),
  entry: string<TransactionEntryType>().oneOf(Object.values(TransactionEntryType)).required(),
  type: string<TransactionType>().oneOf(Object.values(TransactionType)).required(),
  amount: number().required(),
  fee: number().required(),
  channel: string().required(),
  summary: string(),
  reason: string().nullable(),
  reference: string().nullable(),
  accountId: string().nullable(),
  createdAt: date().required(),
  updatedAt: date().required(),
  source: string().nullable(),
  counterParty: string().nullable(),
})

export const investmentTransactionResponseSchema = object({
  status: boolean().required(),
  message: string().required(),
  data: array().of(investmentTransactionSchema),
  meta: paginationSchema.required(),
})

export const investmentResponseSchema = object({
  status: boolean().required(),
  message: string().required(),
  data: object().shape(investment),
})

export const investmentYieldResponseSchema = object({
  status: boolean().required(),
  message: string().required(),
  data: object().shape(yieldObject),
})

export const businessInvestmentSchema = object().shape(businessInvestment)

export const businessInvestmentResponseSchema = object().shape({
  status: boolean().required(),
  message: string().required(),
  data: object().shape({
    totalInvestment: number().required(),
    investments: array().of(businessInvestmentSchema),
  }),
  meta: paginationSchema.required(),
})
