import { IpAddress } from '../types'
import { API_ROUTES } from '../routes'
import { GATEWAY_ENDPOINT } from './SetupBase'

export const ipAddressApi = {
  $_getAddresses: async () => {
    const url = API_ROUTES.ipWhitelist
    const res = await GATEWAY_ENDPOINT.get(url)

    return res.data
  },
  $_addAdress: async (payload: Omit<IpAddress, 'id' | 'created_at'>) => {
    const url = API_ROUTES.ipWhitelist
    const res = await GATEWAY_ENDPOINT.post(url, payload)

    return res.data
  },
  $_editAdress: async (payload: Omit<IpAddress, 'created_at'>) => {
    const url = API_ROUTES.ipWhitelist
    const res = await GATEWAY_ENDPOINT.put(url, payload)

    return res.data
  },
  $_deleteAdress: async (id: string) => {
    const url = API_ROUTES.ipWhitelist + `/${id}`
    const res = await GATEWAY_ENDPOINT.delete(url)

    return res.data
  },
}
