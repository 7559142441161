import { AxiosResponse } from 'axios'
import Recase from 'better-recase'
import { CollectionOtp, CollectionRecipientResponse, CollectionResponse, collectionTransactionResponse } from './type'
import { GATEWAY_ENDPOINT } from '~/src/services/SetupBase'
import { CurrencyCode } from '~/src/types/internals'

export const collectionApi = {
  $_directDebit: (credential: any) => {
    const url = '/collections/direct-debit'
    return GATEWAY_ENDPOINT.post(url, credential)
  },
  $_getMOMORecipients: (currency?: CurrencyCode): Promise<AxiosResponse<CollectionRecipientResponse>> | 'ERROR' => {
    const url = `counterparties`

    return GATEWAY_ENDPOINT.get(url, {
      params: {
        currency,
      },
      transformResponse: (data: string) => Recase.camelCopy(JSON.parse(data)),
    })
  },
  $_getCollectionTransaction: (id: string): Promise<AxiosResponse<collectionTransactionResponse>> | 'ERROR' => {
    const url = `transactions/pending-otp/${id}`

    return GATEWAY_ENDPOINT.get(url, {
      transformResponse: (data: string) => Recase.camelCopy(JSON.parse(data)),
    })
  },
  $_verifyOtp: (payload: CollectionOtp): Promise<AxiosResponse> | 'ERROR' => {
    const url = 'collections/momo/verify-otp'

    return GATEWAY_ENDPOINT.post(url, Recase.snakeCopy(payload), {
      transformResponse: (data: string) => Recase.camelCopy(JSON.parse(data)),
    })
  },
  $_requestCollection: <T>(payload: T): Promise<AxiosResponse<CollectionResponse>> | 'ERROR' => {
    const url = 'collections/momo'

    return GATEWAY_ENDPOINT.post(url, Recase.snakeCopy(payload), {
      transformResponse: (data: string) => Recase.camelCopy(JSON.parse(data)),
    })
  },
}
