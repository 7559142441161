import { v4 as uuidv4 } from 'uuid'
import { getBanks } from '../../institution'
import {
  CollectionResponse,
  CollectionStepThree,
  CollectionStepTwo,
  CollectionRecipient,
  CollectionWithRecipient,
  CollectionWithNewRecipient,
  CreateRecipient,
  CollectionTransaction,
  CollectionOtp,
} from '~/src/services/requests/collection/type'
import { collectionApi } from '~/src/services/requests/collection'
import { useAlert } from '~/src/composables/core/useNotification'
import { CountryIso, CurrencyCode, Institution, MOBILE_MONEY_SUPPORTED_COUNTRIES } from '~/src/types/internals'

const globalState = reactive<{
  recipients: CollectionRecipient[]
  recipient: Partial<CollectionRecipient> | null
  loading: Record<string, boolean>
  collection: Partial<CollectionWithNewRecipient | CollectionWithRecipient> | null
  transaction: null | CollectionTransaction
  isNewRecipient: boolean
  collectionRequest: CollectionResponse['data'] | null
  country: CountryIso | ''
}>({
  country: '',
  isNewRecipient: false,
  recipients: [],
  recipient: null,
  collection: null,
  transaction: null,
  loading: {
    allRecipients: false,
    transfer: false,
    verify: false,
    collectionsRequest: false,
  },
  collectionRequest: null,
})

const { fetch, banks, loading: bankLoading } = getBanks()

export const momoExcludingGhs = MOBILE_MONEY_SUPPORTED_COUNTRIES.filter((item) => item.currency !== CurrencyCode.GHS)

const countryDetails = computed(() => {
  return momoExcludingGhs.find((curr) => curr.currency === globalState.collection?.currency)
})

const existingRecipient = computed(() =>
  globalState.recipients.find(
    (user) => user.id === (globalState.collection as CollectionWithRecipient)?.counterpartyId,
  ),
)

export const useCollection = () => {
  const reset = () => {
    globalState.country = ''
    globalState.isNewRecipient = false
    globalState.recipient = null
    globalState.collectionRequest = null
    globalState.collection = null
    globalState.isNewRecipient = false
  }

  const getOperators = (currency: CurrencyCode) => {
    const country = momoExcludingGhs.find((curr) => curr.currency === currency)
    if (!country) {
      return useAlert().openAlert({
        type: 'ERROR',
        msg: 'This feature is not available for this currency',
      })
    }

    globalState.collection = {
      currency,
    }

    country.country && fetch(Institution.MOMOCOLLECTION, country.country)
  }

  const createCollectionStepOne = (values: CreateRecipient | CollectionWithRecipient) => {
    globalState.collection = {
      ...globalState.collection,
      ...values,
    }
  }

  const createCollectionStepTwo = (values: CollectionStepTwo) => {
    globalState.collection = {
      ...globalState.collection,
      ...values,
    }
  }

  const createCollectionStepThree = (values: CollectionStepThree) => {
    globalState.collection = {
      ...globalState.collection,
      ...values,
      reference: uuidv4(),
    }
  }

  const requestCollection = async <T extends CollectionWithNewRecipient | CollectionWithRecipient>(
    data: T,
    options?: { onSuccess?: () => void },
  ) => {
    globalState.loading.collectionRequest = true

    const res = await collectionApi.$_requestCollection<T>(data)

    if (res !== 'ERROR') {
      globalState.collectionRequest = res.data.data
      options?.onSuccess?.()
    }

    globalState.loading.collectionRequest = false
  }

  const getTransactionDetails = async (id: string) => {
    globalState.loading.transfer = true

    const res = await collectionApi.$_getCollectionTransaction(id)

    if (res !== 'ERROR') {
      globalState.transaction = res.data.data
    }

    globalState.loading.transfer = false
  }

  const verifyOtp = async (payload: CollectionOtp, options?: { onSuccess?: () => void }) => {
    globalState.loading.verify = true

    if (!globalState.transaction?.id) {
      useAlert().openAlert({
        type: 'ERROR',
        msg: 'Something went wrong',
      })
      return
    }

    const res = await collectionApi.$_verifyOtp(payload)

    if (res !== 'ERROR') {
      globalState.transaction = res.data.data
      useAlert().openAlert({
        type: 'SUCCESS',
        msg: 'OTP Verification successful',
      })
      options?.onSuccess?.()
    }

    globalState.loading.verify = false
  }

  const getMOMORecipients = async (currency: CurrencyCode) => {
    globalState.loading.allRecipients = true
    const res = await collectionApi.$_getMOMORecipients(currency)

    if (res !== 'ERROR') {
      globalState.recipients = res.data.data || []
    }

    globalState.loading.allRecipients = false
  }

  return {
    reset,
    getTransactionDetails,
    createCollectionStepOne,
    createCollectionStepTwo,
    getMOMORecipients,
    requestCollection,
    createCollectionStepThree,
    verifyOtp,
    existingRecipient,
    globalState,
    bankLoading,
    countryDetails,
    banks,
    getOperators,
  }
}
