<template>
  <form
    class="w-full overflow-y-auto h-[600px]"
    @submit.prevent="create"
  >
    <div class="flex flex-col gap-6">
      <div class="field">
        <label
          for="bvn"
          class="label"
        >
          {{ $t('street') }}
        </label>
        <input
          id="street"
          v-model="credential.address.street.value"
          type="text"
          name="street"
          class="input"
          required
        />
      </div>
      <div class="field">
        <label
          for="bvn"
          class="label"
        >
          {{ $t('street') }} 2
        </label>
        <input
          id="street2"
          v-model="credential.address.street2.value"
          type="text"
          name="street2"
          class="input"
        />
      </div>
      <div class="field">
        <label
          for="bvn"
          class="label"
        >
          {{ $t('city') }}
        </label>
        <input
          id="street2"
          v-model="credential.address.city.value"
          type="text"
          name="city"
          class="input"
        />
      </div>
      <div class="field">
        <label
          for="bvn"
          class="label"
        >
          {{ $t('state') }}
        </label>
        <input
          id="street2"
          v-model="credential.address.state.value"
          type="text"
          name="state"
          class="input"
        />
      </div>

      <div class="field">
        <label
          for="bvn"
          class="label"
        >
          {{ $t('country') }}
        </label>
        <SelectMenu
          id="country"
          v-model="credential.address.country.value"
          class="mt-2"
          :required="true"
          :options="countries"
          variant=""
          :value="credential.address.country.value"
        />
      </div>
      <div class="field">
        <label
          for="bvn"
          class="label"
        >
          {{ $t('customer.postalCode') }}
        </label>
        <input
          id="postal_code"
          v-model="credential.address.postal_code.value"
          type="text"
          name="postal_code"
          class="input"
        />
      </div>
    </div>

    <div class="flex justify-between w-full mt-5">
      <button
        class="btn bg-primary text-white self-end px-7 min-w-[140px] mt-5 text-sm !py-3"
        type="button"
        @click="step--"
      >
        {{ $t('back') }}
      </button>
      <button
        class="ModalBtn"
        :disabled="loading"
      >
        <span v-if="!loading"> {{ $t('submit') }}</span> <Spinner v-else />
      </button>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { createCustomer } from '~/src/composables/apis/customer'
import countries from '~/src/constants/countries'
import Spinner from '~/src/components/core/Spinner.vue'

const { credential, create, loading, step } = createCustomer()
</script>

<style scoped></style>
