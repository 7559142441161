<template>
  <el-radio-group v-bind="$attrs">
    <template
      v-for="(_, slot) of $slots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </el-radio-group>
</template>
