<script lang="ts" setup>
import { MaIcon } from '@wirepay/maplerad-component-lib'

import type { UploadInstance, UploadProps } from 'element-plus'
import { useAlert } from '~/src/composables/core/useNotification'

const props = withDefaults(defineProps<{ size?: number; subtitle?: string }>(), {
  size: 5,
  subtitle: '',
})
defineEmits(['update:fileList'])
defineOptions({
  inheritAttrs: false,
})

const beforeUpload: UploadProps['beforeUpload'] = (rawFile) => {
  if (typeof props.size === 'number' && rawFile.size / 1024 / 1024 > props.size) {
    useAlert().openAlert({ type: 'ERROR', msg: `File size can not exceed ${props.size}MB!` })
    return false
  }
  return true
}

const uploadRef = ref<UploadInstance>()

defineExpose({
  uploadRef,
  beforeUpload,
})
</script>
<template>
  <el-upload
    ref="uploadRef"
    action="#"
    :auto-upload="false"
    drag
    :show-file-list="false"
    :before-upload="beforeUpload"
    v-bind="$attrs"
  >
    <slot>
      <div class="flex gap-3 items-center justify-center">
        <MaIcon
          name="file-upload"
          variant="base"
          class="h-6"
        />
        <div>
          <Typography
            variant="base"
            color="text-gray900"
          >
            {{ $t('dnd') }}
          </Typography>
          <Typography
            v-if="subtitle"
            class="flex gap-2 text-gray500"
            variant="sm"
          >
            {{ subtitle }}
          </Typography>
        </div>
      </div>
    </slot>
  </el-upload>
</template>
<style>
.el-upload {
  --el-color-primary: var(--gray10);
  @apply relative;
}
.el-upload:focus {
  --el-color-primary: var(--primary600);
  color: var(--gray900);
}
.el-upload-dragger {
  @apply rounded-lg;
}
</style>
