<template>
  <Modal
    modal="$atts.modal"
    :title="$t('settings.settings')"
    type="settings"
  >
    <div class="flex flex-col gap-6 w-full h-full max-h-[300px] justify-between items-center text-sm mt-8">
      <div class="rounded-[10px] border border-gray300 p-4 w-full">
        <div class="flex w-full justify-between items-center border-b border-divider pb-4 mb-4">
          <span class="flex items-center gap-2">
            <p class="text-base font-semibold">{{ $t('overview.generalSupport') }}</p>
          </span>
        </div>

        <div class="flex justify-between items-center">
          <span class="text-sm flex items-center gap-2"
            >{{ $t('overview.chat') }}
            <span class="bg-disabled px-2 py-1 flex items-center gap-1 rounded-full text-xs">
              <Icon
                name="clock"
                class="w-3.5"
              />
              2 mins</span
            ></span
          >
          <span
            class="bg-disabled px-2 py-1.5 flex items-center gap-2 rounded-[10px] cursor-pointer"
            @click="openChat"
          >
            <Icon
              name="chat"
              class="w-4"
            />
            {{ $t('overview.liveChat') }}</span
          >
        </div>
        <div class="flex justify-between items-center mt-5">
          <span class="text-sm flex items-center gap-2"
            >{{ $t('overview.callText') }}
            <span class="bg-disabled px-2 py-1 flex items-center gap-1 rounded-full text-xs">
              <Icon
                name="clock"
                class="w-3.5"
              />
              2 mins</span
            ></span
          >
          <span>+234</span>
        </div>
        <div class="flex justify-between items-center mt-5">
          <span class="text-sm flex items-center gap-2"
            >{{ $t('overview.message') }}
            <span class="bg-disabled px-2 py-1 flex items-center gap-1 rounded-full text-xs">
              <Icon
                name="clock"
                class="w-3.5"
              />
              6 mins</span
            ></span
          >
          <span class="bg-disabled px-2 py-1.5 flex items-center gap-2 rounded-[10px]">
            <Icon
              name="msg"
              class="w-4"
            />{{ $t('new') }}</span
          >
        </div>
      </div>

      <div class="rounded-[10px] border border-gray300 p-4 w-full">
        <div class="flex w-full justify-between items-center border-b border-divider pb-4 mb-4">
          <span class="flex items-center gap-2">
            <p class="text-sm font-semibold">{{ $t('overview.contactDedicatedSupportSpecialist') }}</p>
          </span>
        </div>

        <div class="lines">
          <span class="text-base font-semibold">Tolulope Abiodun</span>
          <span class="text-sm">{{ $t('overview.mapleradIsAProductOf') }}</span>
        </div>
        <div class="flex justify-between items-center mt-5">
          <span class="text-sm flex items-center gap-2">{{ $t('overview.call') }}</span>
          <span>+234</span>
        </div>
        <div class="flex justify-between items-center mt-5">
          <span class="text-sm flex items-center gap-2">{{ $t('email') }} </span>
          <a href="mailto:tolulope.abiodun@maplerad.com">tolulope.abiodun@maplerad.com</a>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
const openChat = () => {
  window.Intercom('show')
}
</script>

<style scoped>
.lines {
  @apply flex flex-col gap-2;
}
.optionName {
  @apply font-semibold text-secondary;
}
.detailName {
  @apply text-secondary text-sm;
}
</style>
