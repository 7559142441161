import { AxiosResponse } from 'axios'
import { removeParentPermission, transformRoles } from '../helpers/transformRoles'
import { PermissionApi, PostRole, RoleApi, ServerResponse, Invite, User } from '../types/api'
import { GATEWAY_ENDPOINT } from './SetupBase'

const rolesApi = {
  $_getRoles: async () => {
    const url = '/roles'
    const res: AxiosResponse<ServerResponse<RoleApi[]>> = await GATEWAY_ENDPOINT.get(url)

    const data = res?.data?.data

    return {
      ...res?.data,
      data: transformRoles(data),
    }
  },
  $_getAllPermissions: async () => {
    const url = '/roles/permission'
    const res: AxiosResponse<ServerResponse<PermissionApi[]>> = await GATEWAY_ENDPOINT.get(url)

    const data = res?.data?.data

    return {
      ...res?.data,
      data: removeParentPermission(data),
    }
  },
  $_addRole: async (role: PostRole) => {
    const url = '/roles'
    const res: AxiosResponse<ServerResponse<any>> = await GATEWAY_ENDPOINT.post(url, role)

    return res?.data
  },
  $_getTeam: async () => {
    const url = '/users'
    const res: AxiosResponse<ServerResponse<any>> = await GATEWAY_ENDPOINT.get(url)

    return res?.data
  },
  $_inviteUser: async (invite: Invite) => {
    const url = '/users/invites'

    const res: AxiosResponse<ServerResponse<any>> = await GATEWAY_ENDPOINT.post(url, invite)

    return res?.data
  },
  $_updateUserPermission: async (user: Pick<User, 'id' | 'role' | 'status'>) => {
    const url = '/users'

    const res: AxiosResponse<ServerResponse<any>> = await GATEWAY_ENDPOINT.patch(url, user)

    return res?.data
  },
  $_removePendingUser: async (id: string) => {
    const url = '/users/invite'

    const res: AxiosResponse<ServerResponse<any>> = await GATEWAY_ENDPOINT.delete(`${url}/${id}`)
    return res?.data
  },
  $_removeUser: async (id: string) => {
    const url = '/users'

    const res: AxiosResponse<ServerResponse<any>> = await GATEWAY_ENDPOINT.delete(`${url}/${id}`)
    return res?.data
  },
  $_reinvite: async (id: string) => {
    const url = '/users/re-invite'

    const res: AxiosResponse<ServerResponse<any>> = await GATEWAY_ENDPOINT.put(url, {
      id,
    })
    return res?.data
  },
}

export default rolesApi
