export const useEnvironment = () => {
  const isProduction = () => {
    return import.meta.env.VITE_NODE_ENV === 'production'
  }

  const environmentType = () => {
    return import.meta.env.VITE_NODE_ENV
  }

  return {
    isProduction,
    environmentType,
  }
}
