<template>
  <Modal
    modal="$atts.modal"
    :title="$t('wallets.fundWallet')"
  >
    <p
      v-if="error"
      class="text-red mt-4 font-medium"
    >
      {{ error }}
    </p>
    <div class="flex flex-col w-full h-full justify-between">
      <div class="flex items-center justify-center w-full" />
      <div class="flex w-full justify-end mt-6" />
      <transition
        name="slideUp"
        appear
      >
        <form
          class="flex flex-col gap-4"
          @submit.prevent="fund"
        >
          <div class="flex flex-col gap-4 mt-2">
            <div class="field">
              <label
                for="bvn"
                class="label"
              >
                {{ $t('wallets.wallets') }}
              </label>
              <SelectMenu
                id="code"
                v-model="state.currency"
                :value="state.currency"
                :options="CurrencyArray"
                name="code"
                required
              />
            </div>
            <div class="field">
              <label
                for="bvn"
                class="label"
              >
                {{ $t('amount') }}
              </label>
              <div class="flex w-full border bg-gray100 border-gray100 rounded-[10px]">
                <span class="px-4 center">{{ state.currency }}</span>
                <input
                  id="amount"
                  :value="state.amount"
                  type="text"
                  name="amount"
                  class="w-full px-4 input rounded-l-none focus:border-none"
                  required
                  @input="handleFormat"
                />
              </div>
            </div>
          </div>

          <button
            class="ModalBtn"
            :disabled="loading || disabled"
            :loading="loading"
          >
            {{ $t('fund') }}
          </button>
        </form>
      </transition>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import { formatToCurrency } from '~/src/composables/utils/useCurrency'
import { useFundWallet } from '~~/src/composables/apis/wallet/index.js'
import { CurrencyArray } from '~/src/constants/index'

const { fund, state, loading, error, disabled } = useFundWallet()

const amount = ref(state.amount)
const handleFormat = (event: Event) => {
  amount.value = (event.target as HTMLInputElement).value
  formatToCurrency(amount)

  state.amount = amount.value
}
</script>

<style scoped>
.slideUp-enter {
  opacity: 0;
  /* transform: translateY(200px); */
}
.slideUp-leave-to {
  opacity: 0;
  /* transform: translateY(-200px); */
}

.slideUp-enter-active,
.slideUp-leave-active {
  transition: all 0.35s ease;
}
</style>
