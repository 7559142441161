import { AxiosResponse } from 'axios'
import Recase from 'better-recase'
import { GATEWAY_ENDPOINT } from '../../SetupBase'
import { TransactionResponse } from '../transactions/type'
import type {
  InvestmentResponse,
  InvestmentTransactionResponse,
  InvestmentYieldResponse,
  StashSchema,
  StashResponse,
  PortfolioResponse,
  BusinessInvestmentResponse,
  FundStash,
  FundStashResponse,
} from './type'
import { InvestmentStatus } from '~/src/types/internals'

export const stashApi = {
  $_fundStash: (payload: FundStash): Promise<AxiosResponse<FundStashResponse>> | 'ERROR' => {
    const url = 'investments/fund'
    return GATEWAY_ENDPOINT.post(url, Recase.snakeCopy(payload), {
      transformResponse: (data: string) => Recase.camelCopy(JSON.parse(data)),
    })
  },
  $_createStashPlan: (payload: StashSchema): Promise<AxiosResponse<StashResponse>> | 'ERROR' => {
    const url = 'investments/business'
    return GATEWAY_ENDPOINT.post(url, Recase.snakeCopy(payload), {
      transformResponse: (data: string) => Recase.camelCopy(JSON.parse(data)),
    })
  },
  $_getPortfolios: (): Promise<AxiosResponse<PortfolioResponse>> | 'ERROR' => {
    const url = 'investments/portfolios'
    return GATEWAY_ENDPOINT.get(url, {
      transformResponse: (data: string) => Recase.camelCopy(JSON.parse(data)),
    })
  },
  $_getInvestmentById: (id: string): Promise<AxiosResponse<InvestmentResponse>> | 'ERROR' => {
    const url = `/investments/${id}`
    return GATEWAY_ENDPOINT.get(url, {
      transformResponse: (data) => Recase.camelCopy(JSON.parse(data)),
    })
  },
  $_getInvestmentYield: (id: string): Promise<AxiosResponse<InvestmentYieldResponse>> | 'ERROR' => {
    const url = `/investments/${id}/yields`
    return GATEWAY_ENDPOINT.get(url, {
      transformResponse: (data) => Recase.camelCopy(JSON.parse(data)),
    })
  },
  $_getBusinessPortFolios: (params?: {
    page?: number
    size?: number
    status?: InvestmentStatus
  }): Promise<AxiosResponse<BusinessInvestmentResponse>> | 'ERROR' => {
    const url = '/investments/business'
    return GATEWAY_ENDPOINT.get(url, {
      transformResponse: (data) => Recase.camelCopy(JSON.parse(data)),
      params: {
        ...params,
      },
    })
  },
  $_getAllTransactions: (params?: {
    type?: 'INVESTMENT'
    page?: number
    size?: number
  }): Promise<AxiosResponse<TransactionResponse>> | 'ERROR' => {
    const { type = 'INVESTMENT', ...rest } = params || {}

    const url = `/transactions`
    return GATEWAY_ENDPOINT.get(url, {
      transformResponse: (data) => Recase.camelCopy(JSON.parse(data)),
      params: {
        type,
        ...rest,
      },
    })
  },
  $_getInvestmentTransactions: ({
    id,
    ...params
  }: {
    id: string
    page?: number
    size?: number
  }): Promise<AxiosResponse<InvestmentTransactionResponse>> | 'ERROR' => {
    const url = `/investments/${id}/transactions`
    return GATEWAY_ENDPOINT.get(url, {
      transformResponse: (data) => Recase.camelCopy(JSON.parse(data)),
      params: {
        ...params,
      },
    })
  },
}
