import { dayjs } from 'element-plus'
import { fetchCustomerDetails } from './CustomerDetails'
import { fetchCustomers } from './index'
import { customerApi } from '~/src/services/customerApi'
import { useCustomerModal } from '~/src/composables/core/modals'
import { useAlert } from '~/src/composables/core/useNotification'

export const customerId = ref('' as any)
const step = ref(0)
export const createTier0 = () => {
  const loading = ref(false)
  const credential = {
    first_name: ref(),
    last_name: ref(),
    email: ref(),
    country: ref('NG'),
  }
  const create = async () => {
    loading.value = true
    const res = (await customerApi.$_createTier_0({
      first_name: credential.first_name.value,
      last_name: credential.last_name.value,
      email: credential.email.value,
      country: credential.country.value,
    })) as any
    loading.value = false
    if (res !== 'ERROR') {
      fetchCustomers()
      useCustomerModal().closeCreateTier0()
      useAlert().openAlert({ type: 'SUCCESS', msg: res.data.message })
    }
  }
  return { create, credential, step, loading }
}

export const createTier1 = () => {
  const loading = ref(false)
  const credential = {
    phone_number: ref(''),
    country: ref('NG'),
    dob: ref(),
    identification_number: ref(),
    address: {
      street: ref(),
      street2: ref(),
      city: ref(),
      state: ref(),
      country: ref('NG'),
      postal_code: ref(),
    },
  }
  const create = async () => {
    loading.value = true

    try {
      const res = (await customerApi.$_createTier_1({
        customer_id: customerId.value,
        dob: dayjs(credential.dob.value).format('DD-MM-YYYY'),
        identification_number: credential.identification_number.value,
        phone: {
          phone_country_code: '+234',
          phone_number: String(credential.phone_number.value),
        },
        address: {
          street: credential.address.street.value,
          street2: credential.address.street2.value,
          city: credential.address.city.value,
          state: credential.address.state.value,
          country: credential.address.country.value,
          postal_code: credential.address.postal_code.value,
        },
      })) as any

      if (res !== 'ERROR') {
        useCustomerModal().closeCreateTier1()
        useAlert().openAlert({ type: 'SUCCESS', msg: res.data.message })
        fetchCustomerDetails(customerId.value)
      } else {
        useAlert().openAlert({ type: 'ERROR', msg: 'Failed to create tier 1' })
      }
    } catch (error) {
      useAlert().openAlert({ type: 'ERROR', msg: 'An error occurred while creating tier 1' })
    } finally {
      loading.value = false
    }
  }

  return { create, credential, loading }
}

export const createTier2 = () => {
  const loading = ref(false)
  const credential = {
    identity: {
      type: ref('NIN'),
      number: ref(),
      image: ref(),
      country: ref('NG'),
    },
    photo: ref(),
  }
  const create = async () => {
    loading.value = true

    const res = (await customerApi.$_createTier_2({
      customer_id: customerId.value,
      identity: {
        type: credential.identity.type.value,
        number: credential.identity.number.value.toString(),
        image: credential.identity.image.value,
        country: credential.identity.country.value,
        is_url: false,
      },
      photo: credential.photo.value,
      photo_is_url: false,
    })) as any
    loading.value = false
    if (res !== 'ERROR') {
      useCustomerModal().closeCreateTier2()
      useAlert().openAlert({ type: 'SUCCESS', msg: res.data.message })
      fetchCustomerDetails(customerId.value)
      // location.reload()
    }
  }
  return { create, credential, loading }
}
