<template>
  <Modal
    modal="$atts.modal"
    :title="$t('wallets.generateWalletStatement')"
  >
    <p
      v-if="error"
      class="text-red mt-4 font-medium"
    >
      {{ error }}
    </p>
    <div class="flex flex-col w-full h-full justify-between">
      <div class="flex items-center justify-center w-full" />
      <div class="flex w-full justify-end mt-6" />
      <transition
        name="slideUp"
        appear
      >
        <form
          class="w-full overflow-y-auto"
          @submit.prevent="generate"
        >
          <div class="flex flex-col gap-6">
            <div class="field">
              <label
                for="bvn"
                class="label"
              >
                {{ $t('dateRange') }}
              </label>
              <div class="flex items-center gap-2">
                <input
                  id="start date"
                  v-model="credential.start.value"
                  type="date"
                  name="start date"
                  class="w-full px-4 input"
                  required
                />
                <span class="mx-2">{{ $t('to') }}</span>
                <input
                  id="end date"
                  v-model="credential.end.value"
                  type="date"
                  name="end date"
                  class="w-full px-4 input"
                  required
                />
              </div>
            </div>
          </div>

          <button
            class="ModalBtn w-full"
            :disabled="loading || disabled"
          >
            <span v-if="!loading"> {{ $t('generate') }}</span> <Spinner v-else />
          </button>
        </form>
      </transition>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import { generateWalletStatement } from '~/src/composables/apis/wallet/generate'

const { generate, credential, loading, error, disabled } = generateWalletStatement()
</script>

<style scoped>
.slideUp-enter {
  opacity: 0;
  /* transform: translateY(200px); */
}
.slideUp-leave-to {
  opacity: 0;
  /* transform: translateY(-200px); */
}

.slideUp-enter-active,
.slideUp-leave-active {
  transition: all 0.35s ease;
}
</style>
