<script setup lang="ts">
enum TagType {
  success = 'success',
  info = 'info',
  warning = 'warning',
  danger = 'danger',
  default = 'primary',
}
const props = withDefaults(defineProps<{ round?: boolean; type?: `${TagType}`; bgColor?: string; color?: string }>(), {
  type: 'primary',
  bgColor: undefined,
  color: undefined,
})
const color = computed(() => {
  switch (props.type) {
    case TagType.success:
      return {
        bg: 'var(--success100)',
        text: 'var(--success900)',
      }
    case TagType.warning:
      return {
        bg: 'var(--warning100)',
        text: 'var(--warning900)',
      }
    case TagType.danger:
      return {
        bg: '', // Todo: get light color of red
        text: '',
      }
    case TagType.info:
      return {
        bg: 'var(--gray200)',
        text: 'var(--gray700)',
      }
    case TagType.default:
      return {
        bg: `var(--${props.bgColor})`,
        text: `var(--${props.color})`,
      }
    default:
      return {
        bg: 'var(--primary)',
        text: 'var(--secondary)',
      }
  }
})

const radius = computed(() => (props.round ? '10px' : '4px'))
</script>
<template>
  <el-tag
    class="tag"
    v-bind="{ ...$attrs, ...props }"
  >
    <slot />
  </el-tag>
</template>
<style>
.tag.el-tag,
.tag.el-tag--small,
.tag.el-tag--large,
.tag.el-tag.el-tag--info,
.tag.el-tag.el-tag--success,
.tag.el-tag.el-tag--warning {
  /* TODO: add danger  */
  @apply border-none font-medium;
  --el-tag-border-radius: v-bind(radius);
  --el-tag-text-color: v-bind(color.text);
  --el-tag-bg-color: v-bind(color.bg);
}
</style>
