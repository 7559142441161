import { PermissionApi, RoleApi } from '../types/api'
import { Role } from '../types/internals'
import { groupBy } from './groupBy'

export const removeParentPermission = (perm: PermissionApi[]) => {
  return perm.filter(({ key }) => !key.includes('-*'))
}

export const groupByPermissionType = (data: PermissionApi[]) => {
  return groupBy<PermissionApi>(data, ({ type }: PermissionApi) => type)
}

export const transformRoles = (roles: RoleApi[]): Role[] => {
  return roles.map(({ permissions, ...rest }) => ({
    ...rest,
    permissions: groupByPermissionType(removeParentPermission(permissions)),
  }))
}
