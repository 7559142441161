<script setup lang="ts">
import { BaseFieldProps, Path } from 'vee-validate'
import { ValueOf } from 'element-plus/es/components/table/src/table-column/defaults'
import { DateFormat, VeeValidationProps } from '~/src/types/internals'
import allCountries from '~/src/constants/countries'
import { MoMoSenderId } from '~/src/services/requests/transfers/types'
import { IDENTIFICATION_OPTIONS } from '~/src/constants'
import Calendar from '~/src/components/icons/Calendar.vue'

interface Props {
  step: number
  nationality?: string
  nationalityProps: BaseFieldProps & VeeValidationProps
  type?: string
  typeProps: BaseFieldProps & VeeValidationProps
  number?: string
  numberProps: BaseFieldProps & VeeValidationProps
  issuedAt?: string
  issuedAtProps: BaseFieldProps & VeeValidationProps
  issuedDate?: string
  issuedDateProps: BaseFieldProps & VeeValidationProps
  expirationDate?: string
  expirationDateProps: BaseFieldProps & VeeValidationProps
}

defineProps<Props>()
const emit = defineEmits(['updateField'])
const countries = allCountries.map(({ text: label, id: value }) => ({
  value,
  label,
}))
const idOptions = IDENTIFICATION_OPTIONS.map(({ id: value, text: label }) => ({
  label,
  value,
}))

const handleUpdate = (key: Path<MoMoSenderId>, value: ValueOf<MoMoSenderId>) => {
  emit('updateField', {
    key,
    value,
  })
}
</script>
<template>
  <div class="flex flex-col gap-6 my-6">
    <div>
      <badge name="default">{{ $t('step', { number: step + 1, total: 3 }) }} </badge>

      <Typography class="mt-4">{{ $t('transactions.addSendersId') }}</Typography>
    </div>

    <FormControl
      :label="$t('nationality')"
      v-bind="nationalityProps"
    >
      <Select
        :model-value="nationality"
        filterable
        class="w-full"
        size="large"
        :placeholder="`--${$t('choose')}--`"
        @update:model-value="($event) => handleUpdate('nationality', $event)"
      >
        <SelectOption
          v-for="item in countries"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
          <span class="inline-flex justify-between items-center w-full">
            {{ item.label }}

            <IconsCheckOutlined
              v-if="nationality === item.value"
              class="text-success900 h-4 w-4"
            />
          </span>
        </SelectOption>
      </Select>
    </FormControl>

    <FormControl
      :label="$t('meansOfIdentification')"
      v-bind="typeProps"
    >
      <Select
        :model-value="type"
        filterable
        class="w-full"
        size="large"
        :placeholder="`--${$t('choose')}--`"
        @update:model-value="($event) => handleUpdate('type', $event)"
      >
        <SelectOption
          v-for="item in idOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
          <span class="inline-flex justify-between items-center w-full">
            {{ item.label }}

            <IconsCheckOutlined
              v-if="type === item.value"
              class="text-success900 h-4 w-4"
            />
          </span>
        </SelectOption>
      </Select>
    </FormControl>

    <FormControl
      :label="$t('customer.countryIssued')"
      v-bind="issuedAtProps"
    >
      <Select
        :model-value="issuedAt"
        filterable
        class="w-full"
        size="large"
        :placeholder="`--${$t('choose')}--`"
        @update:model-value="($event) => handleUpdate('issuedAt', $event)"
      >
        <SelectOption
          v-for="item in countries"
          :key="item.value"
          :label="item.label"
          :value="item.label"
        >
          <span class="inline-flex justify-between items-center w-full">
            {{ item.label }}

            <IconsCheckOutlined
              v-if="issuedAt === item.label"
              class="text-success900 h-4 w-4"
            />
          </span>
        </SelectOption>
      </Select>
    </FormControl>

    <FormControl
      :label="$t('customer.identificationNumber')"
      v-bind="numberProps"
    >
      <InputField
        :model-value="number"
        placeholder="AA00"
        @update:model-value="($event) => handleUpdate('number', $event)"
      />
    </FormControl>

    <div class="flex gap-4">
      <FormControl
        :label="$t('dateIssued')"
        v-bind="issuedDateProps"
        class="flex-2 w-3/5"
      >
        <DatePicker
          :model-value="issuedDate"
          size="large"
          type="date"
          placeholder="MM / DD / YYYY"
          class="!w-full"
          :value-format="DateFormat.DefaultAlt"
          :prefix-icon="Calendar"
          :clearable="false"
          @update:model-value="($event) => handleUpdate('issuedDate', $event)"
        />
      </FormControl>

      <FormControl
        :label="$t('cards.expiryDate')"
        v-bind="expirationDateProps"
        class="flex-2 w-3/5"
      >
        <DatePicker
          :model-value="expirationDate"
          size="large"
          type="date"
          placeholder="MM / DD / YYYY"
          class="!w-full"
          :value-format="DateFormat.DefaultAlt"
          :prefix-icon="Calendar"
          :clearable="false"
          @update:model-value="($event) => handleUpdate('expirationDate', $event)"
        />
      </FormControl>
    </div>
  </div>
</template>
