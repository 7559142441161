export const groupBy = <T extends object>(array: T[], callback: (value: T) => string) => {
  return array.reduce(
    (acc, item) => {
      const key = callback(item)

      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(item)
      return acc
    },
    {} as Record<string, T[]>,
  )
}
