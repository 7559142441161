<template>
  <Modal
    modal="$atts.modal"
    :title="$t('customer.createCust')"
  >
    <div class="flex flex-col w-full h-full justify-between">
      <div class="flex items-center justify-center w-full" />
      <div class="flex w-full justify-end mt-6">
        <badge name="default"> {{ $t('step', { number: step, total: 3 }) }} </badge>
      </div>
      <transition
        name="slideUp"
        appear
        mode="out-in"
      >
        <stepOne v-if="step === 1" />
        <stepTwo v-else-if="step === 2" />
        <stepThree v-else-if="step === 3" />
      </transition>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import stepOne from './stepOne.vue'
import stepTwo from './stepTwo.vue'
import stepThree from './stepThree.vue'
import Modal from '@/components/core/modal/index.vue'
import { createCustomer } from '~/src/composables/apis/customer'

const { step } = createCustomer()
</script>

<style scoped>
.slideUp-enter,
.slideUp-leave-to {
  opacity: 0;
  transform: translateY(-200px);
}

.slideUp-enter-active,
.slideUp-leave-active {
  transition: all 0.35s;
}
</style>
