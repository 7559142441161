import { useAlert } from '../core/useNotification'
import { fxApi } from '~/src/services/fxApi'
import { fromMinorUnit } from '~/src/helpers/fromMinorUnit'
import { useWalletsModal } from '~/src/composables/core/modals'
import { getWallet } from '~~/src/composables/apis/wallet'
import { toMinorUnit } from '~/src/helpers/toMinorUnit'

let exchange: any
const credential = {
  source_currency: ref('USD'),
  target_currency: ref('NGN'),
  amount: ref(1) as any,
  rate: ref(''),
  result: ref('0'),
  reference: ref(''),
}

export const currencyExchange = () => {
  const loading = ref(false)
  const { fetchWallet, loading: WalletLoading, walletData } = getWallet()

  const disabled = computed(() => {
    if (credential.source_currency.value === '' || !credential.amount.value || credential.target_currency.value === '')
      return true
    else return false
  })

  watch(credential.source_currency, (value, prev) => {
    if (value === 'NGN' && credential.amount.value < 10) credential.amount.value = 10
    if (value === credential.target_currency.value) credential.target_currency.value = prev
    exchange()
  })
  watch(credential.target_currency, (value, prev) => {
    if (value === credential.source_currency.value) credential.source_currency.value = prev
    exchange()
  })
  watch(credential.amount, (value: any) => {
    if (value === 0) return (credential.result.value = fromMinorUnit('0'))
    if (value) exchange()
    else {
      credential.amount.value = null
    }
  })

  exchange = async () => {
    loading.value = true
    const res = (await fxApi.$_GeneralQuote({
      source_currency: credential.source_currency.value,
      target_currency: credential.target_currency.value,
      amount: toMinorUnit(credential.amount.value.toString()),
    })) as any
    loading.value = false

    if (res !== 'ERROR') {
      credential.result.value = fromMinorUnit(res.data.data.target.amount)
      credential.rate.value = res.data.data.rate
      credential.reference.value = res.data.data.reference
    }
  }
  return { credential, exchange, loading, disabled, fetchWallet, WalletLoading, walletData }
}

export const convertCurrency = () => {
  const { fetchWallet } = getWallet()

  const loading = ref(false)

  const convert = async () => {
    loading.value = true
    const res = (await fxApi.$_ExchangeCurrency({
      quote_reference: credential.reference.value,
    })) as any
    loading.value = false

    if (res !== 'ERROR') {
      fetchWallet()
      exchange()
      useWalletsModal().closeAll()
      useAlert().openAlert({ type: 'SUCCESS', msg: res.data.message })
    }
  }
  return { credential, convert, loading }
}
