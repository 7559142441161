import { Ref } from 'vue'

// TODO: clean up this file
export const formatToCurrency = (amount: Ref<string>) => {
  // Todo: prevent direct mutation
  const number = parseFloat(amount.value.replace(/[^0-9.]/g, ''))
  if (!isNaN(number)) {
    amount.value = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
}

export default (amount: string) => {
  const number = parseFloat(amount.replace(/[^0-9.]/g, ''))

  return !isNaN(number) ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : number
}

export const useCurrencyFormatter = () => {
  const addComma = (val: string) => {
    return val.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  const formatCurrency = (number: number): string => {
    const formattedNumber = number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return `$${formattedNumber}`
  }

  const comma = (event: any) => {
    event.target.value = addComma(event.target.value)
  }

  return {
    comma,
    addComma,
    formatCurrency,
  }
}

// TODO use component lib
export const maCurrencyFormatter = (currency: string) =>
  new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: 15,
  })
