import { ExportToCsv } from 'export-to-csv'
import { useHomeModal } from '../core/modals'

let exportFunc: Function

export const downloadFile = (file: Blob, filename: string) => {
  const anchor = document.createElement('a')
  document.body.appendChild(anchor)
  anchor.style.setProperty('display', 'none')

  const objectUrl = window.URL.createObjectURL(file)
  anchor.href = objectUrl
  anchor.download = filename
  anchor.click()

  window.URL.revokeObjectURL(objectUrl)
}

export const useExport = () => {
  const state = reactive<{ loading: boolean }>({
    loading: false,
  })

  const exportData = <T extends Record<string, any>>({
    data,
    filename,
    headers,
  }: {
    headers: { title: string; key: keyof T; getter?: (value: T) => string | undefined }[]
    data: T[]
    filename: string
  }) => {
    state.loading = true

    const exportedRes = data.map((item) => {
      return Object.fromEntries([
        ...headers.map(({ title, key, getter }) => [title, getter ? getter(item) : item[key] || '-']),
      ])
    })

    const csvExporter = new ExportToCsv({
      title: 'Maplerad Transaction',
      filename,
      showLabels: true,
      showTitle: true,
      useTextFile: false,
      headers: headers.map(({ title }) => title),
    })
    csvExporter.generateCsv(exportedRes)

    state.loading = false
  }

  const exportToDocument = async <V>(args: V) => {
    state.loading = true
    await exportFunc(args)

    state.loading = false
    useHomeModal().closeExport()
  }

  const handleExport = (exportRequest: Function) => {
    exportFunc = exportRequest
    useHomeModal().openExport()
  }

  return {
    state,
    exportData,
    exportToDocument,
    handleExport,
  }
}
