<template>
  <select
    :id="Math.random()"
    v-bind="{
      ...$attrs,
      onChange: updateValue,
    }"
    :disabled="disabled"
    :class="[
      'input ',
      variant === 'black' ? 'styled-select-black' : 'styled-select',
      loading || disabled ? 'text-gray cursor-not-allowed' : '',
    ]"
  >
    <option
      value=""
      disabled
      selected
    >
      {{ loading ? 'Loading...' : placeholder }}
    </option>
    <option
      v-for="option in options"
      :key="option"
      :value="option.id"
      :disabled="option.disabled"
      :selected="option.id === modelValue"
    >
      {{ option.text || option.name }}
    </option>
  </select>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    variant: {
      type: String,
      default: 'default',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(_, context) {
    const updateValue = (event) => {
      const val = event.target.value
      context.emit('update:modelValue', val)
    }

    return {
      updateValue,
    }
  },
}
</script>

<style scoped>
.styled-select {
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  background: url('../../assets/icons/source/downArrow.svg') white;
  background-repeat: no-repeat;
  background-attachment: relative;
  background-position: 97% 50%;
  z-index: 4;
}
.styled-select-black {
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  background: url('../../assets/icons/source/downArrowWhite.svg') black;
  background-repeat: no-repeat;
  background-attachment: relative;
  background-position: 87% 50%;
  z-index: 4;
  color: white;
}
</style>

<style>
*::placeholder {
  color: #9ca3af !important;
}
</style>
