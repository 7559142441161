import { getAllTransfers } from './index'
import { useAlert } from '~/src/composables/core/useNotification'
import { useTransfersModal } from '~/src/composables/core/modals'
import { resolveAccount, getBanks } from '~/src/composables/apis/institution'
import { transferApi } from '~/src/services/requests/transactions/index'
import { ROUTES_NAMES } from '~/src/routes'
import { SnakeMomoTransfer } from '~/src/services/requests/transfers/types'

const { fetch: fetchBanks, banks, loading: bankLoading } = getBanks()
export const mapleradTransferLoading = ref(false)

export const useMapleradTransfer = () => {}

export const createMapleradTransfer = () => {
  const { fetchTransfer } = getAllTransfers()

  const error = ref('')
  const credential = {
    bank_code: computed(() => {
      const mapleradCode = banks.value.find((item: any) => item.name === 'Maplerad')

      return mapleradCode?.id
    }),
    account_number: ref(''),
    amount: ref(''),
    reason: ref(''),
    currency: ref('NGN'),
    to: ref(''),
  }

  const disabled = computed(() => {
    if (
      credential.bank_code.value === '' ||
      credential.amount.value === '' ||
      credential.account_number.value.toString().length !== 9 ||
      resolved.value === '' ||
      credential.currency.value === '' ||
      /[a-zA-Z]/.test(credential.account_number.value)
    )
      return true
    else return false
  })

  const { fetch, loading: AccLoading, resolved } = resolveAccount()

  watch(credential.account_number, async (val) => {
    if (val.toString().length < 9 || val.toString().length > 9) {
      error.value = 'Invalid Account Number'
      resolved.value = ''
    }
    if (/[a-zA-Z]/.test(val.toString())) {
      error.value = "Account Number Can't contain strings"
      resolved.value = ''
    }
    if (val.toString().length === 9) {
      error.value = ''
      await fetch({
        accountNumber: credential.account_number.value.toString(),
        bankCode: credential.bank_code.value!,
      })
      credential.to = resolved
    }
  })

  const create = async () => {
    mapleradTransferLoading.value = true
    const res = (await transferApi.$_createTransfer({
      bank_code: credential.bank_code.value,
      account_number: credential.account_number.value.toString(),
      amount: Number(credential.amount.value),
      currency: credential.currency.value,
      reason: credential.reason.value,
    } as SnakeMomoTransfer)) as any
    mapleradTransferLoading.value = false

    if (res !== 'ERROR') {
      useTransfersModal().closeCreateMapleradTransfer()
      useRouter().push({
        name: ROUTES_NAMES.transactions,
      })
      fetchTransfer()
      useAlert().openAlert({ type: 'SUCCESS', msg: res.data.message })
    }
  }
  return {
    credential,
    create,
    mapleradTransferLoading,
    AccLoading,
    error,
    disabled,
    resolved,
    bankLoading,
    fetchBanks,
  }
}
