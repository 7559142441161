<template>
  <Modal
    width="448px"
    modal="$atts.modal"
  >
    <div class="flex flex-col gap-6 w-full">
      <div class="text-left">
        <Typography
          variant="lgBold"
          class="mb-2"
        >
          {{ $t('transactions.selectDestination') }}
        </Typography>

        <Typography variant="sm">
          {{ $t('transactions.chooseCountryToSend') }}
        </Typography>
      </div>

      <FormControl
        :label="$t('country')"
        class="w-full"
      >
        <Select
          v-model="state.credential.country"
          class="w-full"
          size="large"
        >
          <SelectOption
            v-for="item in countriesCode"
            :key="item.id"
            :label="item.text"
            :value="item.id"
          >
            {{ item.text }}

            <IconsCheckOutlined
              v-if="state.credential.country === item.id"
              class="text-success900 h-4 w-4"
            />
          </SelectOption>
        </Select>
      </FormControl>

      <div class="flex self-end gap-4">
        <Button
          variant="secondary"
          size="large"
          @click="useTransfersModal().closeBankTransferCountry()"
        >
          {{ $t('cancel') }}
        </Button>
        <Button
          :disabled="!state.credential.country"
          size="large"
          @click="determineNextRoute"
        >
          {{ $t('proceed') }}
        </Button>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router'
import { createBankTransfer } from '~/src/composables/apis/transfers/bankTransfer'
import { countriesCode } from '~/src/constants/countriesCode'
import { useTransfersModal } from '~/src/composables/core/modals'
import { CurrencyCode } from '~/src/types/internals'
import { useFundWallet } from '~~/src/composables/apis/wallet/index.js'

const router = useRouter()
const { getVirtualAccounts, usdVirtualAccounts } = useFundWallet()
const firstUsdAccount = computed(() => {
  const [first] = usdVirtualAccounts.value
  return first
})

const { state } = createBankTransfer()

const determineNextRoute = () => {
  useTransfersModal().closeBankTransferCountry()

  if (state.credential.country === CurrencyCode.USD) {
    router.push(`/transfer/usd/${firstUsdAccount.value.id}`)
    return
  }
  useTransfersModal().openCreateBankTransfer()
}

getVirtualAccounts(CurrencyCode.USD)
</script>
