import Recase from 'better-recase'
import { AxiosResponse } from 'axios'
import { GATEWAY_ENDPOINT } from '../../SetupBase'
import { BusinessCard, Card, CustomerCard, FundCard } from './type'
import { SnakifyKeys } from '~/src/types/utilities'
import { addQueryParam } from '~/src/composables/utils/useActivityChecker'
import { PAGINATION_LIMIT } from '~/src/constants'
import { ServerResponse } from '~/src/types/api'

export const cardApi = {
  $_createCustomerCard: (credential: SnakifyKeys<CustomerCard>) => {
    const url = '/issuing/'
    return GATEWAY_ENDPOINT.post(url, credential)
  },
  $_createBusinessCard: (credential: SnakifyKeys<BusinessCard>) => {
    const url = '/issuing/business'
    return GATEWAY_ENDPOINT.post(url, credential)
  },
  $_getCard: (id: string): Promise<AxiosResponse<ServerResponse<Card>>> | 'ERROR' => {
    const url = `/issuing/${id}`
    return GATEWAY_ENDPOINT.get(url, {
      transformResponse: (data) => Recase.camelCopy(JSON.parse(data)),
    })
  },
  $_getCardTransactions: (id: string) => {
    const url = `/issuing/${id}/transactions`
    return GATEWAY_ENDPOINT.get(url)
  },
  $_getAllCard: (page = 1, credential: any, limit = PAGINATION_LIMIT) => {
    const url = ref('/issuing/?')

    addQueryParam(url, 'page', page)
    addQueryParam(url, 'page_size', limit)
    addQueryParam(url, 'id', credential.id.value)
    addQueryParam(url, 'created_at', credential.created_at.value)
    addQueryParam(url, 'brand', credential.brand.value)
    addQueryParam(url, 'status', credential.status.value)
    addQueryParam(url, 'customer_id', credential.customer_id.value)

    url.value = url.value.slice(0, -1)
    return GATEWAY_ENDPOINT.get(url.value)
  },
  $_enableCard: (id: string) => {
    const url = `/issuing/${id}/enable`
    return GATEWAY_ENDPOINT.patch(url)
  },
  $_freezeCard: (id: string) => {
    const url = `/issuing/${id}/freeze`
    return GATEWAY_ENDPOINT.patch(url)
  },
  $_unfreezeCard: (id: string) => {
    const url = `/issuing/${id}/unfreeze`
    return GATEWAY_ENDPOINT.patch(url)
  },

  $_creditCard: (credential: FundCard) => {
    const { amount } = credential
    const url = `/issuing/${credential.id}/fund`

    return GATEWAY_ENDPOINT.post(url, { amount })
  },
  $_debitCard: (credential: any, id: string) => {
    const url = `/issuing/${id}/withdraw`
    return GATEWAY_ENDPOINT.post(url, credential)
  },
  $_terminateCard: (id: string) => {
    const url = `/issuing/${id}/terminate`
    return GATEWAY_ENDPOINT.put(url)
  },
  $_getCardStatement: (credential: any) => {
    const url = `/statement/issuing/${credential.id}?start_date=${credential.start}&end_date=${credential.end}`
    return GATEWAY_ENDPOINT.get(url, { responseType: 'arraybuffer' })
  },
}
