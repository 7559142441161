<script setup lang="ts">
import { Path, useForm } from 'vee-validate'
import { ValueOf } from 'element-plus/es/components/table/src/table-column/defaults'
import { toTypedSchema } from '@vee-validate/yup'
import StepOne from './StepOne.vue'
import StepTwo from './StepTwo.vue'
import StepThree from './StepThree.vue'
import { useMobileMoneyTransfer } from '~/src/composables/apis/transfers/MobileMoneyTransfer'
import {
  senderIdentificationSchema,
  senderSchema,
  transferBeneficiarySchema,
} from '~/src/services/requests/transfers/schema'
import { MoMoSender, MoMoSenderId, TransferBeneficiary } from '~/src/services/requests/transfers/types'
import { useTransfersModal } from '~/src/composables/core/modals'
import { CountryIso, CurrencyKey, TransferScheme } from '~/src/types/internals'

const { closeMobileMoneyTransfer } = useTransfersModal()
const { makeTransfer, loading } = useMobileMoneyTransfer()
const step = ref(0)

const schemas = [transferBeneficiarySchema, senderSchema, senderIdentificationSchema]
const currentSchema = computed(() => toTypedSchema(schemas[step.value]))

const { defineField, handleSubmit, resetForm, setFieldValue } = useForm({
  validationSchema: currentSchema,
  keepValuesOnUnmount: true,
  initialValues: {
    meta: {
      scheme: TransferScheme.MOBILEMONEY,
    },
    country: 'NG',
  },
})

const elPlusConfig = (state: any) => ({
  props: {
    validateEvent: false,
    error: state.errors[0],
    required: state.required,
  },
})

const [reason, reasonProps] = defineField('reason', elPlusConfig)
const [currency, currencyProps] = defineField('currency', elPlusConfig)
const [amount, amountProps] = defineField('amount', elPlusConfig)
const [bankCode, bankCodeProps] = defineField('bankCode', elPlusConfig)
const [accountNumber, accountNumberProps] = defineField('accountNumber', elPlusConfig)
const [counterparty, counterpartyProps] = defineField('meta.counterparty.name', elPlusConfig)
const [_] = defineField('meta.counterparty.phoneNumber', elPlusConfig)

// Sender Details
const [firstName, firstNameProps] = defineField('firstName', elPlusConfig)
const [lastName, lastNameProps] = defineField('lastName', elPlusConfig)
const [country, countryProps] = defineField('country', elPlusConfig)
const [address, addressProps] = defineField('address', elPlusConfig)
const [city, cityProps] = defineField('city', elPlusConfig)
const [state, stateProps] = defineField('state', elPlusConfig)
const [postalCode, postalCodeProps] = defineField('postalCode', elPlusConfig)
const [email, emailProps] = defineField('email', elPlusConfig)
const [dob, dobProps] = defineField('dob', elPlusConfig)
const [phoneNumber, phoneNumberProps] = defineField('phoneNumber', elPlusConfig)

// Sender Identification
const [nationality, nationalityProps] = defineField('nationality', elPlusConfig)
const [type, typeProps] = defineField('type', elPlusConfig)
const [number, numberProps] = defineField('number', elPlusConfig)
const [issuedAt, issuedAtProps] = defineField('issuedAt', elPlusConfig)
const [issuedDate, issuedDateProps] = defineField('issuedDate', elPlusConfig)
const [expirationDate, expirationDateProps] = defineField('expirationDate', elPlusConfig)

const isKenyan = computed(() => currency.value === CountryIso.KES)
const { t } = useI18n()
const submit = t('submit')
const next = t('next')

const updateBeneficiaryFields = ({
  key,
  value,
}: {
  key: Path<TransferBeneficiary | MoMoSender | MoMoSenderId>
  value: ValueOf<TransferBeneficiary | MoMoSender | MoMoSenderId>
}) => {
  setFieldValue(key, value)
}

const nextStep = handleSubmit((values) => {
  const {
    currency: currencyValue,
    amount,
    reference,
    meta,
    reason,
    accountNumber,
    bankCode,
    ...rest
  } = values as TransferBeneficiary

  const currency = CurrencyKey[currencyValue as keyof typeof CurrencyKey]

  const { type, number, issuedAt, issuedDate, expirationDate, ...senderDetails } = rest as MoMoSender & MoMoSenderId

  const data = {
    reason,
    accountNumber,
    bankCode,
    currency,
    amount: Number(amount),
    reference,
    meta: !isKenyan.value
      ? meta
      : {
          ...meta,
          sender: {
            ...senderDetails,
            id: {
              type,
              number,
              issuedAt,
              issuedDate,
              expirationDate,
            },
          },
        },
  }

  if (!isKenyan.value || step.value === 2) {
    makeTransfer(data, {
      onSuccess: () => {
        closeMobileMoneyTransfer()
      },
    })

    return
  }
  step.value = step.value + 1
})

const prevStep = () => {
  if (step.value <= 0) {
    return
  }

  step.value--
}
</script>
<template>
  <Modal
    modal="$atts.modal"
    :title="$t('transactions.mobileMoneyTransfer')"
    @close="resetForm"
  >
    <form
      class="w-full"
      @submit="nextStep"
    >
      <StepOne
        v-if="step === 0"
        :reason-props="reasonProps"
        :reason="reason"
        :currency="currency"
        :amount="amount?.toString()"
        :bank-code="bankCode"
        :account-number="accountNumber"
        :counterparty="counterparty"
        :counterparty-props="counterpartyProps"
        :amount-props="amountProps"
        :currency-props="currencyProps"
        :bank-code-props="bankCodeProps"
        :account-number-props="accountNumberProps"
        @update-field="updateBeneficiaryFields"
      />
      <StepTwo
        v-else-if="step === 1"
        :step="step"
        :first-name-props="firstNameProps"
        :first-name="firstName"
        :last-name="lastName"
        :country="country"
        :address="address"
        :city="city"
        :country-props="countryProps"
        :last-name-props="lastNameProps"
        :address-props="addressProps"
        :postal-code="postalCode"
        :postal-code-props="postalCodeProps"
        :email="email"
        :email-props="emailProps"
        :phone-number="phoneNumber"
        :phone-number-props="phoneNumberProps"
        :city-props="cityProps"
        :dob="dob"
        :dob-props="dobProps"
        :state="state"
        :state-props="stateProps"
        @update-field="updateBeneficiaryFields"
      />
      <StepThree
        v-else-if="step === 2"
        :step="step"
        :nationality="nationality"
        :nationality-props="nationalityProps"
        :type="type"
        :type-props="typeProps"
        :number="number"
        :number-props="numberProps"
        :issued-at="issuedAt"
        :issued-at-props="issuedAtProps"
        :issued-date="issuedDate"
        :issued-date-props="issuedDateProps"
        :expiration-date="expirationDate"
        :expiration-date-props="expirationDateProps"
        @update-field="updateBeneficiaryFields"
      />

      <div class="flex gap-4">
        <Button
          v-if="step !== 0"
          type="button"
          class="flex-1"
          size="large"
          variant="text"
          @click="prevStep"
        >
          {{ $t('back') }}
        </Button>

        <Button
          class="flex-1"
          size="large"
          :loading="loading"
        >
          <template v-if="step === 2">Confirm</template>
          <template v-else>{{ isKenyan ? next : submit }}</template>
        </Button>
      </div>
    </form>
  </Modal>
</template>
<style scoped>
.slideUp-enter,
.slideUp-leave-to {
  opacity: 0;
  transform: translateY(-200px);
}

.slideUp-enter-active,
.slideUp-leave-active {
  opacity: 1;
  transition: all 0.35s;
}
</style>
