<template>
  <aside>
    <nav>
      <ul class="flex flex-col">
        <li
          v-for="route in routes"
          :key="route.name"
        >
          <Typography
            v-if="!route.children"
            as="NuxtLink"
            :to="route.route"
            class="capitalize flex py-2 px-4 items-center duration-75 rounded-[10px] hover:bg-gray300 my-0.5"
            active-class="bg-gray300"
          >
            <MaIcon
              v-if="route.route === '/stash' || route.route === '/fx'"
              :name="route.icon"
              class="w-5 mr-4"
            />
            <icon
              v-else
              :name="route.icon"
              class="w-5 mr-4"
            />

            {{ $t(route.name) }}
          </Typography>
          <details
            v-else
            open
          >
            <summary
              class="flex py-2 px-4 items-center duration-75 rounded-[10px] hover:bg-gray300 my-0.5"
              :class="{ 'bg-gray300': isChildActive(route) }"
            >
              <icon
                :name="route.icon"
                class="w-5 mr-4"
              />
              <Typography class="capitalize"> {{ $t(route.name) }} </Typography>
              <icon
                name="arrowUp"
                class="turn w-4 ml-auto"
              />
            </summary>

            <div class="flex flex-col">
              <Typography
                v-for="child in route.children"
                :key="child.name"
                as="NuxtLink"
                :to="child.route"
                class="capitalize flex py-2 px-4 items-center duration-75 rounded-[10px] hover:bg-gray300 my-0.5 ml-8"
              >
                {{ $t(child.name) }}
              </Typography>
            </div>
          </details>
        </li>
      </ul>
    </nav>

    <Button
      variant="text"
      class="self-start"
      @click="invite"
    >
      <icon
        name="add"
        class="w-5 !text-secondary"
      />

      {{ $t('nav.invitePeople') }}
    </Button>
  </aside>
</template>

<script lang="ts" setup>
import { MaIcon } from '@wirepay/maplerad-component-lib'
import { useSettingsModal } from '~/src/composables/core/modals'
import { useBusiness } from '~/src/composables/apis/business'
import { ApiFeatureFlag } from '~/src/types/internals'
import { useEnvironment } from '~/src/composables/utils/useEnviroment'

const router = useRoute()

const invite = () => {
  useSettingsModal().openNewTeam()
}

const { business } = useBusiness()
const stashIsEnabled = business.value?.feature_flags?.includes(ApiFeatureFlag.STASH)

const allRoutes = [
  {
    icon: 'rocket',
    name: 'nav.links.getStarted',
    route: '/',
  },
  {
    icon: 'bills',
    name: 'nav.links.cash',
    children: [
      {
        name: 'nav.links.transactions',
        route: '/transactions',
      },
      {
        name: 'nav.links.billPayment',
        route: '/bills',
      },
      {
        name: 'nav.links.wallets',
        route: '/wallets',
      },
    ],
  },
  {
    icon: 'stash',
    name: 'nav.links.stash',
    route: '/stash',
  },
  {
    icon: 'convert',
    name: 'nav.links.fxConversion',
    route: '/fx',
  },
  {
    icon: 'card',
    name: 'nav.links.cards',
    route: '/cards',
  },
  {
    icon: 'customer',
    name: 'nav.links.customers',
    route: '/customers',
  },
]

const { isProduction } = useEnvironment()

const routes = computed(() => {
  const staticRoutes = allRoutes.filter((route) => {
    if (!isProduction()) {
      return true
    }
    if (route.name === 'nav.links.stash') {
      return stashIsEnabled
    }
    return true
  })

  const [_, ...rest] = staticRoutes
  return business.value?.live ? rest : staticRoutes
})

const isChildActive = (route: any) => {
  return route.children.some((child: any) => router.path.includes(child.route))
}
</script>

<style scoped lang="scss">
.bg-shadow {
  box-shadow: 0px 2px 16px #1f29371f;
}
details {
  user-select: none;
  & summary svg.turn {
    color: var(--secondary);
  }
  & summary svg {
    color: var(--secondary);
  }
}
details[open] {
  & summary svg {
    color: var(--secondary);
  }
  & summary svg.turn {
    transform: rotate(-180deg);
  }
}
summary {
  cursor: pointer;
}
svg {
  transition: all 0.3s;
}
summary::-webkit-details-marker {
  display: none;
}
:focus {
  outline: none;
}
</style>
