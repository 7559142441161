export default [
  { CallingCode: '93', text: 'Afghanistan', id: 'AF' },
  { CallingCode: '358', text: 'Åland Islands', id: 'AX' },
  { CallingCode: '355', text: 'Albania', id: 'AL' },
  { CallingCode: '213', text: 'Algeria', id: 'DZ' },
  { CallingCode: '1684', text: 'American Samoa', id: 'AS' },
  { CallingCode: '376', text: 'Andorra', id: 'AD' },
  { CallingCode: '244', text: 'Angola', id: 'AO' },
  { CallingCode: '1264', text: 'Anguilla', id: 'AI' },
  { CallingCode: '672', text: 'Antarctica', id: 'AQ' },
  { CallingCode: '1268', text: 'Antigua and Barbuda', id: 'AG' },
  { CallingCode: '54', text: 'Argentina', id: 'AR' },
  { CallingCode: '374', text: 'Armenia', id: 'AM' },
  { CallingCode: '297', text: 'Aruba', id: 'AW' },
  { CallingCode: '61', text: 'Australia', id: 'AU' },
  { CallingCode: '43', text: 'Austria', id: 'AT' },
  { CallingCode: '994', text: 'Azerbaijan', id: 'AZ' },
  { CallingCode: '1242', text: 'Bahamas', id: 'BS' },
  { CallingCode: '973', text: 'Bahrain', id: 'BH' },
  { CallingCode: '880', text: 'Bangladesh', id: 'BD' },
  { CallingCode: '1246', text: 'Barbados', id: 'BB' },
  { CallingCode: '375', text: 'Belarus', id: 'BY' },
  { CallingCode: '32', text: 'Belgium', id: 'BE' },
  { CallingCode: '501', text: 'Belize', id: 'BZ' },
  { CallingCode: '229', text: 'Benin', id: 'BJ' },
  { CallingCode: '1441', text: 'Bermuda', id: 'BM' },
  { CallingCode: '975', text: 'Bhutan', id: 'BT' },
  { CallingCode: '591', text: 'Bolivia (Plurinational State of)', id: 'BO' },
  { CallingCode: '5997', text: 'Bonaire, Sint Eustatius and Saba', id: 'BQ' },
  { CallingCode: '387', text: 'Bosnia and Herzegovina', id: 'BA' },
  { CallingCode: '267', text: 'Botswana', id: 'BW' },
  { CallingCode: '47', text: 'Bouvet Island', id: 'BV' },
  { CallingCode: '55', text: 'Brazil', id: 'BR' },
  { CallingCode: '246', text: 'British Indian Ocean Territory', id: 'IO' },
  { CallingCode: '673', text: 'Brunei Darussalam', id: 'BN' },
  { CallingCode: '359', text: 'Bulgaria', id: 'BG' },
  { CallingCode: '226', text: 'Burkina Faso', id: 'BF' },
  { CallingCode: '257', text: 'Burundi', id: 'BI' },
  { CallingCode: '238', text: 'Cabo Verde', id: 'CV' },
  { CallingCode: '855', text: 'Cambodia', id: 'KH' },
  { CallingCode: '237', text: 'Cameroon', id: 'CM' },
  { CallingCode: '1', text: 'Canada', id: 'CA' },
  { CallingCode: '1345', text: 'Cayman Islands', id: 'KY' },
  { CallingCode: '236', text: 'Central African Republic', id: 'CF' },
  { CallingCode: '235', text: 'Chad', id: 'TD' },
  { CallingCode: '56', text: 'Chile', id: 'CL' },
  { CallingCode: '86', text: 'China', id: 'CN' },
  { CallingCode: '61', text: 'Christmas Island', id: 'CX' },
  { CallingCode: '61', text: 'Cocos (Keeling) Islands', id: 'CC' },
  { CallingCode: '57', text: 'Colombia', id: 'CO' },
  { CallingCode: '269', text: 'Comoros', id: 'KM' },
  { CallingCode: '242', text: 'Congo', id: 'CG' },
  { CallingCode: '243', text: 'Congo (Democratic Republic of the)', id: 'CD' },
  { CallingCode: '682', text: 'Cook Islands', id: 'CK' },
  { CallingCode: '506', text: 'Costa Rica', id: 'CR' },
  { CallingCode: '225', text: "Côte d'Ivoire", id: 'CI' },
  { CallingCode: '385', text: 'Croatia', id: 'HR' },
  { CallingCode: '53', text: 'Cuba', id: 'CU' },
  { CallingCode: '599', text: 'Curaçao', id: 'CW' },
  { CallingCode: '357', text: 'Cyprus', id: 'CY' },
  { CallingCode: '420', text: 'Czech Republic', id: 'CZ' },
  { CallingCode: '45', text: 'Denmark', id: 'DK' },
  { CallingCode: '253', text: 'Djibouti', id: 'DJ' },
  { CallingCode: '1767', text: 'Dominica', id: 'DM' },
  { CallingCode: '1809', text: 'Dominican Republic', id: 'DO' },
  { CallingCode: '593', text: 'Ecuador', id: 'EC' },
  { CallingCode: '20', text: 'Egypt', id: 'EG' },
  { CallingCode: '503', text: 'El Salvador', id: 'SV' },
  { CallingCode: '240', text: 'Equatorial Guinea', id: 'GQ' },
  { CallingCode: '291', text: 'Eritrea', id: 'ER' },
  { CallingCode: '372', text: 'Estonia', id: 'EE' },
  { CallingCode: '251', text: 'Ethiopia', id: 'ET' },
  { CallingCode: '500', text: 'Falkland Islands (Malvinas)', id: 'FK' },
  { CallingCode: '298', text: 'Faroe Islands', id: 'FO' },
  { CallingCode: '679', text: 'Fiji', id: 'FJ' },
  { CallingCode: '358', text: 'Finland', id: 'FI' },
  { CallingCode: '33', text: 'France', id: 'FR' },
  { CallingCode: '594', text: 'French Guiana', id: 'GF' },
  { CallingCode: '689', text: 'French Polynesia', id: 'PF' },
  { CallingCode: '262', text: 'French Southern Territories', id: 'TF' },
  { CallingCode: '241', text: 'Gabon', id: 'GA' },
  { CallingCode: '220', text: 'Gambia', id: 'GM' },
  { CallingCode: '995', text: 'Georgia', id: 'GE' },
  { CallingCode: '49', text: 'Germany', id: 'DE' },
  { CallingCode: '233', text: 'Ghana', id: 'GH' },
  { CallingCode: '350', text: 'Gibraltar', id: 'GI' },
  { CallingCode: '30', text: 'Greece', id: 'GR' },
  { CallingCode: '299', text: 'Greenland', id: 'GL' },
  { CallingCode: '1473', text: 'Grenada', id: 'GD' },
  { CallingCode: '590', text: 'Guadeloupe', id: 'GP' },
  { CallingCode: '1671', text: 'Guam', id: 'GU' },
  { CallingCode: '502', text: 'Guatemala', id: 'GT' },
  { CallingCode: '44', text: 'Guernsey', id: 'GG' },
  { CallingCode: '224', text: 'Guinea', id: 'GN' },
  { CallingCode: '245', text: 'Guinea-Bissau', id: 'GW' },
  { CallingCode: '592', text: 'Guyana', id: 'GY' },
  { CallingCode: '509', text: 'Haiti', id: 'HT' },
  { CallingCode: '672', text: 'Heard Island and McDonald Islands', id: 'HM' },
  { CallingCode: '379', text: 'Holy See', id: 'VA' },
  { CallingCode: '504', text: 'Honduras', id: 'HN' },
  { CallingCode: '852', text: 'Hong Kong', id: 'HK' },
  { CallingCode: '36', text: 'Hungary', id: 'HU' },
  { CallingCode: '354', text: 'Iceland', id: 'IS' },
  { CallingCode: '91', text: 'India', id: 'IN' },
  { CallingCode: '62', text: 'Indonesia', id: 'ID' },
  { CallingCode: '98', text: 'Iran (Islamic Republic of)', id: 'IR' },
  { CallingCode: '964', text: 'Iraq', id: 'IQ' },
  { CallingCode: '353', text: 'Ireland', id: 'IE' },
  { CallingCode: '44', text: 'Isle of Man', id: 'IM' },
  { CallingCode: '972', text: 'Israel', id: 'IL' },
  { CallingCode: '39', text: 'Italy', id: 'IT' },
  { CallingCode: '1876', text: 'Jamaica', id: 'JM' },
  { CallingCode: '81', text: 'Japan', id: 'JP' },
  { CallingCode: '44', text: 'Jersey', id: 'JE' },
  { CallingCode: '962', text: 'Jordan', id: 'JO' },
  { CallingCode: '7', text: 'Kazakhstan', id: 'KZ' },
  { CallingCode: '254', text: 'Kenya', id: 'KE' },
  { CallingCode: '686', text: 'Kiribati', id: 'KI' },
  { CallingCode: '850', text: "Korea (Democratic People's Republic of)", id: 'KP' },
  { CallingCode: '82', text: 'Korea (Republic of)', id: 'KR' },
  { CallingCode: '965', text: 'Kuwait', id: 'KW' },
  { CallingCode: '996', text: 'Kyrgyzstan', id: 'KG' },
  { CallingCode: '856', text: "Lao People's Democratic Republic", id: 'LA' },
  { CallingCode: '371', text: 'Latvia', id: 'LV' },
  { CallingCode: '961', text: 'Lebanon', id: 'LB' },
  { CallingCode: '266', text: 'Lesotho', id: 'LS' },
  { CallingCode: '231', text: 'Liberia', id: 'LR' },
  { CallingCode: '218', text: 'Libya', id: 'LY' },
  { CallingCode: '423', text: 'Liechtenstein', id: 'LI' },
  { CallingCode: '370', text: 'Lithuania', id: 'LT' },
  { CallingCode: '352', text: 'Luxembourg', id: 'LU' },
  { CallingCode: '853', text: 'Macao', id: 'MO' },
  { CallingCode: '389', text: 'North Macedonia', id: 'MK' },
  { CallingCode: '261', text: 'Madagascar', id: 'MG' },
  { CallingCode: '265', text: 'Malawi', id: 'MW' },
  { CallingCode: '60', text: 'Malaysia', id: 'MY' },
  { CallingCode: '960', text: 'Maldives', id: 'MV' },
  { CallingCode: '223', text: 'Mali', id: 'ML' },
  { CallingCode: '356', text: 'Malta', id: 'MT' },
  { CallingCode: '692', text: 'Marshall Islands', id: 'MH' },
  { CallingCode: '596', text: 'Martinique', id: 'MQ' },
  { CallingCode: '222', text: 'Mauritania', id: 'MR' },
  { CallingCode: '230', text: 'Mauritius', id: 'MU' },
  { CallingCode: '262', text: 'Mayotte', id: 'YT' },
  { CallingCode: '52', text: 'Mexico', id: 'MX' },
  { CallingCode: '691', text: 'Micronesia (Federated States of)', id: 'FM' },
  { CallingCode: '373', text: 'Moldova (Republic of)', id: 'MD' },
  { CallingCode: '377', text: 'Monaco', id: 'MC' },
  { CallingCode: '976', text: 'Mongolia', id: 'MN' },
  { CallingCode: '382', text: 'Montenegro', id: 'ME' },
  { CallingCode: '1664', text: 'Montserrat', id: 'MS' },
  { CallingCode: '212', text: 'Morocco', id: 'MA' },
  { CallingCode: '258', text: 'Mozambique', id: 'MZ' },
  { CallingCode: '95', text: 'Myanmar', id: 'MM' },
  { CallingCode: '264', text: 'Namibia', id: 'NA' },
  { CallingCode: '674', text: 'Nauru', id: 'NR' },
  { CallingCode: '977', text: 'Nepal', id: 'NP' },
  { CallingCode: '31', text: 'Netherlands', id: 'NL' },
  { CallingCode: '687', text: 'New Caledonia', id: 'NC' },
  { CallingCode: '64', text: 'New Zealand', id: 'NZ' },
  { CallingCode: '505', text: 'Nicaragua', id: 'NI' },
  { CallingCode: '227', text: 'Niger', id: 'NE' },
  { CallingCode: '234', text: 'Nigeria', id: 'NG' },
  { CallingCode: '683', text: 'Niue', id: 'NU' },
  { CallingCode: '672', text: 'Norfolk Island', id: 'NF' },
  { CallingCode: '1670', text: 'Northern Mariana Islands', id: 'MP' },
  { CallingCode: '47', text: 'Norway', id: 'NO' },
  { CallingCode: '968', text: 'Oman', id: 'OM' },
  { CallingCode: '92', text: 'Pakistan', id: 'PK' },
  { CallingCode: '680', text: 'Palau', id: 'PW' },
  { CallingCode: '970', text: 'Palestine, State of', id: 'PS' },
  { CallingCode: '507', text: 'Panama', id: 'PA' },
  { CallingCode: '675', text: 'Papua New Guinea', id: 'PG' },
  { CallingCode: '595', text: 'Paraguay', id: 'PY' },
  { CallingCode: '51', text: 'Peru', id: 'PE' },
  { CallingCode: '63', text: 'Philippines', id: 'PH' },
  { CallingCode: '64', text: 'Pitcairn', id: 'PN' },
  { CallingCode: '48', text: 'Poland', id: 'PL' },
  { CallingCode: '351', text: 'Portugal', id: 'PT' },
  { CallingCode: '1787', text: 'Puerto Rico', id: 'PR' },
  { CallingCode: '974', text: 'Qatar', id: 'QA' },
  { CallingCode: '262', text: 'Réunion', id: 'RE' },
  { CallingCode: '40', text: 'Romania', id: 'RO' },
  { CallingCode: '7', text: 'Russian Federation', id: 'RU' },
  { CallingCode: '250', text: 'Rwanda', id: 'RW' },
  { CallingCode: '590', text: 'Saint Barthélemy', id: 'BL' },
  { CallingCode: '290', text: 'Saint Helena, Ascension and Tristan da Cunha', id: 'SH' },
  { CallingCode: '1869', text: 'Saint Kitts and Nevis', id: 'KN' },
  { CallingCode: '1758', text: 'Saint Lucia', id: 'LC' },
  { CallingCode: '590', text: 'Saint Martin (French part)', id: 'MF' },
  { CallingCode: '508', text: 'Saint Pierre and Miquelon', id: 'PM' },
  { CallingCode: '1784', text: 'Saint Vincent and the Grenadines', id: 'VC' },
  { CallingCode: '685', text: 'Samoa', id: 'WS' },
  { CallingCode: '378', text: 'San Marino', id: 'SM' },
  { CallingCode: '239', text: 'Sao Tome and Principe', id: 'ST' },
  { CallingCode: '966', text: 'Saudi Arabia', id: 'SA' },
  { CallingCode: '221', text: 'Senegal', id: 'SN' },
  { CallingCode: '381', text: 'Serbia', id: 'RS' },
  { CallingCode: '248', text: 'Seychelles', id: 'SC' },
  { CallingCode: '232', text: 'Sierra Leone', id: 'SL' },
  { CallingCode: '65', text: 'Singapore', id: 'SG' },
  { CallingCode: '1721', text: 'Sint Maarten (Dutch part)', id: 'SX' },
  { CallingCode: '421', text: 'Slovakia', id: 'SK' },
  { CallingCode: '386', text: 'Slovenia', id: 'SI' },
  { CallingCode: '677', text: 'Solomon Islands', id: 'SB' },
  { CallingCode: '252', text: 'Somalia', id: 'SO' },
  { CallingCode: '27', text: 'South Africa', id: 'ZA' },
  { CallingCode: '500', text: 'South Georgia and the South Sandwich Islands', id: 'GS' },
  { CallingCode: '211', text: 'South Sudan', id: 'SS' },
  { CallingCode: '34', text: 'Spain', id: 'ES' },
  { CallingCode: '94', text: 'Sri Lanka', id: 'LK' },
  { CallingCode: '249', text: 'Sudan', id: 'SD' },
  { CallingCode: '597', text: 'Suriname', id: 'SR' },
  { CallingCode: '47', text: 'Svalbard and Jan Mayen', id: 'SJ' },
  { CallingCode: '268', text: 'Swaziland', id: 'SZ' },
  { CallingCode: '46', text: 'Sweden', id: 'SE' },
  { CallingCode: '41', text: 'Switzerland', id: 'CH' },
  { CallingCode: '963', text: 'Syrian Arab Republic', id: 'SY' },
  { CallingCode: '886', text: 'Taiwan', id: 'TW' },
  { CallingCode: '992', text: 'Tajikistan', id: 'TJ' },
  { CallingCode: '255', text: 'Tanzania, United Republic of', id: 'TZ' },
  { CallingCode: '66', text: 'Thailand', id: 'TH' },
  { CallingCode: '670', text: 'Timor-Leste', id: 'TL' },
  { CallingCode: '228', text: 'Togo', id: 'TG' },
  { CallingCode: '690', text: 'Tokelau', id: 'TK' },
  { CallingCode: '676', text: 'Tonga', id: 'TO' },
  { CallingCode: '1868', text: 'Trinidad and Tobago', id: 'TT' },
  { CallingCode: '216', text: 'Tunisia', id: 'TN' },
  { CallingCode: '90', text: 'Turkey', id: 'TR' },
  { CallingCode: '993', text: 'Turkmenistan', id: 'TM' },
  { CallingCode: '1649', text: 'Turks and Caicos Islands', id: 'TC' },
  { CallingCode: '688', text: 'Tuvalu', id: 'TV' },
  { CallingCode: '256', text: 'Uganda', id: 'UG' },
  { CallingCode: '380', text: 'Ukraine', id: 'UA' },
  { CallingCode: '971', text: 'United Arab Emirates', id: 'AE' },
  { CallingCode: '44', text: 'United Kingdom of Great Britain and Northern Ireland', id: 'GB' },
  { CallingCode: '1', text: 'United States of America', id: 'US' },
  { CallingCode: '598', text: 'Uruguay', id: 'UY' },
  { CallingCode: '998', text: 'Uzbekistan', id: 'UZ' },
  { CallingCode: '678', text: 'Vanuatu', id: 'VU' },
  { CallingCode: '58', text: 'Venezuela (Bolivarian Republic of)', id: 'VE' },
  { CallingCode: '84', text: 'Viet Nam', id: 'VN' },
  { CallingCode: '1284', text: 'Virgin Islands (British)', id: 'VG' },
  { CallingCode: '1340', text: 'Virgin Islands (U.S.)', id: 'VI' },
  { CallingCode: '681', text: 'Wallis and Futuna', id: 'WF' },
  { CallingCode: '212', text: 'Western Sahara', id: 'EH' },
  { CallingCode: '967', text: 'Yemen', id: 'YE' },
  { CallingCode: '260', text: 'Zambia', id: 'ZM' },
  { CallingCode: '263', text: 'Zimbabwe', id: 'ZW' },
]
