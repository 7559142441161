<script setup lang="ts">
interface Option {
  label: string
  value: string
}

interface Props {
  id: string
  label: string
  disabled?: boolean
  selected?: string
  modelValue?: string
  hasSelector?: boolean
  options?: Option[]
  maxlength?: string
  type?: string
  formatter?: (value: string) => string
  parser?: (value: string) => string
  placeholder?: string
  selectProps?: {
    placeholder?: string
    disabled?: boolean
    width?: string
  }
  prepend?: string
}

const props = withDefaults(defineProps<Props>(), {
  hasSelector: true,
  selected: '',
  modelValue: '',
  options: undefined,
  placeholder: '',
  selectProps: undefined,
  formatter: (value: string) => `${value}`.replace(/\D/g, ''),
  parser: undefined,
  maxlength: undefined,
  type: undefined,
  prepend: '',
})

const destructuredSelectProp = computed(() => {
  const { disabled, placeholder } = props.selectProps || {}

  return { disabled, placeholder }
})

defineEmits(['update:modelValue', 'update:selected'])
</script>

<template>
  <Typography
    :for="id"
    as="label"
    color="text-secondary"
    class="mb-2"
  >
    {{ label }}
  </Typography>
  <el-input
    :id="id"
    :disabled="disabled"
    :model-value="modelValue"
    :placeholder="placeholder"
    :maxlength="maxlength"
    size="large"
    class="selectInput"
    :formatter="formatter"
    :parser="parser"
    @input="$emit('update:modelValue', $event)"
  >
    <template
      v-if="hasSelector"
      #prepend
    >
      <Select
        v-bind="destructuredSelectProp"
        size="large"
        class="selectInput-select"
        :model-value="selected"
        :style="{ width: selectProps?.width || '88px' }"
        @change="$emit('update:selected', $event)"
      >
        <SelectOption
          v-for="option in options"
          :key="option.value"
          :label="option.label"
          :value="option.value"
        >
          {{ option.label }}

          <IconsCheckOutlined
            v-if="selectProps === option.value"
            class="text-success900 h-4 w-4"
          />
        </SelectOption>
      </Select>
    </template>
    <template
      v-if="prepend"
      #prefix
    >
      <span class="mr-3">{{ prepend }}</span>
    </template>
  </el-input>
</template>
<style>
.selectInput > .el-input__wrapper {
  --el-input-border-color: var(--gray10);
}

.selectInput > .el-input__wrapper {
  --el-input-border-radius: 8px;
}

.selectInput > .el-input__wrapper:not([class*='is-focus']):not(:hover) {
  box-shadow:
    -1px 0 0 0 var(--el-input-border-color) inset,
    0 1px 0 0 var(--el-input-border-color) inset,
    0 -1px 0 0 var(--el-input-border-color) inset;
}

.selectInput > .el-input-group__prepend {
  --el-input-border-color: var(--gray10);
  --el-fill-color-light: var(--light);
  --el-input-border-radius: 10px;
}

.selectInput-select.el-select {
  @apply bg-none border-none;
}

.selectInput-select.el-select .el-input__wrapper {
  @apply shadow-none;
}
</style>
