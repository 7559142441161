import { useMouse } from '@vueuse/core'
import { useUser } from '~/src/composables/apis/user'

export const isDev = process.env.NODE_ENV === 'development'

export const useActivityChecker = () => {
  const { x } = useMouse()
  const ACTIVITY_TIMEOUT = 30 * 60000 // 30 min
  let timer: number
  const startCounter = () => {
    timer = x.value
    setInterval(() => {
      if (timer === x.value) {
        // if (!isDev) {
        useUser().logOut()
        // }
      }
      timer = x.value
    }, ACTIVITY_TIMEOUT)
  }
  return { startCounter }
}

export const addQueryParam = (urlRef: any, param: any, value: any) => {
  if (value) {
    urlRef.value += `${param}=${value}&`
  }
}
