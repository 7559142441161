<template>
  <Modal
    modal="$atts.modal"
    :title="$t('accountCreation.createAccount')"
  >
    <div class="flex flex-col w-full h-full justify-between">
      <div class="flex items-center justify-center w-full" />
      <div class="flex w-full justify-end mt-6" />
      <transition
        name="slideUp"
        appear
      >
        <form
          class="w-full overflow-y-auto"
          @submit.prevent="create"
        >
          <div class="flex flex-col gap-6">
            <div class="field">
              <label
                for="bvn"
                class="label"
              >
                {{ $t('accountCreation.selectCustomer') }}
              </label>
              <SelectMenu
                id="code"
                v-model="credential.customer_id.value"
                :options="customersIdData"
                name="code"
                :loading="CustomerLoading"
                required
              />
            </div>
            <div class="field">
              <label
                for="bvn"
                class="label"
              >
                {{ $t('accountCreation.selectCurrency') }}
              </label>
              <SelectMenu
                id="currency"
                v-model="credential.currency.value"
                :options="currencies"
                name="currency"
                :loading="currencyLoading"
                required
              />
            </div>
          </div>

          <button
            class="btn bg-primary text-white px-7 min-w-[140px] mt-5 text-sm !py-3 ml-auto"
            :disabled="loading || disabled"
          >
            <span v-if="!loading"> {{ $t('submit') }}</span> <Spinner v-else />
          </button>
        </form>
      </transition>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import { createAccount } from '~/src/composables/apis/account'
import { getCurrencies } from '~/src/composables/apis/institution'
import { getAllCustomers } from '~/src/composables/apis/customer'

const { create, credential, loading, disabled } = createAccount()
const { currencies, fetch: fetchCurrencies, loading: currencyLoading } = getCurrencies()
const { customersIdData, fetchCustomers, loading: CustomerLoading } = getAllCustomers()

onMounted(() => {
  fetchCurrencies()
  fetchCustomers()
})
</script>

<style scoped>
.slideUp-enter {
  opacity: 0;
  /* transform: translateY(200px); */
}
.slideUp-leave-to {
  opacity: 0;
  /* transform: translateY(-200px); */
}

.slideUp-enter-active,
.slideUp-leave-active {
  transition: all 0.35s ease;
}
</style>
