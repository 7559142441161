<template>
  <Modal
    modal="$atts.modal"
    :title="$t('customer.upgradeTier', { number: 1 })"
  >
    <div class="flex flex-col w-full h-full justify-between">
      <form
        class="w-full overflow-y-auto mt-6"
        @submit.prevent="create"
      >
        <div class="flex flex-col gap-6">
          <FormControl :label="$t('dob')">
            <DatePicker
              v-model="credential.dob.value"
              size="large"
              class="!w-full"
              prefix-icon=""
              required
            />
          </FormControl>

          <div class="grid grid-cols-2 gap-4">
            <FormControl :label="$t('country')">
              <Select
                v-model="credential.address.country.value"
                class="w-full"
                size="large"
                required
                filterable
                :loading="countryLoading"
              >
                <SelectOption
                  v-for="item in countries"
                  :key="item.id"
                  :label="item.text"
                  :value="item.id"
                >
                  {{ item.text }}

                  <IconsCheckOutlined
                    v-if="credential.address.country.value === item.id"
                    class="text-success900 h-4 w-4"
                  />
                </SelectOption>
              </Select>
            </FormControl>

            <FormControl
              :label="$t('state')"
              class="flex-1"
            >
              <Select
                v-model="credential.address.state.value"
                filterable
                class="w-full"
                size="large"
                required
                :loading="loadingStates.states"
              >
                <SelectOption
                  v-for="item in currentStates"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value"
                >
                  <span class="inline-flex justify-between items-center w-full">
                    {{ item.label }}

                    <IconsCheckOutlined
                      v-if="credential.address.state.value === item.value"
                      class="text-success900 h-4 w-4"
                    />
                  </span>
                </SelectOption>
              </Select>
            </FormControl>
          </div>

          <FormControl :label="$t('street')">
            <GMapAutocomplete
              ref="autocomplete"
              :value="credential.address.street.value"
              required
              placeholder=""
              class="py-px px-4 h-10 border border-gray10 rounded-lg w-full focus:outline-none focus:border-primary transition duration-300 focus:shadow-1xl"
              @place_changed="setPlace"
            />
          </FormControl>

          <div class="grid grid-cols-2 gap-4">
            <FormControl :label="$t('city')">
              <InputField
                required
                v-model="credential.address.city.value"
              />
            </FormControl>
            <FormControl :label="$t('customer.postalCode')">
              <InputField
                required
                v-model="credential.address.postal_code.value"
              />
            </FormControl>
          </div>

          <FormControl :label="credential.address.country.value === 'NG' ? 'BVN' : identificationNumber">
            <InputField
              v-model="credential.identification_number.value"
              :maxlength="credential.address.country.value === 'NG' ? 11 : undefined"
              :formatter="(value: string) => `${value}`.replace(/\D/g, '')"
              required
            />
          </FormControl>

          <FormControl
            for="phoneNumber"
            :label="$t('wallets.mobileNumber')"
          >
            <InputFieldPhone
              id="phoneNumber"
              v-model="credential.phone_number.value"
            />
          </FormControl>

          <Button
            class="w-full mt-3"
            size="large"
            :disabled="!credential.dob.value || credential.phone_number.value.length < MAX_PHONE_LENGTH"
          >
            <span v-if="!loading"> {{ $t('customer.upgrade') }}</span> <Spinner v-else />
          </Button>
        </div>
      </form>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import { createTier1 } from '~/src/composables/apis/customer/tiersCreation'
import { getCountries, useCountries } from '~/src/composables/apis/extras/country'
import { useAlert } from '~/src/composables/core/useNotification'
import { MAX_PHONE_LENGTH } from '~/src/types/internals'

const { countries, fetch, loading: countryLoading } = getCountries()
const { getStateByISo, states, loading: loadingStates } = useCountries()
const { credential, create, loading } = createTier1()

const { t } = useI18n()

const identificationNumber = t('customer.identificationNumber')

const autocomplete = ref<{ $el: HTMLInputElement } | null>(null)

const mapping: Record<string, keyof typeof credential.address> = {
  route: 'street',
  locality: 'city',
  postal_code: 'postal_code',
  administrative_area_level_1: 'state',
  country: 'country',
}

const isCountrySupported = (iso2: string) => {
  return countries.value.some((country) => country.id === iso2)
}

const currentStates = computed(() => {
  return credential.address.country.value && states.value?.[credential.address.country.value]
    ? states.value[credential.address.country.value].map(({ iso2, name }) => ({
        value: iso2,
        label: name,
      }))
    : []
})

const setPlace = (address: any) => {
  resetAddress()

  const selectedCountry = address.address_components.find((component: any) => {
    return component.types.find((type: string) => mapping[type] === mapping.country)
  })

  if (!isCountrySupported(selectedCountry.short_name)) {
    // TODO: find an alternative for restricting google place for more than 5 countries
    useAlert().openAlert({ type: 'ERROR', msg: 'Country not supported' })
    return
  }

  address.address_components.forEach((component: any) => {
    component.types.forEach((type: string) => {
      if (mapping[type]) {
        const value = mapping[type] === 'city' ? component.long_name : component.short_name
        credential.address[mapping[type]].value = value
      }
    })
  })
}

const resetAddress = () => {
  credential.address.street.value = ''
  credential.address.postal_code.value = ''
  credential.address.city.value = ''
  credential.address.state.value = ''
}

const updateStreet = (event: Event) => {
  resetAddress()
  credential.address.street.value = (event.target as HTMLInputElement).value
}

onMounted(() => {
  if (autocomplete.value?.$el) {
    autocomplete.value?.$el.addEventListener('input', updateStreet)
  }

  credential.address.country.value && getStateByISo(credential.address.country.value)
})

onBeforeUnmount(() => {
  autocomplete.value?.$el.removeEventListener('input', updateStreet)
})

watch(credential.address.country, (value) => {
  value && getStateByISo(value)
})

fetch()
</script>

<style scoped>
.slideUp-enter,
.slideUp-leave-to {
  opacity: 0;
  transform: translateY(-200px);
}

.slideUp-enter-active,
.slideUp-leave-active {
  transition: all 0.35s;
}
</style>
