import { AxiosResponse } from 'axios'
import Recase from 'better-recase'
import { GATEWAY_ENDPOINT } from './SetupBase'
import { WalletResponse } from './requests/wallet/types'

export const walletApi = {
  $_fundWallet: (credential: any) => {
    const url = '/test/wallet/credit'
    return GATEWAY_ENDPOINT.post(url, credential)
  },
  $_getWallet: (): Promise<AxiosResponse<WalletResponse>> | 'ERROR' => {
    const url = '/wallets'
    return GATEWAY_ENDPOINT.get(url, {
      transformResponse: (data: string) => Recase.camelCopy(JSON.parse(data)),
    })
  },
  $_getWalletHistories: () => {
    const url = '/wallets/history'
    return GATEWAY_ENDPOINT.get(url)
  },
  $_getWalletHistoriesByCurrency: (id: string) => {
    const url = `/wallets/${id}/history`
    return GATEWAY_ENDPOINT.get(url)
  },
  $_getByCurrency: (id: string) => {
    const url = `/wallets/${id}`
    return GATEWAY_ENDPOINT.get(url)
  },
  $_getWalletStatement: (credential: any) => {
    const url = `/statement/${credential.id}/wallet?start_date=${credential.start}&end_date=${credential.end}`
    return GATEWAY_ENDPOINT.get(url)
  },
  $_editLimit: (currency: string, crediential: any) => {
    const url = `/wallets/${currency}/mini-balance`
    return GATEWAY_ENDPOINT.patch(url, crediential)
  },
}
