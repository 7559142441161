<template>
  <form
    class="w-full overflow-y-auto"
    @submit.prevent="handleSubmit"
  >
    <div class="flex flex-col gap-6">
      <FormControl :label="$t('wallets.selectCountry')">
        <Select
          v-model="state.credential.country"
          filterable
          class="w-full"
          size="large"
        >
          <SelectOption
            v-for="item in countriesCode"
            :key="item.id"
            :label="item.text"
            :value="item.id"
          >
            {{ item.text }}

            <IconsCheckOutlined
              v-if="state.credential.country === item.id"
              class="text-success900 h-4 w-4"
            />
          </SelectOption>
        </Select>
      </FormControl>

      <FormControl :label="$t('selectBank')">
        <Select
          v-model="state.credential.bank_code"
          placeholder="Select Bank"
          class="w-full"
          size="large"
          filterable
          required
          :loading="bankLoading"
        >
          <SelectOption
            v-for="item in banks"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          >
            {{ item.name }}

            <IconsCheckOutlined
              v-if="state.credential.bank_code === item.id"
              class="text-success900 h-4 w-4"
            />
          </SelectOption>
        </Select>
      </FormControl>

      <FormControl>
        <template #label>
          <label
            for="account_number"
            class="flex w-full label"
          >
            {{ $t('transactions.accountNumber') }}
            <span
              class="ml-auto"
              :class="[error ? 'text-red' : 'text-secondary']"
              >{{ resolved || error }}</span
            >
          </label>
        </template>
        <div class="relative w-full">
          <InputField
            v-model="state.credential.account_number"
            :formatter="(value: string) => `${value}`.replace(/\D/g, '')"
            required
            :disabled="!Object.keys(state.credential.bank_code).length || AccLoading"
          />
          <Spinner
            v-if="AccLoading"
            class="absolute !border-gray950 !border-t-black top-1.5 right-4"
          />
        </div>
      </FormControl>

      <FormControl>
        <CurrencyInput
          id="fund-card"
          v-model="state.credential.amount"
          :selected="state.credential.country"
          :select-props="{ disabled: true }"
          :label="$t('amount')"
          placeholder="0.00"
          :options="CURRENCY_CODE"
        />
      </FormControl>

      <template v-if="state.credential.country !== CurrencyCode.NGN">
        <FormControl :label="$t('transfer.receiversName')">
          <InputField
            v-model="state.credential.meta.counterparty.name"
            required
          />
        </FormControl>
        <FormControl :label="$t('transfer.receiversPhoneNumber')">
          <InputFieldPhone
            id="phone"
            v-model="state.credential.meta.counterparty.phone_number"
            :iso="country?.iso"
          />
        </FormControl>
      </template>

      <FormControl :label="$t('reason')">
        <InputField v-model="state.credential.reason" />
      </FormControl>

      <Button
        size="large"
        :disabled="NairaTransferLoading || disabled"
      >
        <span v-if="!NairaTransferLoading"> {{ isNairaTransfer ? submit : next }}</span> <Spinner v-else />
      </Button>
    </div>
  </form>
</template>
<script lang="ts" setup>
import { onMounted } from 'vue'
import { createBankTransfer } from '~/src/composables/apis/transfers/bankTransfer'
import { countriesCode } from '~/src/constants/countriesCode'
import { CurrencyCode, Institution } from '~/src/types/internals'
import { getBanks } from '~/src/composables/apis/institution'
import { confirmTransfer } from '~/src/composables/apis/transfers'
import { CURRENCY_CODE } from '~/src/constants/index'

const { step, state, create, NairaTransferLoading, AccLoading, error, resolved, disabled, bankLoading } =
  createBankTransfer()
const { banks, fetch: fetchBanks } = getBanks()

const { setFunction } = confirmTransfer()

const isNairaTransfer = computed(() => step.value === 1 && state.credential.country === CurrencyCode.NGN)

const country = computed(() => countriesCode.find((country) => country.id === state.credential.country))

const handleSubmit = () => {
  if (isNairaTransfer.value) {
    setFunction(create, state.credential)
    return
  }

  step.value = step.value + 1
}

const { t } = useI18n()

const submit = t('submit')
const next = t('next')

onMounted(async () => {
  if (!state.credential.country) {
    state.credential.country = CurrencyCode.NGN
    await fetchBanks(Institution.NUBAN)
  }
})
</script>
