import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js'
import { array, boolean, mixed, number, object, string } from 'yup'
import { BVN_MAX_LENGTH } from '~/src/constants'
import { CountryIso, EmploymentStatus, JOB_TYPE, UsResidencyStatus } from '~/src/types/internals'

const directorStepOne = {
  email: string().email().required(),
  firstName: string().required().label('first name'),
  lastName: string().required().label('last name'),
  political: boolean().required(),
  meta: object()
    .shape({
      percentage: number().min(0.1).max(100).required().label('percentage'),
      occupation: mixed<JOB_TYPE>().oneOf(Object.values(JOB_TYPE)).label('occupation').required().label('occupation'),
      employmentDescription: string()
        .when('occupation', {
          is: (occupation: string) => occupation === JOB_TYPE.Other,
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.notRequired(),
        })
        .label('job title'),
    })
    .required(),
}

const directorStepTwo = {
  phoneNumber: string()
    .when('phoneNumber', {
      is: (value: string) => Boolean(value),
      then: (schema) =>
        schema.test('is-valid-phone-number', 'Phone number does not match country selected', function (value) {
          return value ? isValidPhoneNumber(value, parsePhoneNumber(value).country) : true
        }),
    })
    .nullable(),
  completed: boolean().nullable(),
  bvn: string().nullable(),
  dob: string().nullable(),
  nationality: string().nullable(),
  address: string().nullable(),
  apartment: string().nullable(),
  state: string().nullable(),
  city: string().nullable(),
  postalCode: string().nullable(),
  idCard: string().label('proof of identification').nullable(),
  proofOfAddress: string().label('proof of address').nullable(),
  meta: object({
    identificationNumber: string().label('nin').nullable(),
  }).nullable(),
}

const externalDirector = {
  dob: string().required(),
  nationality: string().required(),
  address: string().required(),
  apartment: string(),
  state: string().required(),
  city: string().required(),
  completed: boolean().nullable(),
  postalCode: string().required(),
  idCard: string().required().label('proof of identification'),
  proofOfAddress: string().required().label('proof of address'),
  meta: object({
    identificationNumber: string().when('$nationality', ([nationality], schema) => {
      return nationality === CountryIso.NGN
        ? schema.required().test('length', 'Must be exactly 11 characters', (val) => val.length === BVN_MAX_LENGTH)
        : schema
    }),
  }),
  bvn: string().when('nationality', {
    is: (nationality: string) => nationality === CountryIso.NGN,
    then: (schema) =>
      schema.required().test('length', 'Must be exactly 11 characters', (val) => val.length === BVN_MAX_LENGTH),
    otherwise: (schema) => schema.notRequired(),
  }),
  phoneNumber: string()
    .required()
    .test('is-valid-phone-number', 'Phone number does not match country selected', function (value) {
      return value ? isValidPhoneNumber(value, parsePhoneNumber(value).country) : false
    }),
}

const directorId = {
  id: string().required(),
}

const newDirector = {
  email: string().email().required(),
  firstName: string().required().label('first name'),
  lastName: string().required().label('last name'),
  political: boolean().required(),
  dob: string().required(),
  completed: boolean().required(),
  postalCode: string().required(),
  bvn: string().when('nationality', {
    is: (nationality: string) => nationality === CountryIso.NGN,
    then: (schema) =>
      schema.required().test('length', 'Must be exactly 11 characters', (val) => val.length === BVN_MAX_LENGTH),
    otherwise: (schema) => schema.notRequired(),
  }),
  nationality: string().required(),
  address: string().required(),
  apartment: string(),
  state: string().required(),
  city: string().required(),
  idCard: string().required().label('proof of identification'),
  proofOfAddress: string().required().label('proof of address'),
  phoneNumber: string()
    .required()
    .test('is-valid-phone-number', 'Phone number does not match country selected', function (value) {
      return value ? isValidPhoneNumber(value, parsePhoneNumber(value).country) : false
    }),
  meta: object()
    .shape({
      percentage: number().min(0.1).max(100).required(),
      occupation: mixed<JOB_TYPE>().oneOf(Object.values(JOB_TYPE)).label('occupation').required(),
      passportNumber: string().nullable(),
      usResidencyStatus: mixed<UsResidencyStatus>().oneOf(Object.values(UsResidencyStatus)).nullable(),
      employmentStatus: mixed<EmploymentStatus>().oneOf(Object.values(EmploymentStatus)).nullable(),
      employerName: string().nullable(),
      proofOfFunds: string().nullable(),
      passportImage: string().nullable(),
      identificationNumber: string().when('$nationality', ([nationality], schema) => {
        return nationality === CountryIso.NGN
          ? schema.required().test('length', 'Must be exactly 11 characters', (val) => val.length === BVN_MAX_LENGTH)
          : schema
      }),
      employmentDescription: string().when('occupation', {
        is: (occupation: string) => occupation === JOB_TYPE.Other,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.notRequired(),
      }),
    })
    .required(),
}

export const newDirectorSchema = object(newDirector)
export const directorStepOneSchema = object(directorStepOne)
// export const externalDirectorSchema = object(externalDirector)
export const directorSchema = object().shape(directorId).concat(object(newDirector))
export const externalDirectorSchema = object(externalDirector)
export const directorStepTwoSchema = object().shape(directorStepTwo, [['phoneNumber', 'phoneNumber']])

export const directorsListSchema = object({
  directors: array().of(directorSchema.required()).min(1).required('Add at least one director'),
})
