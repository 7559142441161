<script lang="ts" setup>
import { createMapleradTransfer } from '~/src/composables/apis/transfers/mapleradTransfer'
import { getCurrencies } from '~/src/composables/apis/institution'
import { confirmTransfer } from '~/src/composables/apis/transfers'

const { setFunction } = confirmTransfer()
const { currencies, fetch: fetchCurrencies, loading: currencyLoading } = getCurrencies()
const { create, credential, mapleradTransferLoading, AccLoading, error, disabled, resolved, fetchBanks, bankLoading } =
  createMapleradTransfer()

onMounted(() => {
  fetchBanks('WALLET')
  fetchCurrencies()
})
</script>

<template>
  <Modal
    modal="$atts.modal"
    :title="$t('transactions.mapleradTransfer')"
  >
    <p
      v-if="error"
      class="text-red mt-4 font-medium"
    >
      {{ error }}
    </p>
    <div class="flex flex-col w-full h-full justify-between">
      <div class="flex items-center justify-center w-full" />
      <div class="flex w-full justify-end mt-6" />
      <transition
        name="slideUp"
        appear
      >
        <form
          class="w-full overflow-y-auto"
          @submit.prevent="setFunction(create, credential)"
        >
          <div class="flex flex-col gap-6">
            <FormControl>
              <template #label>
                <label
                  for="bvn"
                  class="label w-full flex"
                >
                  {{ $t('transactions.accountNumber') }}
                  <span class="ml-auto text-secondary">{{ resolved }}</span>
                </label>
              </template>
              <div class="relative w-full">
                <InputField
                  v-model="credential.account_number.value"
                  :disabled="!credential.bank_code.value || bankLoading"
                  required
                />

                <Spinner
                  v-if="AccLoading"
                  class="absolute !border-gray950 !border-t-black top-1.5 right-4"
                />
              </div>
            </FormControl>

            <FormControl>
              <CurrencyInput
                id="fund-card"
                v-model="credential.amount.value"
                v-model:selected="credential.currency.value"
                :select-props="{ loading: currencyLoading }"
                :label="$t('amount')"
                placeholder="0.00"
                :options="currencies.map((item: any) => ({ label: item.text, value: item.id }))"
              />
            </FormControl>

            <FormControl :label="$t('reason')">
              <InputField v-model="credential.reason.value" />
            </FormControl>

            <button
              class="btn-primary mt-3"
              :disabled="mapleradTransferLoading || disabled"
            >
              <span v-if="!mapleradTransferLoading"> {{ $t('submit') }}</span> <Spinner v-else />
            </button>
          </div>
        </form>
      </transition>
    </div>
  </Modal>
</template>

<style scoped>
.slideUp-enter {
  opacity: 0;
  /* transform: translateY(200px); */
}
.slideUp-leave-to {
  opacity: 0;
  /* transform: translateY(-200px); */
}

.slideUp-enter-active,
.slideUp-leave-active {
  transition: all 0.35s ease;
}
</style>
