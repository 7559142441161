import { array, number, object, string } from 'yup'

const shareholder = {
  id: string(),
  firstName: string().required().label('First name'),
  lastName: string().required().label('Last name'),
  sharePercentage: number()
    .required()
    .test('max-number', 'Enter a number less than 100%', (value) => value <= 100)
    .label('Share percentage'),
  nationality: string().required().label('Nationality'),
  address: string().required().label('Address'),
  idCard: string().required().label('Proof of identification'),
  proofOfAddress: string().required().label('Proof of address'),
}

export const shareholderSchema = object(shareholder)

export const shareholdersListSchema = object({
  shareCapital: number().required().min(1, 'Specify initial Share capital').label('Share Capital'),
  shareholder: array().of(object().shape(shareholder).required()).min(1).required('Add at least one shareholder'),
})
