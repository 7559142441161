import { ROUTES_NAMES } from '../routes'
import { BusinessRegisterationType } from '../types/api'
import {
  IdentificationType,
  CardBrand,
  EmploymentStatus,
  ComplianceRoutes,
  UsResidencyStatus,
  ProofOfAddress,
  CurrencyCode,
  JOB_TYPE,
  RELATIONSHIP,
  TransactionChannel,
  ACCOUNT_TYPE,
  COMPLIANCE_STEPS_ENUM,
  USD_TRANSFER_STEPS_ENUM,
  STASH_CREATION_STEPS_ENUM,
  COLLECTION_ENUM,
} from '../types/internals'

export const DATE_FILTER_OPTIONS = [
  { label: 'allTime' },
  { label: 'last30Days' },
  // { label: 'custom' }, TODO: uncomment when custom date filter is implemented, currently have issues with element plus teleport https://github.com/element-plus/element-plus/issues/1266
  { label: 'thisMonth' },
  { label: 'lastMonth' },
  { label: 'thisQuarter' },
  { label: 'lastQuarter' },
  { label: 'threeQuartersAgo' },
  { label: 'twoQuartersAgo' },
  { label: 'lastSixMonths' },
  { label: 'thisYear' },
  { label: 'lastYear' },
]

export const CURRENCY_CODE = [
  { value: CurrencyCode.NGN, label: CurrencyCode.NGN },
  { value: CurrencyCode.USD, label: CurrencyCode.USD },
  { value: CurrencyCode.KES, label: CurrencyCode.KES },
  { value: CurrencyCode.GHS, label: CurrencyCode.USD },
  { value: CurrencyCode.XAF, label: CurrencyCode.USD },
]
export const OTP_STAGING_VALUE = '123456'

export const RESIDENCY_STATUS = [
  { value: UsResidencyStatus.NON_RESIDENT_ALIEN, label: 'wallets.nonResidentAlien' },
  { value: UsResidencyStatus.RESIDENT_ALIEN, label: 'wallets.residentAlien' },
  { value: UsResidencyStatus.US_CITIZEN, label: 'wallets.usCitizen' },
]

export const JOB_TITLE = [
  { value: JOB_TYPE.CEO, label: JOB_TYPE.CEO },
  { value: JOB_TYPE.CTO, label: JOB_TYPE.CTO },
  { value: JOB_TYPE.CFO, label: JOB_TYPE.CFO },
  { value: JOB_TYPE.COO, label: JOB_TYPE.COO },
  { value: JOB_TYPE.Founder, label: JOB_TYPE.Founder },
  { value: JOB_TYPE.President, label: JOB_TYPE.President },
  { value: JOB_TYPE.GeneralPartner, label: JOB_TYPE.GeneralPartner },
  { value: JOB_TYPE.FinanceTeam, label: JOB_TYPE.FinanceTeam },
  { value: JOB_TYPE.Other, label: JOB_TYPE.Other },
]

export const RELATIONSHIP_TYPES = [
  { value: RELATIONSHIP.SPOUSE, label: 'wallets.usd.spouse' },
  { value: RELATIONSHIP.EX_SPOUSE, label: 'wallets.usd.exSpouse' },
  { value: RELATIONSHIP.CHILDREN, label: 'wallets.usd.children' },
  { value: RELATIONSHIP.PARENT, label: 'wallets.usd.parent' },
  { value: RELATIONSHIP.SIBLING, label: 'wallets.usd.sibling' },
  { value: RELATIONSHIP.RELATIVE, label: 'wallets.usd.relative' },
  { value: RELATIONSHIP.SELF, label: 'wallets.usd.self' },
  { value: RELATIONSHIP.FRIEND, label: 'wallets.usd.friend' },
  { value: RELATIONSHIP.BUSINESS_PARTNER, label: 'wallets.usd.businessPartner' },
  { value: RELATIONSHIP.CUSTOMER, label: 'wallets.usd.customer' },
  { value: RELATIONSHIP.EMPLOYEE, label: 'wallets.usd.employee' },
  { value: RELATIONSHIP.BRANCH_OFFICE, label: 'wallets.usd.branchOffice' },
  { value: RELATIONSHIP.SUBSIDIARY_COMPANY, label: 'wallets.usd.subsidiaryCompany' },
  { value: RELATIONSHIP.HOLDING_COMPANY, label: 'wallets.usd.holdingCompany' },
  { value: RELATIONSHIP.SUPPLIER, label: 'wallets.usd.supplier' },
  { value: RELATIONSHIP.CREDITOR, label: 'wallets.usd.creditor' },
  { value: RELATIONSHIP.DEBTOR, label: 'wallets.usd.debtor' },
  { value: RELATIONSHIP.FRANCHISEE, label: 'wallets.usd.franchisee' },
  { value: RELATIONSHIP.NOT_RELATED, label: 'wallets.usd.notRelated' },
]

export const TRANSACTION_CHANNEL_OPTIONS = [
  { label: TransactionChannel.ACH, value: TransactionChannel.ACH, text: 'wallets.usd.threeDays' },
  { label: 'wallets.usd.wire', value: TransactionChannel.FEDWIRE, text: 'wallets.usd.oneDay' },
  { label: 'wallets.usd.internationalWire', value: TransactionChannel.SWIFT, text: 'wallets.usd.sevenToTenDays' },
]

export const ACCOUNT_TYPE_OPTIONS = [
  { label: ACCOUNT_TYPE.SAVING, value: ACCOUNT_TYPE.SAVING },
  { label: ACCOUNT_TYPE.CHECKING, value: ACCOUNT_TYPE.CHECKING },
]

export const ADDRESS_TYPE = [
  { value: ProofOfAddress.LEASE_AGREEMENT, label: 'wallets.leaseAgreement' },
  { value: ProofOfAddress.UTILITY_BILL, label: 'wallets.utilityBill' },
]

export const EMPLOYEE_STATUS = [
  { value: EmploymentStatus.EMPLOYEE, label: 'wallets.employee' },
  { value: EmploymentStatus.SELF_EMPLOYED, label: 'wallets.selfEmployed' },
  { value: EmploymentStatus.UNEMPLOYED, label: 'wallets.unemployed' },
  { value: EmploymentStatus.OTHER, label: 'other' },
]

export const CARD_BRANDS = [
  { name: CardBrand.VISA, id: CardBrand.VISA },
  { name: CardBrand.MASTERCARD, id: CardBrand.MASTERCARD },
]

export const IDENTIFICATION_OPTIONS = [
  { id: IdentificationType.NationalId, text: 'National Id' },
  { id: IdentificationType.NationalPassport, text: 'Passport' },
]

export const MAX_OTP_ATTEMPTS = 3

export const headerHeight = 63

export const dashboardPaddingTop = 16

export const settingsHeader = 152

export const totalHeaderHeight = headerHeight + dashboardPaddingTop

export const PAGINATION_LIMIT = 10

export const BUSINESS_REGISTERATION_TYPE = [
  {
    label: 'kyc.limitedLiabilityCompany',
    value: BusinessRegisterationType.Limited,
  },
  {
    label: 'kyc.businessNamePartnership',
    value: BusinessRegisterationType.SoleProprietor,
  },
  {
    label: 'kyc.incorporatedTrustee',
    value: BusinessRegisterationType.Trustee,
  },
]

export const COMPLIANCE_STEPS = [
  {
    label: COMPLIANCE_STEPS_ENUM.CompanyInfo,
    link: { name: ComplianceRoutes.index },
  },
  {
    label: COMPLIANCE_STEPS_ENUM.CompanyAddress,
    link: { name: ComplianceRoutes.address },
  },
  {
    label: COMPLIANCE_STEPS_ENUM.OwnershipDetails,
    link: { name: ComplianceRoutes.ownership },
  },
  {
    label: COMPLIANCE_STEPS_ENUM.CompanyDocument,
    link: { name: ComplianceRoutes.documents },
  },
  {
    label: COMPLIANCE_STEPS_ENUM.Review,
    link: { name: ComplianceRoutes.review },
  },
]

export const COLLECTION_STEPS = [
  {
    label: COLLECTION_ENUM.Recipient,
    link: { name: ROUTES_NAMES.collectionReceiveFundsCurrency },
  },
  {
    label: COLLECTION_ENUM.Amount,
    link: { name: ROUTES_NAMES.collectionReceiveFundsCurrencyAmount },
  },
  {
    label: COLLECTION_ENUM.AdditionalInfo,
    link: { name: ROUTES_NAMES.collectionReceiveFundsCurrencyAdditionalInfo },
  },
  {
    label: COLLECTION_ENUM.Review,
    link: { name: ROUTES_NAMES.collectionReceiveFundsCurrencyReview },
  },
]

export const USD_TRANSFER_STEPS = [
  {
    label: USD_TRANSFER_STEPS_ENUM.Recipient,
    link: { name: ROUTES_NAMES.transferUsdId },
  },
  {
    label: USD_TRANSFER_STEPS_ENUM.PaymentMethod,
    link: { name: ROUTES_NAMES.transferUsdIdPaymentMethod },
  },
  {
    label: USD_TRANSFER_STEPS_ENUM.Amount,
    link: { name: ROUTES_NAMES.transferUsdIdAmount },
  },
  {
    label: USD_TRANSFER_STEPS_ENUM.AdditionalInfo,
    link: { name: ROUTES_NAMES.transferUsdIdAdditionalInfo },
  },
  {
    label: USD_TRANSFER_STEPS_ENUM.Review,
    link: { name: ROUTES_NAMES.transferUsdIdReview },
  },
]

export const STASH_CREATION_STEPS = [
  {
    label: STASH_CREATION_STEPS_ENUM.CreateStash,
    link: { name: ROUTES_NAMES.stashCreate },
  },
  {
    label: STASH_CREATION_STEPS_ENUM.AmountAndDuration,
    link: { name: ROUTES_NAMES.stashDuration },
  },
  {
    label: STASH_CREATION_STEPS_ENUM.ReviewPlan,
    link: { name: ROUTES_NAMES.stashReview },
  },
]

export const STASH_CREATION_STAGES = {
  [ROUTES_NAMES.stashCreate]: {
    stepValue: 1,
    stageValue: 1,
  },
  [ROUTES_NAMES.stashDuration]: {
    stepValue: 2,
    stageValue: 2,
  },
  [ROUTES_NAMES.stashReview]: {
    stepValue: 3,
    stageValue: 3,
  },
}

export const USD_TRANSFER_STAGES = {
  [ROUTES_NAMES.transferUsdId]: {
    stepValue: 1,
    stageValue: 1,
  },
  [ROUTES_NAMES.transferUsdIdPaymentMethod]: {
    stepValue: 2,
    stageValue: 2,
  },
  [ROUTES_NAMES.transferUsdIdAmount]: {
    stepValue: 3,
    stageValue: 3,
  },
  [ROUTES_NAMES.transferUsdIdAdditionalInfo]: {
    stepValue: 4,
    stageValue: 4,
  },
  [ROUTES_NAMES.transferUsdIdReview]: {
    stepValue: 5,
    stageValue: 5,
  },
}

export const COLLECTION_STAGES = {
  [ROUTES_NAMES.collectionReceiveFundsCurrency]: {
    stepValue: 1,
    stageValue: 1,
  },
  [ROUTES_NAMES.collectionReceiveFundsCurrencyAmount]: {
    stepValue: 2,
    stageValue: 2,
  },
  [ROUTES_NAMES.collectionReceiveFundsCurrencyAdditionalInfo]: {
    stepValue: 3,
    stageValue: 3,
  },
  [ROUTES_NAMES.collectionReceiveFundsCurrencyReview]: {
    stepValue: 4,
    stageValue: 4,
  },
}

export const COMPLIANCE_STAGES = {
  [ComplianceRoutes.index]: {
    stepValue: 1,
    stageValue: 1,
  },
  [ComplianceRoutes.address]: {
    stepValue: 2,
    stageValue: 2,
  },
  [ComplianceRoutes.ownership]: {
    stepValue: 3,
    stageValue: 3,
  },
  [ComplianceRoutes.documents]: {
    stepValue: 4,
    stageValue: 4,
  },
  [ComplianceRoutes.review]: {
    stepValue: 5,
    stageValue: 5,
  },
  [ComplianceRoutes.success]: {
    stepValue: 6,
    stageValue: 6,
  },
}

export const CurrencyArray = [
  { name: CurrencyCode.NGN, id: CurrencyCode.NGN },
  { name: CurrencyCode.USD, id: CurrencyCode.USD },
  { name: CurrencyCode.XAF, id: CurrencyCode.XAF },
  { name: CurrencyCode.KES, id: CurrencyCode.KES },
  { name: CurrencyCode.GHS, id: CurrencyCode.GHS },
  { name: CurrencyCode.XOF, id: CurrencyCode.XOF },
]

export const BVN_MAX_LENGTH = 11

export const elPlusConfig = (state: any) => ({
  props: {
    validateEvent: false,
    error: state.errors[0],
    required: state.required,
  },
})
