<template>
  <Modal
    modal="$atts.modal"
    :title="$t('wallets.currencyExchange')"
  >
    <div
      v-if="!WalletLoading"
      class="flex flex-col w-full"
    >
      <div class="flex items-center justify-between max-w-full p-5 mt-4 border border-divider rounded-card">
        <div class="flex flex-col w-56">
          <span class="text-sm headSub text-tertiary">{{ $t('youPay') }}</span>
          <input
            v-model="credential.amount.value"
            type="number"
            class="font-medium text-[32px] outline-none"
            @input="formatToCurrency(credential.amount)"
          />
        </div>
        <div class="flex items-center w-auto">
          <icon
            :name="credential.source_currency.value"
            class="w-4 rounded-full"
          />
          <selectMenu
            v-model="credential.source_currency.value"
            :options="CurrencyArray"
            class="w-20 border-none"
          />
        </div>
      </div>

      <div class="flex items-center justify-between p-5 mt-4 border border-divider rounded-card">
        <div class="flex flex-col w-56">
          <span class="text-sm headSub{">{{ $t('youPay') }}</span>
          <input
            v-model="credential.result.value"
            type="text"
            class="font-medium text-[32px] outline-none disabled:bg-transparent"
            disabled
          />
        </div>
        <div class="flex items-center">
          <icon
            :name="credential.target_currency.value"
            class="w-4 rounded-full"
          />
          <selectMenu
            v-model="credential.target_currency.value"
            :options="CurrencyArray"
            class="w-20 border-none"
          />
        </div>
      </div>

      <span class="mt-3 italic font-medium text-tertiary">
        1 {{ credential.source_currency.value }} = {{ credential.rate }}
        {{ credential.target_currency.value }}
      </span>
    </div>
    <div
      v-else
      class="flex justify-center w-full"
    >
      <Spinner class="text-secondary !border-t-secondary" />
    </div>

    <div class="flex justify-between w-full gap-4 mt-14">
      <button
        class="w-full btn-secondary-md"
        type="button"
        @click="useWalletsModal().closeWalletConverter()"
      >
        {{ $t('cancel') }}
      </button>
      <button
        class="w-full btn-primary-md"
        :disabled="disabled || loading"
        @click="useWalletsModal().openConvertCurrency()"
      >
        <span> {{ $t('next') }}</span>
      </button>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import { useWalletsModal } from '~/src/composables/core/modals'
import { currencyExchange } from '~/src/composables/apis/currency'
import { formatToCurrency } from '~/src/composables/utils/useCurrency'
import { CurrencyArray } from '~/src/constants/index'

const { credential, exchange, disabled, WalletLoading, loading } = currencyExchange()

onMounted(() => {
  credential.amount.value = 10
  exchange()
})
</script>

<style scoped>
.slideUp-enter {
  opacity: 0;
  /* transform: translateY(200px); */
}
.slideUp-leave-to {
  opacity: 0;
  /* transform: translateY(-200px); */
}

.slideUp-enter-active,
.slideUp-leave-active {
  transition: all 0.35s ease;
}
</style>
