import { v4 as uuidv4 } from 'uuid'
import { getBanks } from '../institution'
import { getAllTransfers } from './index'
import { useTransfersModal } from '~/src/composables/core/modals'
import { toMinorUnit } from '~/src/helpers/toMinorUnit'
import { useAlert } from '~/src/composables/core/useNotification'
import { CurrencyCode, Institution, MOBILE_MONEY_SUPPORTED_COUNTRIES } from '~/src/types/internals'
import { ROUTES_NAMES } from '~/src/routes'
import { collectionApi } from '~/src/services/requests/collection'

const { fetch, banks, loading: bankLoading } = getBanks()

const countryKey = {
  GHS: 'GH',
  KES: 'KE',
  XAF: 'CM',
  NGN: 'NG',
} as any

const loading = ref(false)
const credential = {
  country: ref(''),
  bank_code: ref(''),
  amount: ref(),
  reason: ref(''),
  currency: ref('USD'),
  account_number: ref(''),
  name: ref(''),
} as any

export const momoExcludingGhs = MOBILE_MONEY_SUPPORTED_COUNTRIES.filter((item) => item.currency !== CurrencyCode.GHS)

export const useReceiveFunds = () => {
  const { fetchTransfer } = getAllTransfers()

  const setReceiveFundModal = (currency: CurrencyCode) => {
    if (!momoExcludingGhs.some((curr) => curr.currency === currency))
      return useAlert().openAlert({ type: 'ERROR', msg: 'This feature is not available for this currency' })
    credential.country.value = currency
    useTransfersModal().openReceiveFund()
  }

  const callingCode = computed(() => {
    if (countryKey[credential.country.value] === 'GH') return '+233'
    else if (countryKey[credential.country.value] === 'KE') return '+254'
    else if (countryKey[credential.country.value] === 'CM') return '+237'
    else if (countryKey[credential.country.value] === 'NG') return '+234'
    else return ''
  })

  watch(credential.country, (value: any) => {
    fetch(Institution.MOMOCOLLECTION, countryKey[value])
  })

  const disabled = computed(() => {
    if (credential.country.value === '' || credential.bank_code.value === '' || !credential.amount.value) return true
    else return false
  })

  const create = async () => {
    loading.value = true
    const res = (await collectionApi.$_requestCollection({
      bank_code: credential.bank_code.value,
      account_number: `${callingCode.value.substring(1)}${credential.account_number.value}`,
      amount: toMinorUnit(credential.amount.value),
      description: credential.reason.value,
      currency: credential.country.value,
      reference: uuidv4(),
    })) as any
    loading.value = false

    if (res !== 'ERROR') {
      useTransfersModal().closeReceiveFund()
      useRouter().push({
        name: ROUTES_NAMES.transactions,
      })
      fetchTransfer()
      useAlert().openAlert({ type: 'SUCCESS', msg: res.data.message })
    }
  }

  return { credential, create, banks, bankLoading, loading, disabled, callingCode, setReceiveFundModal }
}
