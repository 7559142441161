<script setup lang="ts">
import { ValueOf } from 'element-plus/es/components/table/src/table-column/defaults'
import { BaseFieldProps, Path } from 'vee-validate'
import { getCountries } from '~/src/composables/apis/extras/country'
import { getBanks, getCountriesByCurrency, resolveAccount } from '~/src/composables/apis/institution'
import { TransferBeneficiary } from '~/src/services/requests/transfers/types'
import {
  Institution,
  MAX_PHONE_LENGTH,
  MOBILE_MONEY_SUPPORTED_COUNTRIES,
  VeeValidationProps,
} from '~/src/types/internals'

type Props = {
  reasonProps: BaseFieldProps & VeeValidationProps
  currencyProps: BaseFieldProps & VeeValidationProps
  amountProps: BaseFieldProps & VeeValidationProps
  bankCodeProps: BaseFieldProps & VeeValidationProps
  accountNumberProps: BaseFieldProps & VeeValidationProps
  counterpartyProps: BaseFieldProps & VeeValidationProps
  counterparty?: string
  reason?: string
  accountNumber?: string
  currency?: string
  amount?: string
  bankCode?: string
}

const { countries, fetch: fetchCountries, loading: countryLoading } = getCountries()
const { banks, fetch: fetchBanks, loading: bankLoading } = getBanks()
const { fetch: fetchAccount, loading: accountLoading, resolved } = resolveAccount()
const {
  fetch: fetchCountryByCurrency,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  countries: countriesByCurrency,
  loading: countriesByCurrencyLoading,
} = getCountriesByCurrency()

const props = defineProps<Props>()
const emit = defineEmits(['updateField'])

const multiCountryCurrency = computed(() => MOBILE_MONEY_SUPPORTED_COUNTRIES.filter(({ country }) => !country))

const countriesByMultiCountryCurrency = computed(() =>
  countriesByCurrency.value.map(({ name: text, code: id, calling_code: callingCode }) => ({ text, callingCode, id })),
)

const generalCountries = computed(() =>
  countries.value.filter(({ id }) => MOBILE_MONEY_SUPPORTED_COUNTRIES.some((item) => item.country === id)),
)

const supportsMobileMoney = computed(() => {
  const allCountries = [...generalCountries.value, ...countriesByMultiCountryCurrency.value]

  return {
    countries: allCountries.map(({ text: label, id }) => ({ value: id, label, id })),
    callingCodes: allCountries.map(({ callingCode: value, id }) => ({ value, label: value, id })),
  }
})
const dropCallingCode = computed(() => {
  const country = supportsMobileMoney.value.callingCodes.find(({ id }) => id === props.currency)

  const [, acct] = country && props.accountNumber ? props.accountNumber.split(country?.value) : [undefined, '']
  return acct
})
const accountDisplay = ref(dropCallingCode.value)
const selectedCode = computed(() => {
  return supportsMobileMoney.value.callingCodes.find(({ id }) => id === props.currency)?.value
})

const handleUpdate = (key: Path<TransferBeneficiary>, value: ValueOf<TransferBeneficiary>) => {
  emit('updateField', {
    key,
    value,
  })
}

watch(resolved, (value) => handleUpdate('meta.counterparty.name', value))

watch([accountDisplay, selectedCode], ([accountNumber, code]) => {
  const value = `${code}${accountNumber}`

  handleUpdate('accountNumber', value)
  handleUpdate('meta.counterparty.phoneNumber', value)
})

watch(
  () => props.currency,
  (value) => {
    value && fetchBanks(Institution.MOMO, value)
    handleUpdate('bankCode', '')
  },
)

watch(accountDisplay, (val) => {
  if (!val || val?.length < MAX_PHONE_LENGTH) {
    resolved.value = ''
  }
})

watch([() => props.accountNumber, () => props.bankCode], ([acct, code]) => {
  if (!acct) return

  acct?.length >= MAX_PHONE_LENGTH &&
    code &&
    fetchAccount({
      accountNumber: acct,
      bankCode: code,
    })
})

onMounted(() => {
  if (multiCountryCurrency.value) {
    multiCountryCurrency.value.forEach(({ currency }) => fetchCountryByCurrency(currency))
  }

  fetchCountries()
})
</script>
<template>
  <div class="flex flex-col gap-6 my-6">
    <FormControl
      :label="$t('country')"
      v-bind="currencyProps"
    >
      <Select
        :model-value="currency"
        filterable
        :loading="countryLoading || countriesByCurrencyLoading"
        class="w-full"
        size="large"
        :placeholder="$t('choose')"
        @update:model-value="($event: string) => handleUpdate('currency', $event)"
      >
        <SelectOption
          v-for="item in supportsMobileMoney.countries"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
          <span class="inline-flex justify-between items-center w-full">
            {{ item.label }}

            <IconsCheckOutlined
              v-if="currency === item.value"
              class="text-success900 h-4 w-4"
            />
          </span>
        </SelectOption>
      </Select>
    </FormControl>

    <FormControl
      :label="$t('transactions.mobileMoneyNetwork')"
      v-bind="bankCodeProps"
    >
      <Select
        :model-value="bankCode"
        filterable
        :loading="bankLoading"
        class="w-full"
        size="large"
        :placeholder="$t('choose')"
        @update:model-value="($event: string) => handleUpdate('bankCode', $event)"
      >
        <SelectOption
          v-for="item in banks"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
          <span class="inline-flex justify-between items-center w-full">
            {{ item.label }}

            <IconsCheckOutlined
              v-if="bankCode === item.value"
              class="text-success900 h-4 w-4"
            />
          </span>
        </SelectOption>
      </Select>
    </FormControl>

    <FormControl
      v-bind="accountNumberProps"
      class="relative"
    >
      <SelectInput
        id="accountNumber"
        v-model="accountDisplay"
        :selected="selectedCode ? `+${selectedCode}` : ''"
        :select-props="{ disabled: true, placeholder: '+00', width: '115px' }"
        :maxlength="MAX_PHONE_LENGTH.toString()"
        :label="$t('transactions.mobileNumber')"
        placeholder="0123456789"
        :options="supportsMobileMoney.callingCodes"
      />
    </FormControl>

    <FormControl
      :label="$t('transfer.beneficiaryName')"
      v-bind="{
        ...counterpartyProps,
        error: accountLoading ? undefined : counterpartyProps?.error,
      }"
    >
      <InputField
        :model-value="counterparty"
        :disabled="accountLoading"
        placeholder="e.g. John Doe"
        maxlength="50"
        @update:model-value="($event: string) => handleUpdate('meta.counterparty.name', $event)"
      />
    </FormControl>

    <FormControl
      v-bind="amountProps"
      class="relative"
    >
      <CurrencyInput
        id="amount"
        :model-value="amount"
        :label="$t('amount')"
        placeholder="0.00"
        :has-selector="false"
        @update:model-value="($event: string) => handleUpdate('amount', $event)"
      />
    </FormControl>

    <FormControl
      :label="$t('reason')"
      v-bind="reasonProps"
    >
      <InputField
        :model-value="reason"
        :placeholder="$t('transfer.whatAreYouPayingFor')"
        maxlength="50"
        @update:model-value="($event: string) => handleUpdate('reason', $event)"
      />
    </FormControl>
  </div>
</template>
