<script setup lang="ts">
import { useKycModal } from '~/src/composables/core/modals'
import { useKYC } from '~/src/composables/apis/kyc/useKyc'
import { ComplianceRoutes } from '~/src/types/internals'

const { closeConfirmDirectorComplete } = useKycModal()
const { directorCompletionState, completeDirector, stateLoading } = useKYC()

const loading = computed(() => stateLoading.completeDirector)

const complete = () => {
  directorCompletionState.currentDirector &&
    completeDirector(directorCompletionState.currentDirector, {
      onSuccess: () => {
        closeConfirmDirectorComplete()
        navigateTo({
          name: ComplianceRoutes.directorId,
          params: {
            id: directorCompletionState.currentDirector!.id,
          },
        })
      },
    })
}
</script>
<template>
  <Modal modal="$atts.modal">
    <div class="flex flex-col gap-8">
      <div>
        <Typography
          variant="lgBold"
          color="text-gray900"
        >
          {{ $t('kyc.completeDirectorTitle') }}
        </Typography>
        <Typography
          color="text-gray900"
          variant="base"
          class="mt-2"
        >
          {{ $t('kyc.completeDirectorSubtitle') }}
        </Typography>
      </div>

      <div class="flex justify-end gap-4">
        <Button
          variant="secondary"
          size="large"
          @click="closeConfirmDirectorComplete"
        >
          {{ $t('back') }}
        </Button>
        <Button
          size="large"
          :loading="loading"
          @click="complete"
        >
          {{ $t('submit') }}
        </Button>
      </div>
    </div>
  </Modal>
</template>
