<script lang="ts" setup>
import { buyAirtime, fetchAirtimeBillers } from '~/src/composables/apis/bills'
const { buy, credential, loading, error, disabled } = buyAirtime()
const { fetch, billerData } = fetchAirtimeBillers()

onMounted(fetch)
</script>

<template>
  <Modal
    modal="$atts.modal"
    :title="$t('transactions.buyAirtime')"
  >
    <p
      v-if="error"
      class="text-red mt-4 font-medium"
    >
      {{ error }}
    </p>
    <div class="flex flex-col w-full h-full justify-between">
      <div class="flex items-center justify-center w-full" />
      <div class="flex w-full justify-end mt-6" />
      <transition
        name="slideUp"
        appear
      >
        <form
          class="w-full overflow-y-auto"
          @submit.prevent="buy"
        >
          <div class="flex flex-col gap-6">
            <FormControl :label="$t('wallets.selectCountry')">
              <Select
                v-model="credential.identifier.value"
                placeholder="Select Bank"
                class="w-full"
                size="large"
                required
              >
                <SelectOption
                  v-for="(item, index) in [
                    { label: 'Nigeria', id: 'ng-airtime' },
                    { label: 'Ghana', id: null },
                  ]"
                  :key="index"
                  :label="item.label"
                  :value="item.id"
                >
                  {{ item.label }}

                  <IconsCheckOutlined
                    v-if="credential.identifier.value === item.id"
                    class="text-success900 h-4 w-4"
                  />
                </SelectOption>
              </Select>
            </FormControl>

            <FormControl
              v-if="credential.identifier.value !== 'ng-airtime'"
              :label="$t('wallets.selectNetwork')"
            >
              <Select
                v-model="credential.identifier.value"
                placeholder="Select Bank"
                class="w-full"
                size="large"
                required
              >
                <SelectOption
                  v-for="item in billerData"
                  :key="item.id"
                  class="capitalize"
                  :label="item.text"
                  :value="item.id"
                >
                  {{ item.label }}

                  <IconsCheckOutlined
                    v-if="credential.identifier.value === item.id"
                    class="text-success900 h-4 w-4"
                  />
                </SelectOption>
              </Select>
            </FormControl>

            <FormControl :label="$t('transactions.phoneNumber')">
              <InputFieldPhone
                id="phone"
                v-model="credential.phone_number.value"
              />
            </FormControl>

            <FormControl>
              <CurrencyInput
                id="amount"
                v-model="credential.amount.value"
                :label="$t('amount')"
                placeholder="0.00"
                :has-selector="false"
              />
            </FormControl>
          </div>

          <button
            class="ModalBtn"
            :disabled="disabled || loading"
          >
            <span v-if="!loading"> {{ $t('submit') }}</span> <Spinner v-else />
          </button>
        </form>
      </transition>
    </div>
  </Modal>
</template>

<style scoped>
.slideUp-enter {
  opacity: 0;
  /* transform: translateY(200px); */
}
.slideUp-leave-to {
  opacity: 0;
  /* transform: translateY(-200px); */
}

.slideUp-enter-active,
.slideUp-leave-active {
  transition: all 0.35s ease;
}
</style>
