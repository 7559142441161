<script setup lang="ts">
import { useStashModal } from '~/src/composables/core/modals'
import { useStash } from '~/src/composables/apis/stash'
import { ROUTES_NAMES } from '~/src/routes'

const { state, reset, getInvestmentTransactions } = useStash()

const viewStash = () => {
  useStashModal().closePlanCreated()

  const id = state.fundingSuccess?.id

  reset()

  getInvestmentTransactions({ id })

  navigateTo({
    name: id ? ROUTES_NAMES.stashId : ROUTES_NAMES.stashHome,
    params: {
      id,
    },
  })
}
</script>

<template>
  <Modal
    modal="$atts.modal"
    width="448px"
    :close-on-click="false"
    @close="useStashModal().closePlanCreated"
  >
    <div class="flex flex-col gap-8 justify-center items-center">
      <Icon
        name="success-illustration"
        class="h-20 mx-auto"
      />

      <div>
        <Typography
          color="text-gray900"
          variant="xxlBold"
          class="text-center mb-4"
        >
          {{ state.fundingSuccess.title }}
        </Typography>

        <Typography
          color="text-gray500"
          variant="sm"
          class="text-center"
        >
          {{ state.fundingSuccess.description }}
        </Typography>
      </div>
      <Button
        class="w-fit"
        size="large"
        @click="viewStash"
      >
        {{ state.fundingSuccess.actionName }}
      </Button>
    </div>
  </Modal>
</template>
