import { ServerResponse } from '../../../types/api'
import { CurrencyCode } from '../../../types/internals'
import { SnakifyKeys } from '../../../types/utilities'
import { GATEWAY_ENDPOINT } from '../../SetupBase'
import { Account } from '../Account/type'
import { Bank } from './type'

export const institutionApi = {
  $_resolveAccount: (
    body: SnakifyKeys<{
      accountNumber: string
      bankCode: string
    }>,
  ): Promise<ServerResponse<{ data: SnakifyKeys<Account> }>> => {
    const url = '/institutions/resolve'
    return GATEWAY_ENDPOINT.post(url, body)
  },
  $_getAllBanks: (options: { type?: string; country?: string }): Promise<ServerResponse<{ data: Bank[] }>> => {
    const url = `/institutions`
    return GATEWAY_ENDPOINT.get(url, {
      params: options,
    })
  },
  $_getCountriesByCurrency: (currency: CurrencyCode): Promise<ServerResponse<{ data: Bank[] }>> => {
    const url = `/countries/currency/${currency}`
    return GATEWAY_ENDPOINT.get(url)
  },
  $_getAllCurrencies: () => {
    const url = '/currencies'
    return GATEWAY_ENDPOINT.get(url)
  },
}
