<script setup lang="ts">
import { TypographyVariant, TypographyElement } from './index'
import type { Props } from './index'

const props = withDefaults(defineProps<Props>(), {
  variant: TypographyVariant.smMedium,
  color: 'text-secondary',
  as: TypographyElement.p,
  class: '',
})

const classes = computed(() => ({
  'text-xs font-normal': props.variant === TypographyVariant.xs,
  'text-xs font-medium': props.variant === TypographyVariant.xsMedium,
  'text-sm font-normal leading-[20px]': props.variant === TypographyVariant.sm,
  'text-base font-normal': props.variant === TypographyVariant.base,
  'text-sm font-medium': props.variant === TypographyVariant.smMedium,
  'text-sm font-semibold': props.variant === TypographyVariant.smBold,
  'text-base font-medium': props.variant === TypographyVariant.baseMedium,
  'text-base font-semibold': props.variant === TypographyVariant.baseBold,
  'text-base font-bold': props.variant === TypographyVariant.baseBolder,
  'text-lg': props.variant === TypographyVariant.lg,
  'text-xl font-medium': props.variant === TypographyVariant.lgMedium,
  'text-xl font-semibold': props.variant === TypographyVariant.lgBold,
  'text-2xl font-semibold': props.variant === TypographyVariant.xlBold,
  'text-[32px] font-semibold': props.variant === TypographyVariant.xxlBold,
  'text-[32px] font-black': props.variant === TypographyVariant.xxlBolder,
  'text-[36px] leading-[44px] font-semibold': props.variant === TypographyVariant.xxxlBold,
}))

const component = computed(() => {
  if (props.as === TypographyElement.NuxtLink) return resolveComponent('NuxtLink')
  return props.as
})
</script>
<template>
  <Component
    :is="component"
    :class="[classes, color, props.class]"
  >
    <slot />
  </Component>
</template>
