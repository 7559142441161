export enum API_ROUTES {
  ipWhitelist = '/ip-whitelisting',
}

export enum ROUTES_NAMES {
  index = 'index',
  compliances = 'compliances',
  transactions = 'transactions',
  pickAccount = 'auth-pick-account',
  login = 'auth-login',
  signup = 'auth-signup',
  otp = 'auth-otp',
  wallets = 'wallets',
  bills = 'bills',
  billsSuccess = 'bills-success',
  billsPayment = 'bills-payment',
  forgotPassword = 'auth-forgot-password',
  recipientId = 'recipient-id',
  transferUsdSuccessId = 'transfer-usd-success-id',
  transferUsdIdPaymentMethod = 'transfer-usd-id-payment-method',
  transferUsdIdAmount = 'transfer-usd-id-amount',
  transferUsdIdAdditionalInfo = 'transfer-usd-id-additional-info',
  transferUsdIdReview = 'transfer-usd-id-review',
  transferUsdId = 'transfer-usd-id',
  stashHome = 'stash',
  stashId = 'stash-id',
  stashCreate = 'stash-plan-create',
  stashDuration = 'stash-plan-duration',
  stashReview = 'stash-plan-review',
  collectionReceiveFundsSuccess = 'collection-receive-funds-success-id',
  collectionReceiveFundsCurrencyReview = 'collection-receive-funds-currency-review',
  collectionReceiveFundsCurrency = 'collection-receive-funds-currency',
  collectionReceiveFundsCurrencyAmount = 'collection-receive-funds-currency-amount',
  collectionReceiveFundsCurrencyAdditionalInfo = 'collection-receive-funds-currency-additional-info',
}
