import { RouteLocationRaw } from '#vue-router'

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Outline = 'outline',
  Text = 'text',
  Error = 'error',
}

export enum ButtonSize {
  Medium = 'medium',
  Large = 'large',
  Small = 'small',
}

export enum Element {
  A = 'a',
  NuxtLink = 'NuxtLink',
  Button = 'button',
}

export interface Props {
  variant?: `${ButtonVariant}`
  size?: `${ButtonSize}`
  as?: `${Element}`
  to?: RouteLocationRaw
  href?: string
  loading?: boolean
  disabled?: boolean
}
