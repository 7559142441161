<script setup lang="ts">
import { ValueOf } from 'element-plus/es/components/table/src/table-column/defaults'
import { BaseFieldProps, Path } from 'vee-validate'
import Calendar from '~/src/components/icons/Calendar.vue'
import allCountries from '~/src/constants/countries'
import { MoMoSender } from '~/src/services/requests/transfers/types'
import { DateFormat, MAX_PHONE_LENGTH, VeeValidationProps } from '~/src/types/internals'

interface Props {
  firstNameProps: BaseFieldProps & VeeValidationProps
  lastNameProps: BaseFieldProps & VeeValidationProps
  postalCodeProps: BaseFieldProps & VeeValidationProps
  addressProps: BaseFieldProps & VeeValidationProps
  emailProps: BaseFieldProps & VeeValidationProps
  dobProps: BaseFieldProps & VeeValidationProps
  phoneNumberProps: BaseFieldProps & VeeValidationProps
  stateProps: BaseFieldProps & VeeValidationProps
  countryProps: BaseFieldProps & VeeValidationProps
  cityProps: BaseFieldProps & VeeValidationProps
  email?: string
  dob?: string
  phoneNumber?: string
  country?: string
  city?: string
  firstName?: string
  state?: string
  lastName?: string
  postalCode?: string
  address?: string
  step: number
}
const props = defineProps<Props>()
const emit = defineEmits(['updateField'])

const countries = allCountries.map(({ text, id: value, CallingCode: callingCode }) => ({
  value,
  label: `${value} - ${text}`,
  callingCode,
}))

const selectCountry = computed(() => countries.find(({ value }) => value === props.country))

const selectedCountryCode = computed(() => {
  if (!selectCountry.value) return ''

  return `${selectCountry.value.callingCode}`
})

const dropCallingCode = computed(() => {
  const [, acct] =
    selectCountry.value && props.phoneNumber ? props.phoneNumber.split(selectCountry.value.value) : [undefined, '']
  return acct
})
const phoneDisplay = ref(dropCallingCode.value)

const handleUpdate = (key: Path<MoMoSender>, value: ValueOf<MoMoSender>) => {
  emit('updateField', {
    key,
    value,
  })
}

const handleUpdateCountry = (value: string) => {
  const newCountry = value
  const code = countries.find(({ value }) => value === newCountry)?.callingCode || ''

  handleUpdate('country', value)
  handleUpdate('phoneNumber', `${code}${phoneDisplay.value}`)
}

const handleUpdatePhoneNumber = (value: string) => {
  const code = countries.find(({ value }) => value === props.country)?.callingCode || ''

  handleUpdate('phoneNumber', `${code}${value}`)
}
</script>
<template>
  <div class="flex flex-col gap-6 my-6">
    <div>
      <badge name="default"> Step {{ step + 1 }} of 3 </badge>

      <Typography class="mt-4">Add Sender's information. This helps in reconcilation of this transfer.</Typography>
    </div>

    <div class="flex gap-4">
      <FormControl
        :label="$t('firstName')"
        v-bind="firstNameProps"
        class="flex-1"
      >
        <InputField
          :model-value="firstName"
          placeholder="John"
          @update:model-value="($event: string) => handleUpdate('firstName', $event)"
        />
      </FormControl>

      <FormControl
        :label="$t('lastName')"
        v-bind="lastNameProps"
        class="flex-1"
      >
        <InputField
          :model-value="lastName"
          placeholder="Doe"
          @update:model-value="($event: string) => handleUpdate('lastName', $event)"
        />
      </FormControl>
    </div>

    <FormControl
      :label="$t('email')"
      v-bind="emailProps"
    >
      <InputField
        :model-value="email"
        placeholder="john@doe.com"
        @update:model-value="($event: string) => handleUpdate('email', $event)"
      />
    </FormControl>

    <FormControl
      v-bind="phoneNumberProps"
      class="relative"
    >
      <SelectInput
        id="phoneNumber"
        v-model="phoneDisplay"
        :selected="selectedCountryCode ? `+${selectedCountryCode}` : ''"
        :select-props="{ placeholder: '+00' }"
        :maxlength="MAX_PHONE_LENGTH.toString()"
        :label="$t('transactions.phoneNumber')"
        placeholder="0123456789"
        :options="countries"
        @update:model-value="handleUpdatePhoneNumber"
        @update:selected="handleUpdateCountry"
      />
    </FormControl>

    <div class="flex gap-4">
      <FormControl
        v-bind="stateProps"
        :label="$t('state')"
        class="flex-1"
      >
        <InputField
          :model-value="state"
          :rows="5"
          placeholder="Lagos"
          @update:model-value="($event: string) => handleUpdate('state', $event)"
        />
      </FormControl>

      <FormControl
        v-bind="cityProps"
        :label="$t('city')"
        class="flex-1"
      >
        <InputField
          :model-value="city"
          placeholder="Ikega"
          @update:model-value="($event: string) => handleUpdate('city', $event)"
        />
      </FormControl>
    </div>

    <FormControl
      :label="$t('customer.address')"
      v-bind="addressProps"
    >
      <InputField
        :model-value="address"
        type="textarea"
        :rows="3"
        placeholder="Type something"
        @update:model-value="($event: string) => handleUpdate('address', $event)"
      />
    </FormControl>

    <div class="flex gap-4">
      <FormControl
        :label="$t('customer.postalCode')"
        v-bind="postalCodeProps"
        class="relative w-2/5"
      >
        <InputField
          :model-value="postalCode"
          placeholder="000000"
          @update:model-value="($event: string) => handleUpdate('postalCode', $event)"
        />
      </FormControl>

      <FormControl
        :label="$t('customer.dateOfBirth')"
        v-bind="dobProps"
        class="flex-2 w-3/5"
      >
        <DatePicker
          :model-value="dob"
          size="large"
          type="date"
          placeholder="MM / DD / YYYY"
          class="!w-full"
          :value-format="DateFormat.DefaultAlt"
          :prefix-icon="Calendar"
          :clearable="false"
          @update:model-value="($event: string) => handleUpdate('dob', $event)"
        />
      </FormControl>
    </div>
  </div>
</template>
