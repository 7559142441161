import { default as index0boPK5kqQgMeta } from "/opt/build/repo/src/pages/auth/business/index.vue?macro=true";
import { default as forgot_45passwordcnWqZJY9BsMeta } from "/opt/build/repo/src/pages/auth/forgot-password.vue?macro=true";
import { default as loginkIn80CuK0vMeta } from "/opt/build/repo/src/pages/auth/login.vue?macro=true";
import { default as otpfJE2HFwn3kMeta } from "/opt/build/repo/src/pages/auth/otp.vue?macro=true";
import { default as pick_45accountqLppzkVOX5Meta } from "/opt/build/repo/src/pages/auth/pick-account.vue?macro=true";
import { default as reset_45passwordCfgS4FpWFaMeta } from "/opt/build/repo/src/pages/auth/reset-password.vue?macro=true";
import { default as signupWTSoKe4EcEMeta } from "/opt/build/repo/src/pages/auth/signup.vue?macro=true";
import { default as verify_45emailC5RmWKHDg2Meta } from "/opt/build/repo/src/pages/auth/verify-email.vue?macro=true";
import { default as indexlglpeB9fttMeta } from "/opt/build/repo/src/pages/bills/index.vue?macro=true";
import { default as paymentibWzMfScsRMeta } from "/opt/build/repo/src/pages/bills/payment.vue?macro=true";
import { default as successokq2XXxxm1Meta } from "/opt/build/repo/src/pages/bills/success.vue?macro=true";
import { default as _91id_93svKQmLaXwEMeta } from "/opt/build/repo/src/pages/cards/[id].vue?macro=true";
import { default as createejET3QW6RUMeta } from "/opt/build/repo/src/pages/cards/create.vue?macro=true";
import { default as indexzmHurHseUyMeta } from "/opt/build/repo/src/pages/cards/index.vue?macro=true";
import { default as _91reference_93t9J09sDNVjMeta } from "/opt/build/repo/src/pages/collab/confirm/[reference].vue?macro=true";
import { default as indexUP9GI2So5ZMeta } from "/opt/build/repo/src/pages/collection/[id]/index.vue?macro=true";
import { default as otpjrfJz2ArJoMeta } from "/opt/build/repo/src/pages/collection/[id]/otp.vue?macro=true";
import { default as additional_45info51axeYQYQPMeta } from "/opt/build/repo/src/pages/collection/receive-funds/[currency]/additional-info.vue?macro=true";
import { default as amountCdPYahqeu8Meta } from "/opt/build/repo/src/pages/collection/receive-funds/[currency]/amount.vue?macro=true";
import { default as index0n0wKyXjTxMeta } from "/opt/build/repo/src/pages/collection/receive-funds/[currency]/index.vue?macro=true";
import { default as review1TQhtHxQ6HMeta } from "/opt/build/repo/src/pages/collection/receive-funds/[currency]/review.vue?macro=true";
import { default as _91currency_93RfLInaTn5VMeta } from "/opt/build/repo/src/pages/collection/receive-funds/[currency].vue?macro=true";
import { default as _91id_93Tvw1vo6p9MMeta } from "/opt/build/repo/src/pages/collection/receive-funds/success/[id].vue?macro=true";
import { default as index8hR4mMpyPmMeta } from "/opt/build/repo/src/pages/compliances-directors/[id]/index.vue?macro=true";
import { default as successYAgbDY7n6nMeta } from "/opt/build/repo/src/pages/compliances-directors/[id]/success.vue?macro=true";
import { default as compliances_45successaGchcJfa51Meta } from "/opt/build/repo/src/pages/compliances-success.vue?macro=true";
import { default as addresshBIT6xk3gIMeta } from "/opt/build/repo/src/pages/compliances/address.vue?macro=true";
import { default as documents1fEG97lMwvMeta } from "/opt/build/repo/src/pages/compliances/documents.vue?macro=true";
import { default as indexmu6Wqx85dgMeta } from "/opt/build/repo/src/pages/compliances/index.vue?macro=true";
import { default as ownershipHXjrsJSHAbMeta } from "/opt/build/repo/src/pages/compliances/ownership.vue?macro=true";
import { default as reviewBvWCnysO5dMeta } from "/opt/build/repo/src/pages/compliances/review.vue?macro=true";
import { default as shareholdersRAgsK51zIgMeta } from "/opt/build/repo/src/pages/compliances/shareholders.vue?macro=true";
import { default as compliancesnlZqKZnXHWMeta } from "/opt/build/repo/src/pages/compliances.vue?macro=true";
import { default as _91id_937IsfRiQfS8Meta } from "/opt/build/repo/src/pages/customers/[id].vue?macro=true";
import { default as indexxPO890SbQ6Meta } from "/opt/build/repo/src/pages/customers/index.vue?macro=true";
import { default as dashboard6jrmk3eHoRMeta } from "/opt/build/repo/src/pages/dashboard.vue?macro=true";
import { default as disputesWK4sBxu9NlMeta } from "/opt/build/repo/src/pages/disputes.vue?macro=true";
import { default as fxl4BnINAvGHMeta } from "/opt/build/repo/src/pages/fx.vue?macro=true";
import { default as indexMUmX8mAfjNMeta } from "/opt/build/repo/src/pages/index.vue?macro=true";
import { default as ProfileLHf24epEV2Meta } from "/opt/build/repo/src/pages/settings/Profile.vue?macro=true";
import { default as WebhooksftygGoluSVMeta } from "/opt/build/repo/src/pages/settings/Webhooks.vue?macro=true";
import { default as audit_45logS9ehD8D8LFMeta } from "/opt/build/repo/src/pages/settings/audit-log.vue?macro=true";
import { default as ip_45whitelistXp8yG0vTioMeta } from "/opt/build/repo/src/pages/settings/ip-whitelist.vue?macro=true";
import { default as rolesaAleRlXrMoMeta } from "/opt/build/repo/src/pages/settings/roles.vue?macro=true";
import { default as teamsWC6MKqju4jMeta } from "/opt/build/repo/src/pages/settings/teams.vue?macro=true";
import { default as indexb2VGgxhhl0Meta } from "/opt/build/repo/src/pages/stash/[id]/index.vue?macro=true";
import { default as indexpAG93PiK8XMeta } from "/opt/build/repo/src/pages/stash/index.vue?macro=true";
import { default as create3UjVpWRm53Meta } from "/opt/build/repo/src/pages/stash/plan/create.vue?macro=true";
import { default as durationZ5dxgDP7FAMeta } from "/opt/build/repo/src/pages/stash/plan/duration.vue?macro=true";
import { default as reviewJgeWqdo8AiMeta } from "/opt/build/repo/src/pages/stash/plan/review.vue?macro=true";
import { default as planIcbXsETR0GMeta } from "/opt/build/repo/src/pages/stash/plan.vue?macro=true";
import { default as subaccountsmTXH8kErFJMeta } from "/opt/build/repo/src/pages/subaccounts.vue?macro=true";
import { default as _91id_93dF86YjIH8WMeta } from "/opt/build/repo/src/pages/track/[id].vue?macro=true";
import { default as createp2KMjeTG7JMeta } from "/opt/build/repo/src/pages/transactions/create.vue?macro=true";
import { default as index0oD3o1Co6zMeta } from "/opt/build/repo/src/pages/transactions/index.vue?macro=true";
import { default as pendingahZSzGR55UMeta } from "/opt/build/repo/src/pages/transactions/pending.vue?macro=true";
import { default as _91id_93kG532F4w9ZMeta } from "/opt/build/repo/src/pages/transfer/usd-success/[id].vue?macro=true";
import { default as additional_45infoV8j0AlNadBMeta } from "/opt/build/repo/src/pages/transfer/usd/[id]/additional-info.vue?macro=true";
import { default as amountBZ1F6qagoMMeta } from "/opt/build/repo/src/pages/transfer/usd/[id]/amount.vue?macro=true";
import { default as indexS3O9nv2oyKMeta } from "/opt/build/repo/src/pages/transfer/usd/[id]/index.vue?macro=true";
import { default as payment_45methodElU9ATvnaHMeta } from "/opt/build/repo/src/pages/transfer/usd/[id]/payment-method.vue?macro=true";
import { default as reviewAoUNShUhCkMeta } from "/opt/build/repo/src/pages/transfer/usd/[id]/review.vue?macro=true";
import { default as usdofQsUgVePhMeta } from "/opt/build/repo/src/pages/transfer/usd.vue?macro=true";
import { default as _91id_93ryk2sbH6LrMeta } from "/opt/build/repo/src/pages/wallets/[id].vue?macro=true";
import { default as indexxSIUyIwN2XMeta } from "/opt/build/repo/src/pages/wallets/index.vue?macro=true";
export default [
  {
    name: index0boPK5kqQgMeta?.name ?? "auth-business",
    path: index0boPK5kqQgMeta?.path ?? "/auth/business",
    meta: index0boPK5kqQgMeta || {},
    alias: index0boPK5kqQgMeta?.alias || [],
    redirect: index0boPK5kqQgMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/auth/business/index.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordcnWqZJY9BsMeta?.name ?? "auth-forgot-password",
    path: forgot_45passwordcnWqZJY9BsMeta?.path ?? "/auth/forgot-password",
    meta: forgot_45passwordcnWqZJY9BsMeta || {},
    alias: forgot_45passwordcnWqZJY9BsMeta?.alias || [],
    redirect: forgot_45passwordcnWqZJY9BsMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: loginkIn80CuK0vMeta?.name ?? "auth-login",
    path: loginkIn80CuK0vMeta?.path ?? "/auth/login",
    meta: loginkIn80CuK0vMeta || {},
    alias: loginkIn80CuK0vMeta?.alias || [],
    redirect: loginkIn80CuK0vMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: otpfJE2HFwn3kMeta?.name ?? "auth-otp",
    path: otpfJE2HFwn3kMeta?.path ?? "/auth/otp",
    meta: otpfJE2HFwn3kMeta || {},
    alias: otpfJE2HFwn3kMeta?.alias || [],
    redirect: otpfJE2HFwn3kMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/auth/otp.vue").then(m => m.default || m)
  },
  {
    name: pick_45accountqLppzkVOX5Meta?.name ?? "auth-pick-account",
    path: pick_45accountqLppzkVOX5Meta?.path ?? "/auth/pick-account",
    meta: pick_45accountqLppzkVOX5Meta || {},
    alias: pick_45accountqLppzkVOX5Meta?.alias || [],
    redirect: pick_45accountqLppzkVOX5Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/auth/pick-account.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordCfgS4FpWFaMeta?.name ?? "auth-reset-password",
    path: reset_45passwordCfgS4FpWFaMeta?.path ?? "/auth/reset-password",
    meta: reset_45passwordCfgS4FpWFaMeta || {},
    alias: reset_45passwordCfgS4FpWFaMeta?.alias || [],
    redirect: reset_45passwordCfgS4FpWFaMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: signupWTSoKe4EcEMeta?.name ?? "auth-signup",
    path: signupWTSoKe4EcEMeta?.path ?? "/auth/signup",
    meta: signupWTSoKe4EcEMeta || {},
    alias: signupWTSoKe4EcEMeta?.alias || [],
    redirect: signupWTSoKe4EcEMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: verify_45emailC5RmWKHDg2Meta?.name ?? "auth-verify-email",
    path: verify_45emailC5RmWKHDg2Meta?.path ?? "/auth/verify-email",
    meta: verify_45emailC5RmWKHDg2Meta || {},
    alias: verify_45emailC5RmWKHDg2Meta?.alias || [],
    redirect: verify_45emailC5RmWKHDg2Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: indexlglpeB9fttMeta?.name ?? "bills",
    path: indexlglpeB9fttMeta?.path ?? "/bills",
    meta: indexlglpeB9fttMeta || {},
    alias: indexlglpeB9fttMeta?.alias || [],
    redirect: indexlglpeB9fttMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/bills/index.vue").then(m => m.default || m)
  },
  {
    name: paymentibWzMfScsRMeta?.name ?? "bills-payment",
    path: paymentibWzMfScsRMeta?.path ?? "/bills/payment",
    meta: paymentibWzMfScsRMeta || {},
    alias: paymentibWzMfScsRMeta?.alias || [],
    redirect: paymentibWzMfScsRMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/bills/payment.vue").then(m => m.default || m)
  },
  {
    name: successokq2XXxxm1Meta?.name ?? "bills-success",
    path: successokq2XXxxm1Meta?.path ?? "/bills/success",
    meta: successokq2XXxxm1Meta || {},
    alias: successokq2XXxxm1Meta?.alias || [],
    redirect: successokq2XXxxm1Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/bills/success.vue").then(m => m.default || m)
  },
  {
    name: _91id_93svKQmLaXwEMeta?.name ?? "cards-id",
    path: _91id_93svKQmLaXwEMeta?.path ?? "/cards/:id()",
    meta: _91id_93svKQmLaXwEMeta || {},
    alias: _91id_93svKQmLaXwEMeta?.alias || [],
    redirect: _91id_93svKQmLaXwEMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/cards/[id].vue").then(m => m.default || m)
  },
  {
    name: createejET3QW6RUMeta?.name ?? "cards-create",
    path: createejET3QW6RUMeta?.path ?? "/cards/create",
    meta: createejET3QW6RUMeta || {},
    alias: createejET3QW6RUMeta?.alias || [],
    redirect: createejET3QW6RUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/cards/create.vue").then(m => m.default || m)
  },
  {
    name: indexzmHurHseUyMeta?.name ?? "cards",
    path: indexzmHurHseUyMeta?.path ?? "/cards",
    meta: indexzmHurHseUyMeta || {},
    alias: indexzmHurHseUyMeta?.alias || [],
    redirect: indexzmHurHseUyMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: _91reference_93t9J09sDNVjMeta?.name ?? "collab-confirm-reference",
    path: _91reference_93t9J09sDNVjMeta?.path ?? "/collab/confirm/:reference()",
    meta: _91reference_93t9J09sDNVjMeta || {},
    alias: _91reference_93t9J09sDNVjMeta?.alias || [],
    redirect: _91reference_93t9J09sDNVjMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/collab/confirm/[reference].vue").then(m => m.default || m)
  },
  {
    name: indexUP9GI2So5ZMeta?.name ?? "collection-id",
    path: indexUP9GI2So5ZMeta?.path ?? "/collection/:id()",
    meta: indexUP9GI2So5ZMeta || {},
    alias: indexUP9GI2So5ZMeta?.alias || [],
    redirect: indexUP9GI2So5ZMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/collection/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: otpjrfJz2ArJoMeta?.name ?? "collection-id-otp",
    path: otpjrfJz2ArJoMeta?.path ?? "/collection/:id()/otp",
    meta: otpjrfJz2ArJoMeta || {},
    alias: otpjrfJz2ArJoMeta?.alias || [],
    redirect: otpjrfJz2ArJoMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/collection/[id]/otp.vue").then(m => m.default || m)
  },
  {
    path: _91currency_93RfLInaTn5VMeta?.path ?? "/collection/receive-funds/:currency()",
    children: [
  {
    name: additional_45info51axeYQYQPMeta?.name ?? "collection-receive-funds-currency-additional-info",
    path: additional_45info51axeYQYQPMeta?.path ?? "additional-info",
    meta: additional_45info51axeYQYQPMeta || {},
    alias: additional_45info51axeYQYQPMeta?.alias || [],
    redirect: additional_45info51axeYQYQPMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/collection/receive-funds/[currency]/additional-info.vue").then(m => m.default || m)
  },
  {
    name: amountCdPYahqeu8Meta?.name ?? "collection-receive-funds-currency-amount",
    path: amountCdPYahqeu8Meta?.path ?? "amount",
    meta: amountCdPYahqeu8Meta || {},
    alias: amountCdPYahqeu8Meta?.alias || [],
    redirect: amountCdPYahqeu8Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/collection/receive-funds/[currency]/amount.vue").then(m => m.default || m)
  },
  {
    name: index0n0wKyXjTxMeta?.name ?? "collection-receive-funds-currency",
    path: index0n0wKyXjTxMeta?.path ?? "",
    meta: index0n0wKyXjTxMeta || {},
    alias: index0n0wKyXjTxMeta?.alias || [],
    redirect: index0n0wKyXjTxMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/collection/receive-funds/[currency]/index.vue").then(m => m.default || m)
  },
  {
    name: review1TQhtHxQ6HMeta?.name ?? "collection-receive-funds-currency-review",
    path: review1TQhtHxQ6HMeta?.path ?? "review",
    meta: review1TQhtHxQ6HMeta || {},
    alias: review1TQhtHxQ6HMeta?.alias || [],
    redirect: review1TQhtHxQ6HMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/collection/receive-funds/[currency]/review.vue").then(m => m.default || m)
  }
],
    name: _91currency_93RfLInaTn5VMeta?.name ?? undefined,
    meta: _91currency_93RfLInaTn5VMeta || {},
    alias: _91currency_93RfLInaTn5VMeta?.alias || [],
    redirect: _91currency_93RfLInaTn5VMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/collection/receive-funds/[currency].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Tvw1vo6p9MMeta?.name ?? "collection-receive-funds-success-id",
    path: _91id_93Tvw1vo6p9MMeta?.path ?? "/collection/receive-funds/success/:id()",
    meta: _91id_93Tvw1vo6p9MMeta || {},
    alias: _91id_93Tvw1vo6p9MMeta?.alias || [],
    redirect: _91id_93Tvw1vo6p9MMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/collection/receive-funds/success/[id].vue").then(m => m.default || m)
  },
  {
    name: index8hR4mMpyPmMeta?.name ?? "compliances-directors-id",
    path: index8hR4mMpyPmMeta?.path ?? "/compliances-directors/:id()",
    meta: index8hR4mMpyPmMeta || {},
    alias: index8hR4mMpyPmMeta?.alias || [],
    redirect: index8hR4mMpyPmMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/compliances-directors/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: successYAgbDY7n6nMeta?.name ?? "compliances-directors-id-success",
    path: successYAgbDY7n6nMeta?.path ?? "/compliances-directors/:id()/success",
    meta: successYAgbDY7n6nMeta || {},
    alias: successYAgbDY7n6nMeta?.alias || [],
    redirect: successYAgbDY7n6nMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/compliances-directors/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: compliances_45successaGchcJfa51Meta?.name ?? "compliances-success",
    path: compliances_45successaGchcJfa51Meta?.path ?? "/compliances-success",
    meta: compliances_45successaGchcJfa51Meta || {},
    alias: compliances_45successaGchcJfa51Meta?.alias || [],
    redirect: compliances_45successaGchcJfa51Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/compliances-success.vue").then(m => m.default || m)
  },
  {
    path: compliancesnlZqKZnXHWMeta?.path ?? "/compliances",
    children: [
  {
    name: addresshBIT6xk3gIMeta?.name ?? "compliances-address",
    path: addresshBIT6xk3gIMeta?.path ?? "address",
    meta: addresshBIT6xk3gIMeta || {},
    alias: addresshBIT6xk3gIMeta?.alias || [],
    redirect: addresshBIT6xk3gIMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/compliances/address.vue").then(m => m.default || m)
  },
  {
    name: documents1fEG97lMwvMeta?.name ?? "compliances-documents",
    path: documents1fEG97lMwvMeta?.path ?? "documents",
    meta: documents1fEG97lMwvMeta || {},
    alias: documents1fEG97lMwvMeta?.alias || [],
    redirect: documents1fEG97lMwvMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/compliances/documents.vue").then(m => m.default || m)
  },
  {
    name: indexmu6Wqx85dgMeta?.name ?? "compliances",
    path: indexmu6Wqx85dgMeta?.path ?? "",
    meta: indexmu6Wqx85dgMeta || {},
    alias: indexmu6Wqx85dgMeta?.alias || [],
    redirect: indexmu6Wqx85dgMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/compliances/index.vue").then(m => m.default || m)
  },
  {
    name: ownershipHXjrsJSHAbMeta?.name ?? "compliances-ownership",
    path: ownershipHXjrsJSHAbMeta?.path ?? "ownership",
    meta: ownershipHXjrsJSHAbMeta || {},
    alias: ownershipHXjrsJSHAbMeta?.alias || [],
    redirect: ownershipHXjrsJSHAbMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/compliances/ownership.vue").then(m => m.default || m)
  },
  {
    name: reviewBvWCnysO5dMeta?.name ?? "compliances-review",
    path: reviewBvWCnysO5dMeta?.path ?? "review",
    meta: reviewBvWCnysO5dMeta || {},
    alias: reviewBvWCnysO5dMeta?.alias || [],
    redirect: reviewBvWCnysO5dMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/compliances/review.vue").then(m => m.default || m)
  },
  {
    name: shareholdersRAgsK51zIgMeta?.name ?? "compliances-shareholders",
    path: shareholdersRAgsK51zIgMeta?.path ?? "shareholders",
    meta: shareholdersRAgsK51zIgMeta || {},
    alias: shareholdersRAgsK51zIgMeta?.alias || [],
    redirect: shareholdersRAgsK51zIgMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/compliances/shareholders.vue").then(m => m.default || m)
  }
],
    name: compliancesnlZqKZnXHWMeta?.name ?? undefined,
    meta: compliancesnlZqKZnXHWMeta || {},
    alias: compliancesnlZqKZnXHWMeta?.alias || [],
    redirect: compliancesnlZqKZnXHWMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/compliances.vue").then(m => m.default || m)
  },
  {
    name: _91id_937IsfRiQfS8Meta?.name ?? "customers-id",
    path: _91id_937IsfRiQfS8Meta?.path ?? "/customers/:id()",
    meta: _91id_937IsfRiQfS8Meta || {},
    alias: _91id_937IsfRiQfS8Meta?.alias || [],
    redirect: _91id_937IsfRiQfS8Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/customers/[id].vue").then(m => m.default || m)
  },
  {
    name: indexxPO890SbQ6Meta?.name ?? "customers",
    path: indexxPO890SbQ6Meta?.path ?? "/customers",
    meta: indexxPO890SbQ6Meta || {},
    alias: indexxPO890SbQ6Meta?.alias || [],
    redirect: indexxPO890SbQ6Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: dashboard6jrmk3eHoRMeta?.name ?? "dashboard",
    path: dashboard6jrmk3eHoRMeta?.path ?? "/dashboard",
    meta: dashboard6jrmk3eHoRMeta || {},
    alias: dashboard6jrmk3eHoRMeta?.alias || [],
    redirect: dashboard6jrmk3eHoRMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: disputesWK4sBxu9NlMeta?.name ?? "disputes",
    path: disputesWK4sBxu9NlMeta?.path ?? "/disputes",
    meta: disputesWK4sBxu9NlMeta || {},
    alias: disputesWK4sBxu9NlMeta?.alias || [],
    redirect: disputesWK4sBxu9NlMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/disputes.vue").then(m => m.default || m)
  },
  {
    name: fxl4BnINAvGHMeta?.name ?? "fx",
    path: fxl4BnINAvGHMeta?.path ?? "/fx",
    meta: fxl4BnINAvGHMeta || {},
    alias: fxl4BnINAvGHMeta?.alias || [],
    redirect: fxl4BnINAvGHMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/fx.vue").then(m => m.default || m)
  },
  {
    name: indexMUmX8mAfjNMeta?.name ?? "index",
    path: indexMUmX8mAfjNMeta?.path ?? "/",
    meta: indexMUmX8mAfjNMeta || {},
    alias: indexMUmX8mAfjNMeta?.alias || [],
    redirect: indexMUmX8mAfjNMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: ProfileLHf24epEV2Meta?.name ?? "settings-Profile",
    path: ProfileLHf24epEV2Meta?.path ?? "/settings/Profile",
    meta: ProfileLHf24epEV2Meta || {},
    alias: ProfileLHf24epEV2Meta?.alias || [],
    redirect: ProfileLHf24epEV2Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/settings/Profile.vue").then(m => m.default || m)
  },
  {
    name: WebhooksftygGoluSVMeta?.name ?? "settings-Webhooks",
    path: WebhooksftygGoluSVMeta?.path ?? "/settings/Webhooks",
    meta: WebhooksftygGoluSVMeta || {},
    alias: WebhooksftygGoluSVMeta?.alias || [],
    redirect: WebhooksftygGoluSVMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/settings/Webhooks.vue").then(m => m.default || m)
  },
  {
    name: audit_45logS9ehD8D8LFMeta?.name ?? "settings-audit-log",
    path: audit_45logS9ehD8D8LFMeta?.path ?? "/settings/audit-log",
    meta: audit_45logS9ehD8D8LFMeta || {},
    alias: audit_45logS9ehD8D8LFMeta?.alias || [],
    redirect: audit_45logS9ehD8D8LFMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/settings/audit-log.vue").then(m => m.default || m)
  },
  {
    name: ip_45whitelistXp8yG0vTioMeta?.name ?? "settings-ip-whitelist",
    path: ip_45whitelistXp8yG0vTioMeta?.path ?? "/settings/ip-whitelist",
    meta: ip_45whitelistXp8yG0vTioMeta || {},
    alias: ip_45whitelistXp8yG0vTioMeta?.alias || [],
    redirect: ip_45whitelistXp8yG0vTioMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/settings/ip-whitelist.vue").then(m => m.default || m)
  },
  {
    name: rolesaAleRlXrMoMeta?.name ?? "settings-roles",
    path: rolesaAleRlXrMoMeta?.path ?? "/settings/roles",
    meta: rolesaAleRlXrMoMeta || {},
    alias: rolesaAleRlXrMoMeta?.alias || [],
    redirect: rolesaAleRlXrMoMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/settings/roles.vue").then(m => m.default || m)
  },
  {
    name: teamsWC6MKqju4jMeta?.name ?? "settings-teams",
    path: teamsWC6MKqju4jMeta?.path ?? "/settings/teams",
    meta: teamsWC6MKqju4jMeta || {},
    alias: teamsWC6MKqju4jMeta?.alias || [],
    redirect: teamsWC6MKqju4jMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/settings/teams.vue").then(m => m.default || m)
  },
  {
    name: indexb2VGgxhhl0Meta?.name ?? "stash-id",
    path: indexb2VGgxhhl0Meta?.path ?? "/stash/:id()",
    meta: indexb2VGgxhhl0Meta || {},
    alias: indexb2VGgxhhl0Meta?.alias || [],
    redirect: indexb2VGgxhhl0Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/stash/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexpAG93PiK8XMeta?.name ?? "stash",
    path: indexpAG93PiK8XMeta?.path ?? "/stash",
    meta: indexpAG93PiK8XMeta || {},
    alias: indexpAG93PiK8XMeta?.alias || [],
    redirect: indexpAG93PiK8XMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/stash/index.vue").then(m => m.default || m)
  },
  {
    name: planIcbXsETR0GMeta?.name ?? "stash-plan",
    path: planIcbXsETR0GMeta?.path ?? "/stash/plan",
    children: [
  {
    name: create3UjVpWRm53Meta?.name ?? "stash-plan-create",
    path: create3UjVpWRm53Meta?.path ?? "create",
    meta: create3UjVpWRm53Meta || {},
    alias: create3UjVpWRm53Meta?.alias || [],
    redirect: create3UjVpWRm53Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/stash/plan/create.vue").then(m => m.default || m)
  },
  {
    name: durationZ5dxgDP7FAMeta?.name ?? "stash-plan-duration",
    path: durationZ5dxgDP7FAMeta?.path ?? "duration",
    meta: durationZ5dxgDP7FAMeta || {},
    alias: durationZ5dxgDP7FAMeta?.alias || [],
    redirect: durationZ5dxgDP7FAMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/stash/plan/duration.vue").then(m => m.default || m)
  },
  {
    name: reviewJgeWqdo8AiMeta?.name ?? "stash-plan-review",
    path: reviewJgeWqdo8AiMeta?.path ?? "review",
    meta: reviewJgeWqdo8AiMeta || {},
    alias: reviewJgeWqdo8AiMeta?.alias || [],
    redirect: reviewJgeWqdo8AiMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/stash/plan/review.vue").then(m => m.default || m)
  }
],
    meta: planIcbXsETR0GMeta || {},
    alias: planIcbXsETR0GMeta?.alias || [],
    redirect: planIcbXsETR0GMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/stash/plan.vue").then(m => m.default || m)
  },
  {
    name: subaccountsmTXH8kErFJMeta?.name ?? "subaccounts",
    path: subaccountsmTXH8kErFJMeta?.path ?? "/subaccounts",
    meta: subaccountsmTXH8kErFJMeta || {},
    alias: subaccountsmTXH8kErFJMeta?.alias || [],
    redirect: subaccountsmTXH8kErFJMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/subaccounts.vue").then(m => m.default || m)
  },
  {
    name: _91id_93dF86YjIH8WMeta?.name ?? "track-id",
    path: _91id_93dF86YjIH8WMeta?.path ?? "/track/:id()",
    meta: _91id_93dF86YjIH8WMeta || {},
    alias: _91id_93dF86YjIH8WMeta?.alias || [],
    redirect: _91id_93dF86YjIH8WMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/track/[id].vue").then(m => m.default || m)
  },
  {
    name: createp2KMjeTG7JMeta?.name ?? "transactions-create",
    path: createp2KMjeTG7JMeta?.path ?? "/transactions/create",
    meta: createp2KMjeTG7JMeta || {},
    alias: createp2KMjeTG7JMeta?.alias || [],
    redirect: createp2KMjeTG7JMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/transactions/create.vue").then(m => m.default || m)
  },
  {
    name: index0oD3o1Co6zMeta?.name ?? "transactions",
    path: index0oD3o1Co6zMeta?.path ?? "/transactions",
    meta: index0oD3o1Co6zMeta || {},
    alias: index0oD3o1Co6zMeta?.alias || [],
    redirect: index0oD3o1Co6zMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/transactions/index.vue").then(m => m.default || m)
  },
  {
    name: pendingahZSzGR55UMeta?.name ?? "transactions-pending",
    path: pendingahZSzGR55UMeta?.path ?? "/transactions/pending",
    meta: pendingahZSzGR55UMeta || {},
    alias: pendingahZSzGR55UMeta?.alias || [],
    redirect: pendingahZSzGR55UMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/transactions/pending.vue").then(m => m.default || m)
  },
  {
    name: _91id_93kG532F4w9ZMeta?.name ?? "transfer-usd-success-id",
    path: _91id_93kG532F4w9ZMeta?.path ?? "/transfer/usd-success/:id()",
    meta: _91id_93kG532F4w9ZMeta || {},
    alias: _91id_93kG532F4w9ZMeta?.alias || [],
    redirect: _91id_93kG532F4w9ZMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/transfer/usd-success/[id].vue").then(m => m.default || m)
  },
  {
    name: usdofQsUgVePhMeta?.name ?? "transfer-usd",
    path: usdofQsUgVePhMeta?.path ?? "/transfer/usd",
    children: [
  {
    name: additional_45infoV8j0AlNadBMeta?.name ?? "transfer-usd-id-additional-info",
    path: additional_45infoV8j0AlNadBMeta?.path ?? ":id()/additional-info",
    meta: additional_45infoV8j0AlNadBMeta || {},
    alias: additional_45infoV8j0AlNadBMeta?.alias || [],
    redirect: additional_45infoV8j0AlNadBMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/transfer/usd/[id]/additional-info.vue").then(m => m.default || m)
  },
  {
    name: amountBZ1F6qagoMMeta?.name ?? "transfer-usd-id-amount",
    path: amountBZ1F6qagoMMeta?.path ?? ":id()/amount",
    meta: amountBZ1F6qagoMMeta || {},
    alias: amountBZ1F6qagoMMeta?.alias || [],
    redirect: amountBZ1F6qagoMMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/transfer/usd/[id]/amount.vue").then(m => m.default || m)
  },
  {
    name: indexS3O9nv2oyKMeta?.name ?? "transfer-usd-id",
    path: indexS3O9nv2oyKMeta?.path ?? ":id()",
    meta: indexS3O9nv2oyKMeta || {},
    alias: indexS3O9nv2oyKMeta?.alias || [],
    redirect: indexS3O9nv2oyKMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/transfer/usd/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: payment_45methodElU9ATvnaHMeta?.name ?? "transfer-usd-id-payment-method",
    path: payment_45methodElU9ATvnaHMeta?.path ?? ":id()/payment-method",
    meta: payment_45methodElU9ATvnaHMeta || {},
    alias: payment_45methodElU9ATvnaHMeta?.alias || [],
    redirect: payment_45methodElU9ATvnaHMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/transfer/usd/[id]/payment-method.vue").then(m => m.default || m)
  },
  {
    name: reviewAoUNShUhCkMeta?.name ?? "transfer-usd-id-review",
    path: reviewAoUNShUhCkMeta?.path ?? ":id()/review",
    meta: reviewAoUNShUhCkMeta || {},
    alias: reviewAoUNShUhCkMeta?.alias || [],
    redirect: reviewAoUNShUhCkMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/transfer/usd/[id]/review.vue").then(m => m.default || m)
  }
],
    meta: usdofQsUgVePhMeta || {},
    alias: usdofQsUgVePhMeta?.alias || [],
    redirect: usdofQsUgVePhMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/transfer/usd.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ryk2sbH6LrMeta?.name ?? "wallets-id",
    path: _91id_93ryk2sbH6LrMeta?.path ?? "/wallets/:id()",
    meta: _91id_93ryk2sbH6LrMeta || {},
    alias: _91id_93ryk2sbH6LrMeta?.alias || [],
    redirect: _91id_93ryk2sbH6LrMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/wallets/[id].vue").then(m => m.default || m)
  },
  {
    name: indexxSIUyIwN2XMeta?.name ?? "wallets",
    path: indexxSIUyIwN2XMeta?.path ?? "/wallets",
    meta: indexxSIUyIwN2XMeta || {},
    alias: indexxSIUyIwN2XMeta?.alias || [],
    redirect: indexxSIUyIwN2XMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/wallets/index.vue").then(m => m.default || m)
  }
]