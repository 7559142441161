import { ref, computed } from 'vue'

const global = {
  auth: ref(null) as any,
  user: ref(JSON.parse(sessionStorage.getItem('user') as string)),
  token: ref(sessionStorage.getItem('token')) || null,
}

export const useUser = () => {
  const id = computed(() => global.auth.value?.id ?? '')
  const isLoggedIn = computed(() => global.user.value != null)

  const logOut = (route?: any) => {
    sessionStorage.clear()
    global.user.value = null

    location.href = route || '/auth/login'
  }
  const createUser = (user: any) => {
    global.user.value = user
    sessionStorage.setItem('user', JSON.stringify(user))
    sessionStorage.setItem('token', user.token)
  }

  return { id, isLoggedIn, createUser, ...global, logOut }
}
