import { useCardsModal } from '~/src/composables/core/modals'
import { useAlert } from '~/src/composables/core/useNotification'
import { toMinorUnit } from '~/src/helpers/toMinorUnit'
import { cardApi } from '~/src/services/requests/card'
import { usePagination } from '~/src/composables/core/utils'
import { Card, FundCard } from '~/src/services/requests/card/type'

let fetchCards: any
const cardData = ref<Card | null>(null)

const filterData = {
  id: ref(''),
  created_at: ref(''),
  brand: ref(''),
  status: ref(''),
  customer_id: ref(''),
} as any
const allCards = ref([] as any[])
const loading = ref(false)

export const getAllCards = () => {
  const { metaObject, showLoadMore, loadMore, setFunction } = usePagination()

  fetchCards = async (updating = false) => {
    loading.value = true
    const res = (await cardApi.$_getAllCard(metaObject.page.value, { ...filterData })) as any
    loading.value = false
    if (res !== 'ERROR') {
      if (updating) {
        allCards.value = [...allCards.value, ...res.data.data]
      } else {
        allCards.value = res.data.data
      }

      metaObject.total.value = res.data?.meta?.total
    }
  }
  setFunction(fetchCards)
  return { fetchCards, loading, CardData: allCards, showLoadMore, loadMore, filterData }
}

const cardInfo = ref('') as any

export const toggleCard = () => {
  const loading = ref(false)
  const cardStatus = ref(cardInfo.value.status === 'ACTIVE')

  watch(cardStatus, (newVal) => {
    enableCard(newVal)
  })

  const enableCard = async (status: any) => {
    loading.value = true
    let res
    if (status) {
      res = (await cardApi.$_unfreezeCard(cardInfo.value.id)) as any
    } else {
      res = (await cardApi.$_freezeCard(cardInfo.value.id)) as any
    }

    loading.value = false
    if (res !== 'ERROR') {
      useAlert().openAlert({ type: 'SUCCESS', msg: res.data.message })
    }
  }
  return { fetchCards, loading, cardStatus }
}

export const debitCard = () => {
  const loading = ref(false)
  const error = ref('')
  const credential = {
    amount: ref(''),
  }

  const disabled = computed(() => {
    if (credential.amount.value === '') return true
    else return false
  })

  const debit = async () => {
    loading.value = true
    const res = (await cardApi.$_debitCard(
      {
        amount: toMinorUnit(credential.amount.value),
      },
      cardInfo.value.id,
    )) as any
    loading.value = false

    if (res !== 'ERROR') {
      useCardsModal().closeDebitCard()
      useAlert().openAlert({ type: 'SUCCESS', msg: res.data.message })
    }
  }
  return { credential, debit, loading, error, disabled }
}

export const getCardTransactions = () => {
  const loading = ref(false)
  const TransactionData = ref([])

  const fetchTransactions = async () => {
    const id = useRoute().params.id as string

    loading.value = true
    const res = (await cardApi.$_getCardTransactions(id)) as any
    loading.value = false
    if (res !== 'ERROR') {
      TransactionData.value = res.data.data
    }
  }
  return { fetchTransactions, loading, TransactionData }
}

export const useCard = () => {
  const funding = ref(false)
  const loading = ref(false)

  const setCardDetails = (card: any) => (cardData.value = card)
  const cardDetails = computed(() => cardData.value)

  const getCardById = async (id: string) => {
    loading.value = true
    const res = await cardApi.$_getCard(id)

    if (res !== 'ERROR') {
      cardData.value = res.data.data
    }

    loading.value = false
  }

  const fundCard = async (payload: FundCard, options?: { onFinally?: () => void }) => {
    funding.value = true

    const res = (await cardApi.$_creditCard(payload)) as any

    if (res !== 'ERROR') {
      await getCardById(payload.id)
      useAlert().openAlert({ type: 'SUCCESS', msg: res.data.message })
    }

    options?.onFinally?.()
    funding.value = false
  }

  const freezeCard = async (id: string, freezeCard: boolean) => {
    const prevValue = cardData.value?.status as Card['status']
    let res

    if (freezeCard) {
      res = (await cardApi.$_freezeCard(id)) as any
    } else {
      res = (await cardApi.$_unfreezeCard(id)) as any
    }

    if (res !== 'ERROR') {
      useAlert().openAlert({ type: 'SUCCESS', msg: res.data.message })
    } else if (cardData.value) {
      cardData.value.status = prevValue
    }
  }

  return { fundCard, cardDetails, freezeCard, getCardById, setCardDetails, funding, loading }
}
