<template>
  <form
    class="w-full overflow-y-auto"
    @submit.prevent="setFunction(create, credential)"
  >
    <div class="flex flex-col gap-6">
      <div class="field">
        <label
          for="bvn"
          class="label"
        >
          {{ $t('transfer.receiversFirstName') }}
        </label>
        <input
          id="first_name"
          v-model="credential.meta.counterparty.first_name.value"
          type="text"
          name="first_name"
          class="input"
          required
        />
      </div>

      <div class="field">
        <label
          for="bvn"
          class="label"
        >
          {{ $t('transfer.receiversLastName') }}
        </label>
        <input
          id="last_name"
          v-model="credential.meta.counterparty.last_name.value"
          type="text"
          name="last_name"
          class="input"
          required
        />
      </div>

      <div class="field">
        <label
          for="bvn"
          class="label"
        >
          {{ $t('transfer.receiversPhoneNumber') }}
        </label>
        <input
          id="phone_number"
          v-model="credential.meta.counterparty.phone_number.value"
          type="number"
          name="phone_number"
          class="input"
          required
        />
      </div>

      <div class="field">
        <label
          for="bvn"
          class="label"
        >
          {{ $t('transfer.receiversAddress') }}
        </label>
        <input
          id="address"
          v-model="credential.meta.counterparty.address.value"
          type="text"
          name="address"
          class="input"
          required
        />
      </div>

      <div class="field">
        <label
          for="bvn"
          class="label"
        >
          {{ $t('transfer.receiversCountry') }}
        </label>

        <SelectMenu
          id="country"
          v-model="credential.meta.counterparty.country.value"
          class="mt-2"
          :required="true"
          :options="countries"
          :loading="countryLoading"
        />
      </div>
    </div>

    <div class="flex justify-between w-full gap-4">
      <button
        class="btn-primary self-end px-7 min-w-[140px] mt-5 text-sm !py-3"
        type="button"
        @click="step--"
      >
        {{ $t('back') }}
      </button>
      <button
        class="btn-primary self-end px-7 min-w-[140px] mt-5 text-sm !py-3"
        :disabled="USDTransferloading"
      >
        <span v-if="!USDTransferloading"> {{ $t('next') }} </span> <Spinner v-else />
      </button>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { createUSDTransfer } from '~/src/composables/apis/transfers/usdTransfer'
import { confirmTransfer } from '~/src/composables/apis/transfers'
import { getCountries } from '~/src/composables/apis/extras/country'

const { countries, fetch, loading: countryLoading } = getCountries()
const { setFunction } = confirmTransfer()
const { credential, step, create, USDTransferloading } = createUSDTransfer()
fetch()
</script>
