<template>
  <span
    class="rounded-full border-white border-w relative"
    :class="{
      'border-2': hasBorder,
    }"
  >
    <img
      v-if="src"
      :src="src"
      :style="`width: ${size}px; height: ${size}px; object-fit: cover;`"
      alt=""
      class="!max-w-[1920px] rounded-full"
    />
    <span
      v-else
      :style="`width: ${size}px; height: ${size}px; object-fit: cover;`"
      class="flex items-center justify-center uppercase font-medium rounded-full"
      :class="`${bgColor} ${color}`"
    >
      <span :style="`font-size: ${size / 2.5}px;line-height: 1em`">
        <template v-if="name">{{ initials }}</template>
        <slot
          v-else
          name="name"
        />
      </span>
    </span>
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    src: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 44,
    },
    hasBorder: {
      type: Boolean,
      default: true,
    },
    bgColor: {
      type: String,
      default: 'secondary',
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
  computed: {
    initials() {
      const name = this.name.trim()
      const [firstName = '', lastName = ''] = name.split(' ')
      return `${firstName.charAt(0)}${lastName.charAt(0)}`
    },
  },
})
</script>
