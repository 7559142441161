<script lang="ts" setup>
import { useForm } from 'vee-validate'
import { useFuse } from '@vueuse/integrations/useFuse'
import { schema } from './type'
import { useRoles } from '~/src/composables/apis/settings/roles'
import { useSettingsModal } from '~/src/composables/core/modals'

const router = useRouter()
const { getRoles, state, inviting, sendInvite } = useRoles()

// form inputs
const { defineField, handleSubmit, resetForm, isSubmitting } = useForm({
  validationSchema: schema,
})

const elPlusConfig = (state: any) => ({
  props: {
    validateEvent: false,
    error: state.errors[0],
    required: state.required,
  },
})

const [email, emailProps] = defineField('email', elPlusConfig)
const [role, roleProps] = defineField('role', elPlusConfig)
const search = ref('')

const { roles } = toRefs(state)

const { results } = useFuse(search, roles, {
  fuseOptions: {
    keys: ['name'],
  },
  matchAllWhenSearchEmpty: true,
})

const onSubmit = handleSubmit(async (values) => {
  await sendInvite(values)
})

const handleRedirect = () => {
  useSettingsModal().closeNewTeam()
  router.push('/settings/roles')
}

getRoles()

watch(
  () => state.roles,
  (value) => {
    // set initial values of roles
    resetForm({ values: { email: '', role: value[0].id } })
  },
)
</script>

<template>
  <Modal
    :title="$t('settings.inviteTeamMembers')"
    @close="resetForm"
  >
    <form
      class="w-full mt-4"
      @submit.prevent="onSubmit"
    >
      <div class="max-h-[520px] overflow-scroll">
        <FormControl
          :label="$t('settings.enterMemberEmail')"
          v-bind="emailProps"
        >
          <InputField
            v-model="email"
            placeholder="example@example.com"
          />
        </FormControl>

        <Typography
          variant="baseMedium"
          class="mt-4"
        >
          {{ $t('settings.selectMemberRole') }}
        </Typography>

        <Typography variant="xs">
          {{ $t('settings.youCan') }}
          <button @click="handleRedirect">
            <Typography
              as="NuxtLink"
              color="text-tertiary"
              variant="xs"
            >
              {{ $t('settings.createARole') }}
            </Typography>
          </button>
        </Typography>

        <Divider />

        <Search
          v-model="search"
          :placeholder="$t('settings.searchRole')"
        />

        <div
          v-if="state.loading"
          class="flex-1 flex justify-center items-center"
        >
          <Spinner class="text-secondary !border-t-secondary" />
        </div>

        <FormControl
          v-else
          v-bind="roleProps"
        >
          <Collapse
            v-model="role"
            class="mt-3 mb-3 w-full"
            accordion
          >
            <RadioGroup
              v-model="role"
              size="large"
              class="flex flex-col w-full"
            >
              <CollapseItem
                v-for="{ item: roleItem } in results"
                :key="roleItem.id"
                :name="roleItem.id"
                class="w-full"
              >
                <template #title>
                  <div class="flex my-3 items-center">
                    <Radio
                      :label="roleItem.id"
                      class="!mr-2"
                    >
                      <span class="sr-only">
                        {{ roleItem.name }}
                      </span>
                    </Radio>
                    <div class="flex flex-col text-left">
                      <Typography
                        class="capitalize"
                        variant="baseMedium"
                      >
                        {{ roleItem.name }}
                      </Typography>
                      <Typography
                        v-if="roleItem.description"
                        color="text-grey"
                        class="mt-1"
                      >
                        {{ roleItem.description }}
                      </Typography>
                    </div>
                  </div>
                </template>
                <div class="flex flex-col bg-gray100 p-4 rounded-[10px] gap-4">
                  <div
                    v-for="(permissions, name) in roleItem.permissions"
                    :key="name"
                  >
                    <Typography
                      as="h4"
                      class="first-letter:capitalize"
                    >
                      {{ $t('settings.permissions', { name }) }}
                    </Typography>
                    <ul>
                      <Typography
                        v-for="item in permissions"
                        :key="item.key"
                        as="li"
                        variant="sm"
                        class="first-letter:capitalize"
                      >
                        {{ item.description }}
                      </Typography>
                    </ul>
                  </div>
                </div>
              </CollapseItem>
            </RadioGroup>
          </Collapse>
        </FormControl>
      </div>

      <Button
        size="large"
        class="w-full"
        :disabled="inviting || isSubmitting"
      >
        <Spinner
          v-if="inviting || isSubmitting"
          class="!w-5 !h-5"
        />
        <span v-else> {{ $t('settings.sendInvite') }} </span>
      </Button>
    </form>
  </Modal>
</template>
