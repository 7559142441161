<template>
  <Modal
    modal="$atts.modal"
    :title="$t('transactions.sendMoneyToCountry', { country: getCountryName })"
  >
    <div class="flex flex-col w-full h-full justify-between mt-6">
      <div class="flex items-center justify-center w-full" />
      <div
        v-if="!isNairaTransfer"
        class="flex w-full justify-end"
      >
        <badge name="default"> {{ $t('step', { number: step, total: 3 }) }}</badge>
      </div>

      <transition
        name="slideUp"
        appear
      >
        <StepOne v-if="step === 1" />
        <StepTwo v-else-if="step === 2" />
        <StepThree v-else-if="step === 3" />
      </transition>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import StepOne from './StepOne.vue'
import StepTwo from './StepTwo.vue'
import StepThree from './StepThree.vue'
import { createBankTransfer } from '~/src/composables/apis/transfers/bankTransfer'
import { CurrencyCode } from '~/src/types/internals'
import { countriesCode } from '~/src/constants/countriesCode'

const { step, state } = createBankTransfer()

const isNairaTransfer = computed(() => state.credential.country === CurrencyCode.NGN)
const getCountryName = computed(() => countriesCode.find((c) => c.id === state.credential.country)?.text)
</script>

<style scoped>
.slideUp-enter {
  opacity: 1;
  transform: translateY(200px);
}
.slideUp-leave-to {
  opacity: 0;
  transform: translateY(-200px);
}

.slideUp-enter-active,
.slideUp-leave-active {
  transition: all 0.35s ease;
}
</style>
