import Recase from 'better-recase'
import { Business } from '../business/types'
import { useAlert } from '../../core/useNotification'
import { CamelizeKeys, Snakify, SnakifyKeys } from '~/src/types/utilities'
import { businessApi } from '~/src/services/requests/business'
import { BusinessAddress, BusinessDetails, Director, Shareholder } from '~/src/services/requests/kyc/types'
import { DocFile, Document, Ownership } from '~/src/types/api'

export const directorCompletionState = reactive<{
  loading: boolean
  business: CamelizeKeys<Business> | null
  currentDirector: Director | null
}>({
  loading: false,
  currentDirector: null,
  business: null,
})

export const globalState = reactive<{
  businessDetails: BusinessDetails | null
  businessAddress: BusinessAddress | null
  directors: Director[]
  currentShareholder: null | Shareholder
  loadingStageKyc: boolean
  loadingDirectors: boolean
  loadingOwnership: boolean
  loadingDocs: boolean
  docs: Document[]
  ownership: Ownership
}>({
  businessDetails: null,
  businessAddress: null,
  directors: [],
  loadingStageKyc: false,
  loadingDirectors: false,
  currentShareholder: null,
  loadingOwnership: false,
  loadingDocs: false,
  docs: [],
  ownership: {
    shareCapital: 0,
    shareholder: [],
  },
})

const loading = reactive({
  addDirector: false,
  editDirector: false,
  completeDirector: false,
  directorById: false,
})

export const useKYC = () => {
  const state = reactive({
    loading: false,
  })

  const { openAlert } = useAlert()

  const getstageOneAndTwo = async () => {
    globalState.loadingStageKyc = true
    const stageOneAndTwo = await businessApi.$_getKycLevelOne()
    if (stageOneAndTwo?.status) {
      const { address, ...rest } = Recase.camelCopy(stageOneAndTwo.data) as unknown as BusinessAddress & BusinessDetails

      globalState.businessAddress = {
        address,
      }
      globalState.businessDetails = rest
    }
    globalState.loadingStageKyc = false
  }

  const getKYCDocs = async (options?: { onSuccess?: () => void }) => {
    globalState.loadingDocs = true

    const res = await businessApi.$_getKycDoc()

    if (res?.status) {
      globalState.docs = Recase.camelCopy(res.data)
      options?.onSuccess?.()
    }

    globalState.loadingDocs = false
  }

  const uploadDoc = async (data: Record<Snakify<string>, DocFile[] | string>, options?: { onSuccess?: () => void }) => {
    state.loading = true

    const res = await businessApi.$_kycUploadDoc(data)

    if (res?.status) {
      await getKYCDocs()

      useAlert().openAlert({ type: 'SUCCESS', msg: 'Documents uploaded' })
      options?.onSuccess?.()
    }

    state.loading = false
  }

  const removeDoc = async (id: string, options?: { onSuccess?: () => void }) => {
    const prevDocuments = JSON.parse(JSON.stringify(globalState.docs)) as Document[]

    globalState.docs = prevDocuments.map((item) => {
      const newList = Array.isArray(item.data) ? item.data.filter((file) => file.id !== id) : item.data
      return {
        ...item,
        data: newList,
      }
    })

    const res = await businessApi.$_deleteKycDoc(id)

    if (res?.status) {
      options?.onSuccess?.()
    } else {
      globalState.docs = prevDocuments
      useAlert().openAlert({ type: 'ERROR', msg: 'Failed: Try again' })
    }
  }

  const sendDirectorEmail = async (directorId: string) => {
    const res = await businessApi.$_sendDirectorEmail(directorId)

    if (res?.status) {
      useAlert().openAlert({ type: 'SUCCESS', msg: 'Email sent' })
    }
  }

  const getDirectors = async () => {
    globalState.loadingDirectors = true

    const res = await businessApi.$_getKycLevelTwo()

    if (res?.status) {
      globalState.directors = Recase.camelCopy(res.data) as unknown as Director[]
    }

    globalState.loadingDirectors = false
  }

  const getDirectorById = async (id: string) => {
    loading.directorById = true

    const res = await businessApi.$_getDirectorById(id)

    if (res?.status) {
      directorCompletionState.currentDirector = res.data.data.associate
      directorCompletionState.business = res.data.data.business
    }

    loading.directorById = false
  }

  const completeDirector = async (director: Director, options?: { onSuccess?: () => void }) => {
    // This is an external request to complete director details
    loading.completeDirector = true

    const formatted = Recase.snakeCopy(director) as unknown as SnakifyKeys<Director>
    const res = await businessApi.$_completeDirector(formatted)

    if (res?.status) {
      options?.onSuccess?.()
    }

    loading.completeDirector = false
  }

  const updateDirector = async (director: Director) => {
    const index = globalState.directors.findIndex((user) => user.id === director.id)
    if (index < 0) return

    const prevDirectorDetails = { ...globalState.directors[index] }
    globalState.directors[index] = director

    const formatted = Recase.snakeCopy(director) as unknown as SnakifyKeys<Director>
    const res = await businessApi.$_editKycDirector(formatted)

    if (!res?.status) {
      globalState.directors[index] = prevDirectorDetails
      useAlert().openAlert({ type: 'ERROR', msg: 'Failed: Try again' })
    }
  }

  const editDirector = async (director: Director, options?: { onSuccess?: () => void }) => {
    loading.editDirector = true

    const formatted = Recase.snakeCopy(director) as unknown as SnakifyKeys<Director>
    const res = await businessApi.$_editKycDirector(formatted)

    if (res?.status) {
      await getDirectors()
      options?.onSuccess?.()
    }

    loading.editDirector = false
  }

  const addDirector = async (director: Director, options?: { onSuccess?: () => void }) => {
    loading.addDirector = true

    const formatted = Recase.snakeCopy(director) as unknown as SnakifyKeys<Director>
    const res = await businessApi.$_kycAddDirector(formatted)

    if (res?.status) {
      await getDirectors()
      options?.onSuccess?.()
    }

    loading.addDirector = false
  }

  const getOwnership = async () => {
    globalState.loadingOwnership = true

    const res = await businessApi.$_getKycShareholder()
    if (res?.status) {
      globalState.ownership = Recase.camelCopy(res.data.data) as unknown as Ownership
    }

    globalState.loadingOwnership = false
  }

  const editShareholder = async (shareholder: Shareholder, options?: { onSuccess?: () => void }) => {
    state.loading = true

    const formatted = Recase.snakeCopy(shareholder) as unknown as SnakifyKeys<Shareholder>
    const res = await businessApi.$_kycEditShareholder(formatted)

    if (res?.status) {
      getOwnership()

      options?.onSuccess?.()
    }

    state.loading = false
  }

  const addShareholder = async (shareholder: Shareholder, options?: { onSuccess?: () => void }) => {
    state.loading = true

    const formatted = Recase.snakeCopy(shareholder) as unknown as SnakifyKeys<Shareholder>
    const res = await businessApi.$_kycShareholder(formatted)

    if (res?.status) {
      getOwnership()

      options?.onSuccess?.()
    }

    state.loading = false
  }

  const removeShareholder = async (shareholder: Shareholder, options?: { onSuccess?: () => void }) => {
    const prevShareholderList = [...globalState.ownership.shareholder]

    globalState.ownership.shareholder = prevShareholderList.filter((user) => user.id !== shareholder.id)

    const res = await businessApi.$_deleteKycShareholder(shareholder.id!)

    if (res?.status) {
      options?.onSuccess?.()
    } else {
      globalState.ownership.shareholder = prevShareholderList
      useAlert().openAlert({ type: 'ERROR', msg: 'Failed: Try again' })
    }
  }

  const submitOwnership = async (ownership: Ownership, options?: { onSuccess?: () => void }) => {
    state.loading = true

    const res = await businessApi.$_updateBusiness({
      share_capital: ownership.shareCapital!,
    })

    if (res?.status) {
      options?.onSuccess?.()
      globalState.ownership.shareCapital = ownership.shareCapital
    }

    state.loading = false
  }

  const removeDirector = async (director: Director, options?: { onSuccess?: () => void }) => {
    const prevDirectorList = [...globalState.directors]

    globalState.directors = prevDirectorList.filter((user) => user.id !== director.id)

    const res = await businessApi.$_deleteKycDirector(director.id!)

    if (res?.status) {
      options?.onSuccess?.()
    } else {
      globalState.directors = prevDirectorList
      useAlert().openAlert({ type: 'ERROR', msg: 'Failed: Try again' })
    }
  }

  const saveBusinessDetails = (data: BusinessDetails, options?: { onSuccess?: () => void }) => {
    globalState.businessDetails = data

    options?.onSuccess?.()
  }

  const updateKycOne = async (
    data: BusinessAddress & BusinessDetails,
    options?: { onSuccess?: () => void; onError?: () => void },
  ) => {
    state.loading = true
    globalState.businessAddress = {
      address: data.address,
    }

    const snakeCaseData = Recase.snakeCopy(data) as unknown as SnakifyKeys<BusinessAddress & BusinessDetails>
    const res = await businessApi.$_kycOne(snakeCaseData)

    if (res?.status) {
      openAlert({ type: 'SUCCESS', msg: res.data.message })
      options?.onSuccess?.()
    } else {
      options?.onError?.()
    }
    state.loading = false
  }

  const submitKYC = async (options?: { onSuccess?: () => void }) => {
    state.loading = true

    const res = (await businessApi.$_submitKYC()) as any
    if (res?.status) {
      options?.onSuccess?.()
      useAlert().openAlert({ type: 'SUCCESS', msg: res.data.message })
    }

    state.loading = false
  }

  return {
    getstageOneAndTwo,
    getDirectors,
    getKYCDocs,
    updateKycOne,
    addDirector,
    removeDirector,
    getOwnership,
    submitKYC,
    submitOwnership,
    addShareholder,
    removeShareholder,
    uploadDoc,
    editShareholder,
    getDirectorById,
    removeDoc,
    editDirector,
    updateDirector,
    saveBusinessDetails,
    completeDirector,
    sendDirectorEmail,
    state: globalState,
    stateLoading: loading,
    directorCompletionState,
    ...toRefs(state),
  }
}
