<template>
  <Modal
    modal="$atts.modal"
    :title="$t('customer.createCustomer')"
  >
    <div class="flex flex-col justify-between w-full h-full">
      <form
        class="w-full mt-6 overflow-y-auto"
        @submit.prevent="create"
      >
        <div class="flex flex-col gap-6">
          <FormControl :label="$t('firstName')">
            <InputField
              v-model="credential.first_name.value"
              required
            />
          </FormControl>
          <FormControl :label="$t('lastName')">
            <InputField
              v-model="credential.last_name.value"
              required
            />
          </FormControl>
          <FormControl :label="$t('email')">
            <InputField
              v-model="credential.email.value"
              type="email"
              required
            />
          </FormControl>
          <FormControl
            class="mt-2"
            :label="$t('country')"
          >
            <Select
              v-model="credential.country.value"
              class="w-full"
              size="large"
              required
              filterable
              :loading="countryLoading"
            >
              <SelectOption
                v-for="item in countries"
                :key="item.id"
                :label="item.text"
                :value="item.id"
              >
                {{ item.text }}

                <IconsCheckOutlined
                  v-if="credential.country.value === item.id"
                  class="text-success900 h-4 w-4"
                />
              </SelectOption>
            </Select>
          </FormControl>

          <button class="mt-3 btn-primary">
            <span v-if="!loading"> {{ $t('create') }} </span> <Spinner v-else />
          </button>
        </div>
      </form>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import { createTier0 } from '~/src/composables/apis/customer/tiersCreation'
import { getCountries } from '~/src/composables/apis/extras/country'

const { countries, fetch, loading: countryLoading } = getCountries()
const { credential, create, loading } = createTier0()
fetch()
</script>

<style scoped>
.slideUp-enter,
.slideUp-leave-to {
  opacity: 0;
  transform: translateY(-200px);
}

.slideUp-enter-active,
.slideUp-leave-active {
  transition: all 0.35s;
}
</style>
