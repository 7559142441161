<template>
  <Modal
    modal="$atts.modal"
    :title="$t('receipt.transactionDetails')"
    type="settings"
  >
    <div
      v-if="!loading && transactionInfo"
      class="flex flex-col items-center justify-between w-full min-h-full gap-6 mt-8 mb-0 text-sm"
    >
      <div class="rounded-[10px] border border-gray300 p-4 w-full">
        <div class="flex flex-col items-center justify-between w-full pb-4 mb-4 border-b border-divider">
          <span class="flex flex-col items-center justify-center gap-1">
            <Avatar
              :name="`${useTransactionHelper(transactionInfo, 'AIRTIMENAME')}`"
              :size="28"
              class="bg-greyBg"
            />
            <p :class="[transactionInfo.entry === 'DEBIT' ? 'text-red' : 'text-green500', 'font-medium text-lg']">
              {{ transactionInfo.currency }} {{ actualAmount }}
            </p>
          </span>
          <p class="text-sm">
            {{ useTransactionHelper(transactionInfo, 'AIRTIMENAME') }}
          </p>
        </div>

        <div class="grid grid-cols-2 gap-4">
          <div class="lines">
            <span class="text-sm text-grey">{{ $t('transactions.processedOn') }}</span>
            <span class="text-sm font-semibold text-secondary">{{
              $dayjs(transactionInfo.createdAt).locale(userLocale).format(DateFormat.Custom)
            }}</span>
          </div>
          <div class="lines">
            <span class="text-sm text-grey">{{ $t('method') }}</span>
            <span class="text-sm font-semibold text-secondary">{{ capitalized(transactionInfo.channel) }}</span>
          </div>
          <div class="lines">
            <span class="text-sm text-grey">{{ $t('wallets.wallets') }}</span>
            <span class="text-sm font-semibold text-secondary"
              >{{ $t('wallets.currencyWallet', { currency: transactionInfo.currency }) }}
            </span>
          </div>
          <div class="items-start lines">
            <span class="text-sm text-grey">{{ $t('status') }}</span>
            <statusBadge :name="transactionInfo.status" />
          </div>
        </div>
      </div>

      <div
        v-if="showReceipt()"
        class="flex items-center justify-start w-full gap-4"
      >
        <div
          v-if="!receiptLoading"
          class="flex items-center justify-start w-full gap-4"
        >
          <button
            class="flex items-center px-4 py-2 bg-disabled font-medium rounded-[10px] text-dark text-sm"
            @click="downloadReceipt(transactionInfo.id)"
          >
            <Icon
              name="share"
              class="w-4 mr-2"
            />
            {{ $t('transactions.shareReceipt') }}
          </button>
          <button
            class="flex items-center px-4 py-2 bg-disabled font-medium rounded-[10px] text-dark text-sm"
            @click="downloadReceipt(transactionInfo.id, true)"
          >
            <Icon
              name="download"
              class="w-4 mr-2"
            />
            {{ $t('transactions.downloadReceipt') }}
          </button>
        </div>
        <Spinner
          v-else
          class="text-secondary !border-t-secondary"
        />
      </div>

      <div class="rounded-[10px] border border-gray300 p-4 w-full">
        <div class="flex justify-start w-full pb-4 mb-4 text-sm font-semibold border-b border-divider">
          {{ $t('transactions.details') }}
        </div>

        <div class="flex flex-col items-start gap-4">
          <div
            v-if="transactionInfo.reference || transactionInfo.id"
            class="lines"
          >
            <span class="optionName">{{ $t('transactions.reference') }}</span>
            <span
              class="!text-secondary cursor-pointer truncate bg-greyBg py-2 px-2.5 rounded text-mono text-xs flex gap-3"
              @click="copyData(transactionInfo.reference || transactionInfo.id, referenceCopied)"
              >{{ transactionInfo.reference || transactionInfo.id }}
              <Icon
                name="copy"
                class="w-4"
            /></span>
          </div>

          <div
            v-if="isDirectTransaction"
            class="lines"
          >
            <span class="optionName">{{ $t('transactions.bankName') }}</span>
            <span class="detailName">
              {{ transactionInfo.source?.bankName || transactionInfo.counterParty?.bankName || '-' }}
            </span>
          </div>
          <div
            v-if="isDirectTransaction"
            class="lines"
          >
            <span class="optionName">{{ $t('wallets.accountNumber') }}</span>
            <span class="detailName">
              {{ transactionInfo.source?.accountNumber || transactionInfo.counterParty?.accountNumber || '-' }}
            </span>
          </div>
          <div
            v-if="transactionInfo.channel !== 'AIRTIME'"
            class="lines"
          >
            <span class="optionName">{{ $t('transactions.counterparty') }}</span>
            <span class="detailName">
              {{ transactionInfo.source?.accountName || transactionInfo.counterParty?.accountName || '-' }}
            </span>
          </div>
          <div class="lines">
            <span class="optionName">{{ $t('fee') }}</span>
            <span class="detailName"> {{ transactionInfo.currency }} {{ fromMinorUnit(transactionInfo.fee) }} </span>
          </div>
          <div
            v-if="isDirectTransaction"
            class="lines"
          >
            <span class="optionName">{{ $t('reason') }}</span>
            <span class="detailName">
              {{ transactionInfo.reason || '-' }}
            </span>
          </div>
          <div
            v-if="!transactionInfo.source?.accountName && !transactionInfo.counterParty?.accountName"
            class="lines"
          >
            <span class="optionName">{{ $t('summary') }}</span>
            <span class="detailName">
              {{ transactionInfo.summary || '-' }}
            </span>
          </div>
        </div>
      </div>

      <div class="rounded-[10px] border border-gray300 p-4 w-full">
        <div class="flex justify-start w-full pb-4 mb-4 text-sm font-semibold border-b border-divider">
          {{ $t('transactions.timeline') }}
        </div>

        <div class="flex flex-col items-start gap-4">
          <div class="flex items-center gap-3">
            <span class="w-36">{{ $t('transactions.transactionInitiated') }}</span>
            <span class="w-2 h-2 rounded-full bg-secondary" />

            <span>{{ useDateFormat(transactionInfo.createdAt, 'MMM D YYYY, h:mm a').value }}</span>
          </div>
          <div
            v-if="transactionInfo.status !== 'PENDING'"
            class="flex items-center gap-3"
          >
            <span class="w-36">{{ $t('transferTitle') }} {{ capitalized(transactionInfo.status) }}</span>
            <span
              :class="[transactionInfo.status === 'SUCCESS' ? '!bg-green500' : '!bg-red', 'h-2 w-2 rounded-full']"
            />
            <span>{{ useDateFormat(transactionInfo.updatedAt, 'MMM D YYYY, h:mm a').value }}</span>
          </div>
        </div>
      </div>
      <div class="rounded-[10px] border border-gray300 p-4 w-full mb-8">
        <div class="flex justify-start w-full pb-4 mb-4 text-sm font-semibold border-b border-divider">
          {{ $t('wallets.wallets') }}
        </div>

        <div class="grid items-start grid-cols-2 gap-4">
          <div class="flex flex-col center">
            <span class="text-sm font-medium">{{ $t('transactions.previousBalance') }}</span>
            <span class="text-base">
              {{ transactionInfo.currency }} {{ fromMinorUnit(transactionInfo.ledger.previousBalance) }}
            </span>
          </div>
          <div class="flex flex-col center">
            <span class="text-sm font-medium">{{ $t('transactions.currentBalance') }}</span>
            <span class="text-base">
              {{ transactionInfo.currency }} {{ fromMinorUnit(transactionInfo.ledger.currentBalance) }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="flex w-full center"
    >
      <Spinner class="text-secondary !border-t-secondary" />
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import { useDateFormat } from '@vueuse/core'
import { fromMinorUnit } from '~/src/helpers/fromMinorUnit'
import { capitalized } from '~/src/helpers/capitalized'
import { ShowTransactionDetails } from '~/src/composables/apis/transfers/index'
import {
  DateFormat,
  TransactionChannel,
  TransactionEntryType,
  TransactionStatus,
  TransactionType,
} from '~/src/types/internals'
import { copyToClipboard } from '~/src/composables/utils/useFile/index'
import { useTransactionHelper } from '~/src/composables/core/utils'

const { transactionInfo, getTransactionDetails, loading, downloadReceipt, receiptLoading } = ShowTransactionDetails()
const { copyData } = copyToClipboard()
const { t, locale } = useI18n()
const userLocale = locale.value

getTransactionDetails(transactionInfo.value?.id)

const showReceipt = () => {
  if (transactionInfo.value?.status === TransactionStatus.SUCCESS) {
    switch (transactionInfo.value.type) {
      case TransactionType.TRANSFER:
        return true
      case TransactionType.COLLECTION:
        return true
      default:
        return false
    }
  } else {
    return false
  }
}

const isDirectTransaction = computed(() => {
  return (
    transactionInfo.value?.channel !== TransactionChannel.AIRTIME &&
    transactionInfo.value?.channel !== TransactionChannel.ACCOUNT &&
    transactionInfo.value?.channel !== TransactionChannel.CARD
  )
})

const referenceCopied = t('referenceCopied')

const actualAmount = computed(() => {
  if (transactionInfo.value?.entry === TransactionEntryType.DEBIT) {
    return fromMinorUnit(String(transactionInfo.value.amount + transactionInfo.value.fee))
  }

  return fromMinorUnit(String(transactionInfo.value?.amount - transactionInfo.value?.fee))
})
</script>

<style scoped>
.lines {
  @apply flex flex-col gap-2;
}
.optionName {
  @apply font-semibold text-secondary;
}
.detailName {
  @apply text-secondary text-sm;
}
</style>
