<template>
  <div class="relative inline-block">
    <Typography
      as="label"
      variant="smBold"
      class="inline-flex gap-2 px-3 py-2 rounded-xl bg-primary"
      :for="id"
    >
      <slot name="label">
        {{ label }}
        <Icon
          aria-hidden
          name="upload"
          class="w-4"
        />
      </slot>
    </Typography>
    <input
      :id="id"
      v-bind="$attrs"
      type="file"
      :accept="fileType"
      class="inset-0 absolute opacity-0"
      @change="handleChange"
    />
  </div>
</template>

<script setup lang="ts">
import { useAlert } from '~/src/composables/core/useNotification'
import { calculateFileSize } from '~/src/helpers'
defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<{ fileType?: string; label?: string; id: string; limit?: number }>(), {
  fileType: undefined,
  label: undefined,
  limit: 5 * 1000 * 1000, // 5MB
})
const emit = defineEmits(['change'])

const handleChange = (event: Event) => {
  const files = (event.target as HTMLInputElement).files
  if (!files) return

  for (const file of files) {
    if (file.size > props.limit) {
      useAlert().openAlert({
        type: 'ERROR',
        msg: `${file.name} should be less than ${calculateFileSize(props.limit)}`,
      })
      return
    }
  }
  emit('change', event)
}
</script>
