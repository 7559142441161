import { GATEWAY_ENDPOINT } from './SetupBase'

export const fxApi = {
  $_ExchangeCurrency: (credential) => {
    const url = '/fx'
    return GATEWAY_ENDPOINT.post(url, credential)
  },
  $_GeneralQuote: (credential) => {
    const url = '/fx/quote'
    return GATEWAY_ENDPOINT.post(url, credential)
  },
}
