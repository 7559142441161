<template>
  <Modal
    modal="$atts.modal"
    :title="$t('wallets.receiveFunds')"
  >
    <form
      class="w-full overflow-y-auto mt-8"
      @submit.prevent="create"
    >
      <div class="flex flex-col gap-6">
        <!-- <div class="field">
					<label for="country" class="label">
						Select Country
					</label>

					<SelectMenu v-model="credential.country.value" label="text" :options="filteredCountries" :loading="countryLoading" />
				</div> -->
        <div class="field">
          <label
            for="bvn"
            class="label"
          >
            {{ $t('wallets.selectOperator') }}
          </label>
          <SelectMenu
            id="code"
            v-model="credential.bank_code.value"
            :disabled="!credential.country.value"
            :options="banks"
            name="code"
            :loading="bankLoading"
            required
          />
        </div>
        <div class="field">
          <label
            for="amount"
            class="label"
          >
            {{ $t('amount') }}
          </label>
          <input
            id="amount"
            v-model="credential.amount.value"
            name="amount"
            class="input"
            type="text"
            required
            @input="formatToCurrency(credential.amount)"
          />
        </div>
        <div
          v-if="credential.country.value"
          class="field"
        >
          <label
            for="phone"
            class="label"
          >
            {{ $t('transactions.phoneNumber') }}
          </label>
          <div class="flex w-full input !p-0">
            <input
              id="phone"
              v-model="callingCode"
              name="code"
              class="px-4 w-20 bg-black text-white rounded-l-lg"
              required
              :disabled="true"
            />
            <input
              id="account_number"
              v-model="credential.account_number.value"
              type="text"
              name="account_number"
              class="input !border-0 !bg-transparent !rounded-l-none w-full"
              required
            />
          </div>
        </div>
        <div class="field">
          <label
            for="reason"
            class="label"
          >
            {{ $t('reason') }}
          </label>
          <input
            id="reason"
            v-model="credential.reason.value"
            name="reason"
            class="input"
            type="text"
            required
          />
        </div>
        <button
          class="btn-primary mt-3"
          :disabled="disabled"
        >
          {{ $t('submit') }}
        </button>
      </div>
    </form>
  </Modal>
</template>

<script lang="ts" setup>
import { formatToCurrency } from '~/src/composables/utils/useCurrency'
import { useReceiveFunds } from '~/src/composables/apis/transfers/receiveFunds'
const { credential, create, banks, bankLoading, disabled, callingCode } = useReceiveFunds()
</script>

<style scoped>
.slideUp-enter,
.slideUp-leave-to {
  opacity: 0;
  transform: translateY(-200px);
}

.slideUp-enter-active,
.slideUp-leave-active {
  transition: all 0.35s;
}
</style>
