<template>
  <el-checkbox-group
    class="w-full"
    v-bind="$attrs"
  >
    <template
      v-for="(_, slot) of $slots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </el-checkbox-group>
</template>
