<template>
  <form
    class="w-full overflow-y-auto"
    @submit.prevent="step++"
  >
    <div class="flex flex-col gap-6">
      <div
        v-if="credential.meta.scheme.value"
        class="field"
      >
        <label
          for="bank_code"
          class="label"
        >
          {{ $t('selectBank') }}
        </label>
        <SuggestionInput
          v-model="credential.bank_code.value"
          :options="banks"
          :loading="bankLoading"
          :placeholder="$t('selectBank')"
          required
        />
      </div>
      <div class="field relative">
        <label
          for="account_number"
          class="label w-full flex"
        >
          {{ $t('wallets.accountNumber') }}
          <span class="ml-auto text-secondary">{{ resolved }}</span>
        </label>
        <input
          id="account_number"
          v-model="credential.account_number.value"
          type="text"
          name="account_number"
          class="input"
          required
          :disabled="!Object.keys(credential.bank_code.value).length || AccLoading"
        />
        <Spinner
          v-if="AccLoading"
          class="absolute !border-gray950 !border-t-black top-[34px] right-4"
        />
      </div>
      <div class="field">
        <label
          for="currency"
          class="label"
        >
          {{ $t('amount') }}
        </label>
        <div class="flex w-full border border-gray150 rounded-xl">
          <SelectMenu
            id="currency"
            v-model="credential.currency.value"
            :value="credential.currency.value"
            variant="black"
            :options="[{ text: 'USD', id: 'USD' }]"
            name="currency"
            class="px-4 w-24 !rounded-r-none"
            required
          />
          <input
            id="amount"
            v-model="credential.amount.value"
            type="text"
            name="amount"
            class="w-full px-4 input"
            required
            @input="formatToCurrency(credential.amount)"
          />
        </div>
      </div>

      <div class="field">
        <label
          for="reason"
          class="label"
        >
          {{ $t('reason') }}
        </label>
        <input
          id="reason"
          v-model="credential.reason.value"
          name="reason"
          class="input"
        />
      </div>

      <button
        class="btn-primary mt-3"
        :disabled="USDTransferloading"
      >
        {{ $t('next') }}
      </button>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { formatToCurrency } from '~/src/composables/utils/useCurrency'
import { createUSDTransfer } from '~/src/composables/apis/transfers/usdTransfer'

const { credential, USDTransferloading, AccLoading, step, resolved, banks, bankLoading } = createUSDTransfer()
</script>

<style scoped>
.slideUp-enter {
  opacity: 0;
  /* transform: translateY(200px); */
}
.slideUp-leave-to {
  opacity: 0;
  /* transform: translateY(-200px); */
}

.slideUp-enter-active,
.slideUp-leave-active {
  transition: all 0.35s ease;
}
</style>
