import { useClipboard } from '@vueuse/core'
import { ref } from 'vue'
import { useAlert } from '../../core/useNotification'

export const toBase64 = (file: any): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export const copyToClipboard = () => {
  const source = ref('')

  const { copy, copied, isSupported } = useClipboard({ source })

  const copyData = (data: any, msg: string) => {
    if (!isSupported) return useAlert().openAlert({ type: 'ERROR', msg: "Seems like your device doesn't clipboarding" })
    source.value = data
    copy()
    if (copied) return useAlert().openAlert({ type: 'SUCCESS', msg })
  }

  return { copyData }
}
