export const toMinorUnit = (val: string) => {
  val = val.replace(/,/g, '')
  // eslint-disable-next-line eqeqeq
  if (val == '0') {
    return parseFloat(val) * 100
  }

  if (!val || isNaN(parseInt(val))) {
    throw new Error('A number is required')
  }
  return parseFloat((Number(val) * 100).toFixed(0))
}
