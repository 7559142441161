<template>
  <el-form-item
    class="form-control"
    v-bind="$attrs"
  >
    <template #label>
      <slot name="label">
        <Typography
          v-if="$attrs.label"
          as="span"
          :color="$attrs.error ? 'text-red' : 'text-secondary'"
          class="mb-1.5"
          variant="smMedium"
        >
          {{ $attrs.label }}
        </Typography>
      </slot>
    </template>
    <slot />
    <template #error>
      <slot name="error">
        <Typography
          v-if="$attrs.error"
          variant="xs"
          color="text-red"
          class="mt-1"
        >
          {{ $attrs.error }}
        </Typography>
      </slot>
    </template>
  </el-form-item>
</template>
<style>
.form-control.el-form-item .el-form-item__label {
  @apply h-auto justify-start;
}
.form-control.el-form-item {
  @apply flex-col mb-0;
}

.form-control.el-form-item.is-error .el-input__wrapper,
.form-control.el-form-item.is-error .el-input__wrapper:hover,
.form-control.el-form-item.is-error .el-input__wrapper:focus,
.form-control.el-form-item.is-error .el-input__wrapper.is-focus,
.form-control.el-form-item.is-error .el-textarea__inner,
.form-control.el-form-item.is-error .el-textarea__inner:hover,
.form-control.el-form-item.is-error .el-textarea__inner:focus,
.form-control.el-form-item.is-error .el-textarea__inner.is-focus,
.form-control.el-form-item.is-error .el-select__wrapper,
.form-control.el-form-item.is-error .el-select__wrapper:hover,
.form-control.el-form-item.is-error .el-select__wrapper:focus,
.form-control.el-form-item.is-error .el-select__wrapper.is-focus {
  --el-color-danger: var(--red);
  box-shadow: 0 0 0 1px var(--el-color-danger) inset !important;
}
.form-control.el-form-item .el-input__validateIcon {
  display: none;
}
</style>
