<template>
  <Modal width="50rem">
    <figure class="text-secondary relative overflow-hidden flex justify-end items-center">
      <WirePayIllustration class="h-80 lg:h-full fixed w-full lg:w-[400px] -left-2 top-0 lg:bottom-0" />
      <figcaption class="mt-80 lg:py-14 lg:w-[45%] lg:pr-4 lg:mt-auto">
        <Typography
          as="h1"
          variant="xxlBold"
          >{{ $t('welcome.tryWirepay') }}</Typography
        >
        <Typography variant="base">
          {{ $t('welcome.redirectToWirepay') }}
        </Typography>

        <ul>
          <li
            v-for="item in list"
            :key="item"
            class="flex items-center gap-3 mt-2"
          >
            <span
              aria-hidden
              class="h-6 w-6 bg-primary rounded-full inline-flex justify-center items-center"
            >
              <IconsCheckOutlined class="text-secondary h-4 w-4" />
            </span>

            <Typography as="span">{{ item }}</Typography>
          </li>
        </ul>

        <a
          ref="redirect"
          class="hidden"
          href="https://sendwirepay.com/"
          target="_blank"
        ></a>

        <Button
          size="large"
          class="w-full mt-10"
          @click="handleClick"
        >
          Yes, proceed
        </Button>
      </figcaption>
    </figure>
  </Modal>
</template>

<script lang="ts" setup>
import WirePayIllustration from '~/src/components/icons/wirepay-illustration.vue'
import { useAuthModal } from '~/src/composables/core/modals'
import { ROUTES_NAMES } from '~/src/routes'

const { closeUnRegisteredBusiness } = useAuthModal()
const router = useRouter()

const { t } = useI18n()
const list = [
  t('welcome.multiCurrencyAccounts'),
  t('welcome.convertMoney'),
  t('welcome.virtualCard'),
  t('welcome.internationalPayments'),
]

const redirect = ref<null | HTMLAnchorElement>(null)

const handleClick = () => {
  closeUnRegisteredBusiness()
  redirect.value?.click()
  router.push({ name: ROUTES_NAMES.pickAccount })
}
</script>
