import { AxiosResponse } from 'axios'
import Recase from 'better-recase'
import {
  Recipient,
  RecipientListResponse,
  RecipientResponse,
  USDTrackingResponse,
  USDTransfer,
  USDTransferResponse,
} from './type'
import { GATEWAY_ENDPOINT, GATEWAY_ENDPOINT_V2, UN_AUTH_GATEWAY_ENDPOINT } from '~/src/services/SetupBase'
import { TransactionChannel } from '~/src/types/internals'
import { ServerResponse } from '~/src/types/api'

export const transferApi = {
  $_createRecipient: (payload: Recipient): Promise<AxiosResponse<ServerResponse<RecipientResponse>>> | 'ERROR' => {
    const url = `collections/virtual-account/counterparties`
    return GATEWAY_ENDPOINT.post(url, Recase.snakeCopy(payload), {
      transformResponse: (data: string) => Recase.camelCopy(JSON.parse(data)),
    })
  },

  $_getRecipients: (accountId: string): Promise<AxiosResponse<RecipientListResponse>> | 'ERROR' => {
    const url = `collections/virtual-account/${accountId}/counterparties`

    return GATEWAY_ENDPOINT.get(url, {
      transformResponse: (data: string) => Recase.camelCopy(JSON.parse(data)),
    })
  },

  $_getSupportedRails: (id: string): Promise<AxiosResponse<ServerResponse<TransactionChannel[]>>> | 'ERROR' => {
    const url = `collections/virtual-account/${id}/rails`

    return GATEWAY_ENDPOINT.get(url, {
      transformResponse: (data: string) => Recase.camelCopy(JSON.parse(data)),
    })
  },

  $_initiateTransfer: (payload: USDTransfer): Promise<AxiosResponse<USDTransferResponse>> | 'ERROR' => {
    const url = `transfers/USD`

    return GATEWAY_ENDPOINT_V2.post(url, Recase.snakeCopy(payload), {
      transformResponse: (data: string) => Recase.camelCopy(JSON.parse(data)),
    })
  },

  $_transactionTracking: (id: string): Promise<AxiosResponse<USDTrackingResponse>> | 'ERROR' => {
    const url = `/transactions/track/${id}`

    return UN_AUTH_GATEWAY_ENDPOINT.get(url, {
      transformResponse: (data: string) => Recase.camelCopy(JSON.parse(data)),
    })
  },
}
