<script lang="ts" setup>
import { createBankTransfer } from '~/src/composables/apis/transfers/bankTransfer'
import allCountries from '~/src/constants/countries'
import { DateFormat } from '~/src/types/internals'

const { step, state } = createBankTransfer()

const disabled = computed(() => {
  const sender = state.credential.meta.sender

  return !sender.country
})

const handleSubmit = () => {
  step.value = step.value + 1
}

onMounted(() => {
  state.credential.meta.sender.country = allCountries.find((country) => country.id === 'NG')?.id ?? ''
})
</script>

<template>
  <form
    class="w-full overflow-y-auto"
    @submit.prevent="handleSubmit"
  >
    <div class="flex flex-col gap-6">
      <FormControl :label="$t('transfer.sendersFirstName')">
        <InputField
          v-model="state.credential.meta.sender.first_name"
          required
        />
      </FormControl>
      <FormControl :label="$t('transfer.sendersLastName')">
        <InputField
          v-model="state.credential.meta.sender.last_name"
          required
        />
      </FormControl>
      <FormControl :label="$t('transfer.sendersDOB')">
        <DatePicker
          v-model="state.credential.meta.sender.dob"
          size="large"
          class="!w-full"
          prefix-icon=""
          required
          :value-format="DateFormat.DefaultAlt"
        />
      </FormControl>

      <FormControl :label="`${$t('transfer.senders')} ${$t('email')}`">
        <InputField
          v-model="state.credential.meta.sender.email"
          type="email"
          required
        />
      </FormControl>

      <FormControl :label="$t('Sender\'s Select Country')">
        <!-- TODO: add translations for above -->
        <Select
          v-model="state.credential.meta.sender.country"
          filterable
          class="w-full"
          size="large"
        >
          <SelectOption
            v-for="item in allCountries"
            :key="item.id"
            :label="item.text"
            :value="item.id"
          >
            {{ item.text }}

            <IconsCheckOutlined
              v-if="state.credential.meta.sender.country === item.id"
              class="text-success900 h-4 w-4"
            />
          </SelectOption>
        </Select>
      </FormControl>

      <!-- TODO: use google address for sender details -->
      <FormControl :label="$t('transfer.sendersAddress')">
        <InputField
          v-model="state.credential.meta.sender.address"
          type="textarea"
          :row="3"
          required
        />
      </FormControl>

      <FormControl :label="$t('transfer.sendersState')">
        <InputField
          v-model="state.credential.meta.sender.state"
          required
        />
      </FormControl>

      <FormControl :label="$t('transfer.sendersCity')">
        <InputField
          v-model="state.credential.meta.sender.city"
          required
        />
      </FormControl>

      <FormControl :label="$t('transfer.sendersPostalCode')">
        <InputField
          v-model="state.credential.meta.sender.postal_code"
          required
        />
      </FormControl>

      <FormControl :label="$t('transfer.sendersPhoneNumber')">
        <InputFieldPhone
          id="sender-phone"
          v-model="state.credential.meta.sender.phone_number"
        />
      </FormControl>

      <div class="flex w-full mt-5 gap-4">
        <Button
          class="flex-1"
          size="large"
          variant="secondary"
          type="button"
          @click="step--"
        >
          {{ $t('back') }}
        </Button>
        <Button
          class="flex-1"
          size="large"
          :disabled="disabled"
        >
          {{ $t('next') }}
        </Button>
      </div>
    </div>
  </form>
</template>
