<template>
  <form
    class="w-full overflow-y-auto"
    @submit.prevent="step++"
  >
    <div class="flex flex-col gap-6">
      <div class="field">
        <label
          for="bvn"
          class="label"
        >
          {{ $t('transfer.sendersFirstName') }}
        </label>
        <input
          id="first_name"
          v-model="credential.meta.sender.first_name.value"
          type="text"
          name="first_name"
          class="input"
          required
        />
      </div>

      <div class="field">
        <label
          for="bvn"
          class="label"
        >
          {{ $t('transfer.sendersLastName') }}
        </label>
        <input
          id="last_name"
          v-model="credential.meta.sender.last_name.value"
          type="text"
          name="last_name"
          class="input"
          required
        />
      </div>

      <div class="field">
        <label
          for="bvn"
          class="label"
        >
          {{ $t('transfer.sendersPhoneNumber') }}
        </label>
        <input
          id="phone_number"
          v-model="credential.meta.sender.phone_number.value"
          type="text"
          name="phone_number"
          class="input"
          required
        />
      </div>

      <div class="field">
        <label
          for="bvn"
          class="label"
        >
          {{ $t('transfer.sendersAddress') }}
        </label>
        <input
          id="address"
          v-model="credential.meta.sender.address.value"
          type="text"
          name="address"
          class="input"
          required
        />
      </div>

      <div class="field">
        <label
          for="bvn"
          class="label"
        >
          {{ $t('transfer.sendersCountry') }}
        </label>

        <SelectMenu
          id="country"
          v-model="credential.meta.sender.country.value"
          class="mt-2"
          :required="true"
          :options="countries"
          :loading="countryLoading"
        />
      </div>
    </div>
    <div class="flex justify-between w-full gap-4">
      <button
        class="btn-primary self-end px-7 min-w-[140px] mt-5 text-sm !py-3"
        type="button"
        @click="step--"
      >
        {{ $t('back') }}
      </button>
      <button class="btn-primary self-end px-7 min-w-[140px] mt-5 text-sm !py-3">{{ $t('next') }}</button>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { createUSDTransfer } from '~/src/composables/apis/transfers/usdTransfer'
import { getCountries } from '~/src/composables/apis/extras/country'

const { countries, fetch, loading: countryLoading } = getCountries()
const { credential, step } = createUSDTransfer()
fetch()
</script>

<style scoped></style>
