<template>
  <form
    class="w-full overflow-y-auto"
    @submit.prevent="step++"
  >
    <div class="flex flex-col gap-6">
      <div class="field">
        <label
          for="Phone"
          class="label"
        >
          {{ $t('firstName') }}
        </label>
        <input
          id="first_name"
          v-model="credential.first_name.value"
          type="text"
          name="first_name"
          class="input"
          required
        />
      </div>
      <div class="field">
        <label
          for="Phone"
          class="label"
        >
          {{ $t('lastName') }}
        </label>
        <input
          id="last_name"
          v-model="credential.last_name.value"
          type="text"
          name="last_name"
          class="input"
          required
        />
      </div>
      <div class="field">
        <label
          for="Phone"
          class="label"
        >
          {{ $t('email') }}
        </label>
        <input
          id="email"
          v-model="credential.email.value"
          type="email"
          name="email"
          class="input"
          required
        />
      </div>
      <div class="field">
        <label
          for="Phone"
          class="label"
        >
          {{ $t('customer.identificationNumber') }} (BVN / SSN)
        </label>
        <input
          id="identification_number"
          v-model="credential.identification_number.value"
          type="text"
          name="identification_number"
          class="input"
          required
        />
      </div>
      <div class="field">
        <label
          for="Phone"
          class="label"
        >
          {{ $t('accountCreation.phoneNumber') }}
        </label>
        <input
          id="phone_number"
          v-model="credential.phone_number.value"
          type="text"
          name="phone_number"
          class="input"
          required
        />
      </div>
      <div class="field">
        <label
          for="Phone"
          class="label"
        >
          {{ $t('customer.dateOfBirth') }}
        </label>
        <input
          id="dob"
          v-model="credential.dob.value"
          type="date"
          name="dob"
          class="input"
          required
        />
      </div>
    </div>

    <button class="ModalBtn">{{ $t('next') }}</button>
  </form>
</template>

<script lang="ts" setup>
import { createCustomer } from '~/src/composables/apis/customer'

const { credential, step } = createCustomer()
</script>

<style scoped></style>
