<template>
  <el-input
    v-bind="$attrs"
    class="input-field"
    size="large"
  >
    <template
      v-for="(_, slot) of $slots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </el-input>
</template>
<style>
.input-field .el-input-group__append,
.input-field .el-input-group__prepend {
  --el-fill-color-light: var(--light);
  --el-input-border-radius: 8px;
  --el-input-border-color: var(--gray10);
  --el-color-info: var(--gray800);
}

.input-field .el-input__wrapper.is-focus {
  @apply shadow-1xl border border-primary;
}

.input-field .el-input__wrapper,
.input-field .el-textarea {
  @apply rounded-lg border border-transparent transition duration-300;
  --el-input-border-radius: 8px;
  --el-input-border-color: var(--gray10);
  --el-input-focus-border-color: var(--primary);
  ---el-input-text-color: var(--text-secondary);
  --el-input-icon-color: var(--grey);
}

.input-field .el-textarea > textarea {
  --el-input-border-color: var(--gray10);
  @apply rounded-lg px-4 py-3;
}
</style>
