<script lang="ts" setup>
import { confirmTransfer } from '~/src/composables/apis/transfers'
import { createBankTransfer } from '~/src/composables/apis/transfers/bankTransfer'
import { IDENTIFICATION_OPTIONS } from '~/src/constants'
import allCountries from '~/src/constants/countries'
import { DateFormat } from '~/src/types/internals'

const { state, create, NairaTransferLoading, step } = createBankTransfer()
const { setFunction } = confirmTransfer()

const disabled = computed(() => {
  const id = state.credential.meta.sender.id

  return id.issued_at && id.issued_date && id.expiration_date
})

const countryNames = allCountries.map(({ text }) => ({ id: text, text }))

const handleSubmit = () => {
  setFunction(create, state.credential)
}
</script>

<template>
  <form
    class="w-full overflow-y-auto"
    @submit.prevent="handleSubmit"
  >
    <div class="flex flex-col gap-6">
      <FormControl :label="$t('nationality')">
        <Select
          v-model="state.credential.meta.sender.nationality"
          filterable
          class="w-full"
          size="large"
        >
          <SelectOption
            v-for="item in allCountries"
            :key="item.id"
            :label="item.text"
            :value="item.id"
          >
            {{ item.text }}

            <IconsCheckOutlined
              v-if="state.credential.meta.sender.nationality === item.id"
              class="text-success900 h-4 w-4"
            />
          </SelectOption>
        </Select>
      </FormControl>

      <FormControl :label="$t('customer.selectIdType')">
        <Select
          v-model="state.credential.meta.sender.id.type"
          filterable
          class="w-full"
          size="large"
        >
          <SelectOption
            v-for="item in IDENTIFICATION_OPTIONS"
            :key="item.id"
            :label="item.text"
            :value="item.id"
          >
            {{ item.text }}

            <IconsCheckOutlined
              v-if="state.credential.meta.sender.id.type === item.id"
              class="text-success900 h-4 w-4"
            />
          </SelectOption>
        </Select>
      </FormControl>

      <FormControl :label="$t('customer.countryIssued')">
        <Select
          v-model="state.credential.meta.sender.id.issued_at"
          filterable
          class="w-full"
          size="large"
        >
          <SelectOption
            v-for="item in countryNames"
            :key="item.id"
            :label="item.text"
            :value="item.id"
          >
            {{ item.text }}

            <IconsCheckOutlined
              v-if="state.credential.meta.sender.id.issued_at === item.id"
              class="text-success900 h-4 w-4"
            />
          </SelectOption>
        </Select>
      </FormControl>

      <FormControl :label="$t('customer.identificationNumber')">
        <InputField
          v-model="state.credential.meta.sender.id.number"
          type="number"
          required
        />
      </FormControl>

      <FormControl :label="$t('dateIssued')">
        <DatePicker
          v-model="state.credential.meta.sender.id.issued_date"
          size="large"
          class="!w-full"
          prefix-icon=""
          required
          :value-format="DateFormat.DefaultAlt"
        />
      </FormControl>

      <FormControl :label="$t('cards.expiryDate')">
        <DatePicker
          v-model="state.credential.meta.sender.id.expiration_date"
          size="large"
          class="!w-full"
          prefix-icon=""
          required
          :value-format="DateFormat.DefaultAlt"
        />
      </FormControl>

      <div class="flex justify-between w-full gap-4">
        <Button
          class="flex-1"
          size="large"
          variant="secondary"
          type="button"
          @click="step--"
        >
          {{ $t('back') }}
        </Button>

        <Button
          class="btn-primary"
          :disabled="!disabled || NairaTransferLoading"
        >
          <span v-if="!NairaTransferLoading"> {{ $t('submit') }} </span> <Spinner v-else />
        </Button>
      </div>
    </div>
  </form>
</template>
