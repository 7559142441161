import { PAGINATION_LIMIT } from '~/src/constants'

export const usePagination = () => {
  let fetchFunc: (id: boolean) => void | Promise<void> = async () => {}
  const metaObject = {
    page: ref(1),
    page_size: PAGINATION_LIMIT,
    total: ref(0),
  }

  const showLoadMore = computed(() => {
    const sigh = metaObject.total.value - metaObject.page.value * metaObject.page_size
    return sigh > 0
  })
  const next = async () => {
    if (metaObject.page.value >= metaObject.total.value / metaObject.page_size) return
    metaObject.page.value++
    await fetchFunc(false)
  }

  const prev = async () => {
    if (metaObject.page.value <= 1) return
    metaObject.page.value--
    await fetchFunc(false)
  }

  const first = async () => {
    metaObject.page.value = 1
    await fetchFunc(false)
  }

  const loadMore = async () => {
    metaObject.page.value++
    await fetchFunc(true)
    // scrollToBottom()
  }

  const setFunction = (data: () => void) => {
    fetchFunc = data
  }

  return { metaObject, showLoadMore, loadMore, setFunction, next, prev, first }
}
export function scrollToBottom() {
  // For modern browsers
  document.documentElement.scrollTo({
    top: document.documentElement.scrollHeight,
    behavior: 'smooth',
  })

  // For older browsers
  document.body.scrollTo(0, document.body.scrollHeight)
}

export const useTransactionHelper = (data: any, variant = '') => {
  if (data.channel === 'DOM' || data.channel === 'CASHPICKUP' || data.channel === 'WALLET') {
    if (data.type === 'FUNDING') return '-'
    if (data.type === 'TRANSFER') return data.summary
    return data.summary?.split('-')[1]?.trim()?.slice(1, -1)
  } else if (data.channel === 'AIRTIME') {
    if (variant === 'AIRTIME_NAME') return data.summary?.split('-')[0]?.trim()
    return data.summary.split('-')[1]?.trim()
  } else if (data.channel === 'MOMO') {
    return data.summary.split('-')[0]?.split('|')[1]
  } else if (data.channel === 'BANKTRANSFER' || data.channel === 'CARD') {
    return data.summary.split('-')[0]?.split('|')[1]
  } else if (data.channel === 'ACCOUNT') {
    return data.summary.split('from')[1]
  }
  return data.channel
}
