import { AxiosResponse } from 'axios'
import Recase from 'better-recase'
import { COUNTRY_ENDPOINT, GATEWAY_ENDPOINT } from '../../SetupBase'
import { City, Country, CountryUnit, State } from './type'
import { ServerResponse } from '~/src/types/api'
import { SnakifyKeys } from '~/src/types/utilities'

export const countryApi = {
  $_getCities: (countryIso: string, stateIso: string): Promise<AxiosResponse<City[]>> | 'ERROR' => {
    const url = `countries/${countryIso}/states/${stateIso}/cities`
    return COUNTRY_ENDPOINT.get(url, {
      transformResponse: (data) => Recase.camelCopy(JSON.parse(data)),
    })
  },
  $_getStateByCountryIso: (iso: string): Promise<AxiosResponse<State[]>> | 'ERROR' => {
    const url = `countries/${iso}/states`
    return COUNTRY_ENDPOINT.get(url, {
      transformResponse: (data) => Recase.camelCopy(JSON.parse(data)),
    })
  },
  $_getAllCountries: (): Promise<AxiosResponse<CountryUnit[]>> | 'ERROR' => {
    const url = '/countries'
    return COUNTRY_ENDPOINT.get(url, {
      transformResponse: (data) => Recase.camelCopy(JSON.parse(data)),
    })
  },
  $_getSupportedCountries: (): Promise<ServerResponse<{ data: SnakifyKeys<Country>[] }>> => {
    const url = '/countries'
    return GATEWAY_ENDPOINT.get(url)
  },
}
