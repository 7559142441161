export const fromMinorUnit = (val: string) => {
  // eslint-disable-next-line eqeqeq
  if (val == '0') {
    return (parseFloat(val) / 100)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  if (!val || isNaN(parseInt(val))) {
    return 'A number is required'
  }
  return (parseFloat(val) / 100)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const fromUnit = (val: string) => {
  if (!val || isNaN(parseInt(val))) {
    return 0
  }
  return parseInt(val) * 100
}

export const toUnit = <T extends string | number>(val: T) => {
  if (!val || isNaN(Number(val))) {
    return 0
  }
  return Number(val) / 100
}
