<script setup lang="ts">
import * as yup from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { useForm } from 'vee-validate'
import { useExport } from '~/src/composables/utils/useExport'
import Calendar from '~/src/components/icons/Calendar.vue'
import { DateFormat } from '~/src/types/internals'

const { state, exportToDocument } = useExport()

const schema = toTypedSchema(
  yup.object({
    date: yup.array().of(yup.string().required()).min(1).required().label('Date range'),
  }),
)

const { defineField, handleSubmit, resetForm } = useForm({
  validationSchema: schema,
})

const elPlusConfig = (state: any) => ({
  props: {
    validateEvent: false,
    error: state.errors[0],
    required: state.required,
  },
})

const [date, dateProps] = defineField('date', elPlusConfig)

const submit = handleSubmit(async (values) => {
  const [from, to] = values.date
  await exportToDocument({ from, to })

  resetForm()
})
</script>
<template>
  <Modal>
    <form
      class="w-full"
      @submit.prevent="submit"
    >
      <Typography variant="baseBold"> {{ $t('exportData') }}</Typography>
      <Divider />

      <FormControl
        v-bind="dateProps"
        :label="$t('dateRange')"
        class="mt-4 mb-6"
      >
        <DatePicker
          v-model="date"
          type="daterange"
          size="large"
          range-separator="to"
          :start-placeholder="$t('startDate')"
          :placeholder="$t('endDate')"
          class="w-full mx-auto"
          :value-format="DateFormat.Default"
          :prefix-icon="Calendar"
          :clearable="false"
        />
      </FormControl>

      <div>
        <Button
          :loading="state.loading"
          size="large"
          class="ml-auto"
        >
          <icon
            name="download"
            class="w-4"
          />
          {{ $t('transfer.download') }}
        </Button>
      </div>
    </form>
  </Modal>
</template>
