import { GATEWAY_ENDPOINT } from './SetupBase'

type CheckoutInit = {
  amount: number
  customer_email: string
  payment_methods?: any[]
}

export const checkoutApi = {
  $_initialize: async (payload: CheckoutInit) => {
    const url = '/payment'
    const res = await GATEWAY_ENDPOINT.post(url, payload)
    return res.data
  },
}
