<template>
  <span
    v-if="name"
    :style="`color: ${selectPalette?.color} ; background-color: ${selectPalette?.bg};`"
    class="py-1.5 px-3 font-medium text-sm rounded-3xl"
  >
    <slot>{{ capitalized(name) }}</slot>
  </span>
</template>

<script setup lang="ts">
import { capitalized } from '~/src/helpers/capitalized'

const toLowerCase = (val: any) => {
  return val.toLowerCase()
}

const props = defineProps<{
  name?: 'pending' | 'success' | 'failed' | 'default' | 'cancelled' | 'credit' | 'debit' | 'active' | 'terminated'
  secondary?: boolean
}>()

const selectPalette = ref<{ color: string; bg: string }>()

const palette = {
  success: { color: '#15803D', bg: '#DCFCE7' },
  failed: { color: '#D12E2E', bg: '#FAEAEA' },
  pending: { color: '#E2C044', bg: '#FAF5E1' },
  default: { color: '#1F2937', bg: '#F3F4F6' },
}

switch (toLowerCase(props.name)) {
  case 'pending':
    selectPalette.value = palette.pending
    break
  case 'success':
  case 'active':
  case 'credit':
    selectPalette.value = palette.success
    break
  case 'failed':
  case 'terminated':
  case 'debit':
    selectPalette.value = palette.failed
    break
  case 'cancelled':
    selectPalette.value = palette.failed
    break
  default:
    selectPalette.value = palette.default
}
</script>
