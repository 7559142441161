<template>
  <Modal
    width="720px"
    @close="close"
  >
    <figure class="text-secondary relative overflow-hidden">
      <PopupIllustration class="w-[655px] h-[250px] fixed left-0 -top-1 right-0" />
      <figcaption class="mt-56">
        <h1 class="text-3xl font-semibold mb-4">Attention: Card Funding & Withdrawal Disabled</h1>
        <p>
          Please be informed that card funding and withdrawal is temporarily disabled. We're working on a quick
          resolution and we will notify you once it has been resolved. We apologise for any inconvenience this may
          cause.
        </p>
      </figcaption>
    </figure>
  </Modal>
</template>

<script lang="ts" setup>
import PopupIllustration from '~/src/components/icons/popup-illustration.vue'

const close = () => {
  localStorage.setItem('pop-up', String(true))
}
</script>
