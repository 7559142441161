import { Pagination } from '~/src/services/requests/Pagination/type'
import { stashApi } from '~/src/services/requests/stash'
import {
  BusinessInvestment,
  FundStash,
  FundStashResponse,
  Investment,
  InvestmentTransaction,
  InvestmentYield,
  PortfolioResponse,
  StashResponse,
  StashSchema,
  StashSchemaStepOne,
  StashSchemaStepTwo,
} from '~/src/services/requests/stash/type'
import { TransactionResponse } from '~/src/services/requests/transactions/type'
import { InvestmentStatus } from '~/src/types/internals'

const globalState = reactive<{
  stash: Partial<StashSchema> | null
  portfolios: PortfolioResponse['data']
  investment: Investment | null
  loading: Record<string, boolean>
  yields: InvestmentYield | null
  totalInvestment: number
  businessInvestments: { data: BusinessInvestment[]; meta: Pagination | null }
  allTransactions: { data: TransactionResponse['data']; meta: TransactionResponse['meta'] | null }
  transactions: { data: InvestmentTransaction[]; meta: Pagination | null }
  fundingSuccess: {
    title: string
    description: string
    actionName: string
    id: string
  }
  funding: FundStash | null
}>({
  loading: {
    funding: false,
    businessInvestments: false,
    investmentById: false,
    yields: false,
    transactions: false,
    createStash: false,
    portfolios: false,
    allTransactions: false,
  },
  fundingSuccess: {
    title: '',
    description: '',
    actionName: '',
    id: '',
  },
  funding: null,
  stash: null,
  portfolios: [],
  totalInvestment: 0,
  investment: null,
  yields: null,
  businessInvestments: {
    data: [],
    meta: null,
  },
  allTransactions: {
    data: [],
    meta: null,
  },
  transactions: {
    data: [],
    meta: null,
  },
})

export const useStash = () => {
  const reset = () => {
    globalState.stash = null
    globalState.portfolios = []
    globalState.loading.createStash = false
    globalState.fundingSuccess = {
      title: '',
      description: '',
      actionName: '',
      id: '',
    }
  }

  const resetFunding = () => {
    globalState.funding = null
  }

  const createStepOne = ({ name }: StashSchemaStepOne) => {
    globalState.stash = {
      ...globalState.stash,
      name,
    }
  }

  const createStepTwo = ({ duration, amount, portfolioId }: StashSchemaStepTwo) => {
    globalState.stash = {
      ...globalState.stash,
      amount,
      duration,
      portfolioId,
    }
  }

  const fundStash = async (
    payload: FundStash,
    options?: { onSuccess?: (data?: FundStashResponse['data']) => void },
  ) => {
    globalState.loading.funding = true

    const res = await stashApi.$_fundStash(payload)

    if (res !== 'ERROR') {
      options?.onSuccess?.(res.data.data)
    }

    globalState.loading.funding = false
  }

  const createStashPlan = async (
    payload: StashSchema,
    options?: { onSuccess?: (data?: StashResponse['data']) => void },
  ) => {
    globalState.loading.createStash = true

    const res = await stashApi.$_createStashPlan(payload)
    if (res !== 'ERROR') {
      globalState.loading.createStash = false
      options?.onSuccess?.(res.data.data)
    }

    globalState.loading.createStash = false
  }

  const getPortfolios = async () => {
    globalState.loading.portfolios = true

    const res = await stashApi.$_getPortfolios()
    if (res !== 'ERROR') {
      globalState.portfolios = res.data.data || []
    }
    globalState.loading.portfolios = false
  }

  const getInvestmentById = async (id: string) => {
    globalState.loading.investmentById = true

    const res = await stashApi.$_getInvestmentById(id)
    if (res !== 'ERROR') {
      globalState.investment = res.data.data
    } else {
      useRouter().push('/404')
    }

    globalState.loading.investmentById = false
  }

  const getInvestmentYields = async (id: string) => {
    globalState.loading.yields = true

    const res = await stashApi.$_getInvestmentYield(id)
    if (res !== 'ERROR') {
      globalState.yields = res.data.data
    } else {
      useRouter().push('/404')
    }

    globalState.loading.yields = false
  }

  const getBusinessPortFolios = async (params?: { status?: InvestmentStatus; page?: number; size?: number }) => {
    globalState.loading.businessInvestments = true

    const res = await stashApi.$_getBusinessPortFolios(params)
    if (res !== 'ERROR') {
      globalState.totalInvestment = res.data.data?.totalInvestment || 0
      globalState.businessInvestments.data = res.data.data?.investments || []
      globalState.businessInvestments.meta = res.data.meta
    }

    globalState.loading.businessInvestments = false
  }

  const getInvestmentTransactions = async (params: { id: string; page?: number; size?: number }) => {
    globalState.loading.transactions = true

    const res = await stashApi.$_getInvestmentTransactions(params)
    if (res !== 'ERROR') {
      globalState.transactions.data = res.data.data || []
      globalState.transactions.meta = res.data.meta
    } else {
      useRouter().push('/404')
    }

    globalState.loading.transactions = false
  }

  const getAllTransactions = async (params?: { id: string; page?: number; size?: number }) => {
    globalState.loading.allTransactions = true

    const res = await stashApi.$_getAllTransactions(params)
    if (res !== 'ERROR') {
      globalState.allTransactions.data = res.data.data || []
      globalState.allTransactions.meta = res.data.meta
    }

    globalState.loading.allTransactions = false
  }

  return {
    state: globalState,
    resetFunding,
    reset,
    fundStash,
    createStashPlan,
    getPortfolios,
    createStepOne,
    createStepTwo,
    getInvestmentById,
    getInvestmentYields,
    getInvestmentTransactions,
    getBusinessPortFolios,
    getAllTransactions,
  }
}
