<template>
  <div
    ref="target"
    class="relative w-full"
  >
    <input
      v-bind="$attrs"
      v-model="typedText"
      type="text"
      class="input"
      :placeholder="loading ? 'Loading..' : placeholder"
      :disabled="loading || disabled"
      @input="handleInput"
      @focus="showSuggestions"
    />

    <ul
      v-if="showingSuggestions"
      class="absolute z-50 w-full mt-1 bg-white border border-dark rounded-md shadow-lg"
    >
      <div v-if="filteredItems.length > 0">
        <li
          v-for="item in filteredItems as any"
          :key="item.id"
          class="px-4 py-2 cursor-pointer hover:bg-gray-100 rounded-md"
          @click="selectItem(item)"
        >
          {{ item.name }}
        </li>
      </div>
      <div v-else>
        <li class="px-4 py-2 cursor-pointer hover:bg-gray-100 rounded-md">No results found</li>
      </div>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
const target = ref(null)

const props = withDefaults(
  defineProps<{
    modelValue?: any
    options: any[]
    loading?: boolean
    disabled?: boolean
    placeholder?: string
  }>(),
  {
    modelValue: '',
    placeholder: 'placeholder text ',
  },
)
const emit = defineEmits(['update:modelValue'])
const typedText = ref('')
const showingSuggestions = ref(false)
const selectedItem = ref({} as any)

watch(
  () => props.modelValue,
  (value) => {
    typedText.value = value.name
    selectedItem.value = value
  },
)
const onBlur = () => {
  if (!selectedItem?.value?.name) {
    typedText.value = ''
  } else {
    typedText.value = selectedItem.value.name
  }
  showingSuggestions.value = false
}

onClickOutside(target, onBlur)
onMounted(() => {
  typedText.value = props?.modelValue?.name ?? ''
  selectedItem.value = props.modelValue
})

const filteredItems = computed(() => {
  return props.options.filter((item: any) => item.name.toLowerCase().includes(typedText.value.toLowerCase()))
})

const handleInput = (event: any) => {
  typedText.value = event.target.value
  showingSuggestions.value = true
}

const showSuggestions = () => {
  if (filteredItems.value.length > 0) {
    showingSuggestions.value = true
  }
}

const selectItem = (item: any) => {
  typedText.value = item.name
  selectedItem.value = item
  emit('update:modelValue', item)
  showingSuggestions.value = false
}
</script>
