<script setup lang="ts">
import { Element, ButtonVariant, ButtonSize } from './index'
import type { Props } from './index'

const props = withDefaults(defineProps<Props>(), {
  variant: ButtonVariant.Primary,
  size: ButtonSize.Medium,
  as: Element.Button,
})

const classes = computed(() => `button-${props.variant}`)

const fontSize = computed(() => ({
  'text-sm py-2.5 px-8': props.size === 'large',
  'text-sm py-1.5 px-6': props.size === 'medium',
  'text-sm py-1 px-2.5': props.size === 'small',
}))

const loadingDimenions = computed(() => ({
  '!h-7 !w-7': props.size === 'large',
  '!h-5 !w-5': props.size === 'medium',
  '!h-4 !w-4': props.size === 'small',
}))

const component = computed(() => {
  if (props.as === Element.A || props.as === Element.NuxtLink) return resolveComponent('NuxtLink')
  return props.as
})

const isDisabled = computed(() => props.loading || props.disabled)
</script>
<template>
  <Component
    v-bind="props"
    :is="component"
    class="button"
    :disabled="isDisabled"
    :class="[classes, fontSize, isDisabled ? 'disabled' : '']"
  >
    <Spinner
      v-if="loading"
      :class="loadingDimenions"
    />
    <slot v-else />
  </Component>
</template>
<style scoped>
.button {
  @apply relative flex justify-center items-center gap-2 font-semibold transition-all duration-200 ease-in;
}

.button.disabled::before {
  content: '';
  @apply absolute h-full w-full bg-white opacity-50 cursor-not-allowed;
}
.button-outline {
  @apply rounded-lg px-4 border border-gray10 disabled:bg-slate-100;
  box-shadow: 0px 1px 2px 0px #1018280d;
}
.button-primary {
  @apply bg-primary600 text-gray900 rounded-[10px] outline-none hover:bg-primary700;
}
.button-error {
  @apply bg-red text-white rounded-[10px] outline-none;
}
.button-secondary {
  @apply bg-RGB-primary600/10 rounded-lg outline-none shadow-sm shadow-RGB-gray900/5;
}

/* TODO: varying sizes are unhandled */
.button-primary-sm {
  @apply button-primary h-8 w-auto text-sm px-2 pl-2.5;
}
.button-primary-md {
  @apply w-auto h-auto px-16 text-sm button-primary;
}
.button-secondary-md {
  @apply w-auto h-auto px-16 text-sm button-secondary;
}
</style>
