import { createGtm } from '@gtm-support/vue-gtm'
import { Router } from '#vue-router'

export default defineNuxtPlugin((app) => {
  if (useRuntimeConfig().public.NODE_ENV !== 'production') return

  app.vueApp.use(
    createGtm({
      id: useRuntimeConfig().public.GTM_KEY,
      defer: false,
      compatibility: false,
      enabled: true,
      debug: false,
      loadScript: true,
      vueRouter: useRouter() as Router,
      trackOnNextTick: false,
    }),
  )
})
