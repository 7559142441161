import { getAllTransfers } from './index'
import { useAlert } from '~/src/composables/core/useNotification'
import { useTransfersModal } from '~/src/composables/core/modals'
import { resolveAccount, getBanks } from '~/src/composables/apis/institution'
import { transferApi } from '~/src/services/requests/transactions/index'
import { toMinorUnit } from '~/src/helpers/toMinorUnit'
import { Institution } from '~/src/types/internals'
import { ROUTES_NAMES } from '~/src/routes'

const { fetch: fetchBanks, banks, loading: bankLoading } = getBanks()
const step = ref(1)
export const USDTransferloading = ref(false)

export const credential = {
  bank_code: ref(''),
  account_number: ref(''),
  amount: ref(''),
  reason: ref(''),
  currency: ref('USD'),
  bank: computed(() => banks.value.find((bank: any) => bank.id === credential.bank_code.value.id)),
  to: ref(''),
  meta: {
    scheme: ref(''),
    sender: {
      first_name: ref(''),
      last_name: ref(''),
      phone_number: ref(''),
      address: ref(''),
      country: ref(''),
    },
    counterparty: {
      first_name: ref(''),
      last_name: ref(''),
      address: ref(''),
      phone_number: ref(''),
      country: ref(''),
    },
  },
}
watch(credential.meta.scheme, async (newValue: string) => {
  if (newValue === Institution.CASHPICKUP || newValue === Institution.DOM) {
    await fetchBanks(newValue)
  }
})

watch([credential.meta.sender.first_name, credential.meta.sender.last_name], ([newValue, secondNewValue]) => {
  credential.to.value = newValue + ' ' + secondNewValue
})

export const createUSDTransfer = () => {
  const { fetchTransfer } = getAllTransfers()
  const error = ref('')

  const { fetch, loading: AccLoading, resolved } = resolveAccount()
  watch(credential.account_number, async (val) => {
    if (val.toString().length !== 0 && (val.toString().length < 10 || val.toString().length > 10)) {
      error.value = 'Invalid Account Number'
      resolved.value = ''
    }
    if (/[a-zA-Z]/.test(val)) {
      error.value = "Account Number Can't contain strings"
      resolved.value = ''
    }
    if (val.toString().length === 10) {
      error.value = ''
      await fetch({
        accountNumber: credential.account_number.value.toString(),
        bankCode: credential.bank_code.value.id,
      })
    }
  })

  const create = async () => {
    USDTransferloading.value = true
    const res = (await transferApi.$_createTransfer({
      bank_code: credential.bank_code.value.id,
      account_number: credential.account_number.value.toString(),
      amount: toMinorUnit(credential.amount.value) as any,
      reason: credential.reason.value,
      currency: credential.currency.value,
      // reference: credential.reference.value,
      meta: {
        scheme: credential.meta.scheme.value,
        sender: {
          first_name: credential.meta.sender.first_name.value,
          last_name: credential.meta.sender.last_name.value,
          phone_number: credential.meta.sender.phone_number.value.toString(),
          address: credential.meta.sender.address.value,
          country: credential.meta.sender.country.value,
        },
        counterparty: {
          first_name: credential.meta.counterparty.first_name.value,
          last_name: credential.meta.counterparty.last_name.value,
          address: credential.meta.counterparty.address.value,
          phone_number: credential.meta.counterparty.phone_number.value.toString(),
          // identity_type: credential.meta.counterparty.identity_type.value,
          country: credential.meta.counterparty.country.value,
        },
      },
    })) as any
    USDTransferloading.value = false

    if (res !== 'ERROR') {
      useTransfersModal().closeCreateUSDTransfer()
      useRouter().push({
        name: ROUTES_NAMES.transactions,
      })
      fetchTransfer()
      useAlert().openAlert({ type: 'SUCCESS', msg: res.data.message })
    }
  }
  return {
    credential,
    create,
    USDTransferloading,
    error,
    AccLoading,
    step,
    resolved,
    banks,
    bankLoading,
  }
}
